<strong><i class="fas fa-share me-2 text-muted"></i> Compartilhar</strong>
<p class="text-muted">
    <small>Você pode compartilhar este link com seu cliente.</small>
</p>
<div class="row">
    <div class="col-8">
        <div class="form-group">
            <input type="url" readonly [value]="[projectUrl,'invoice',invoice.id].join('/')" class="form-control">
        </div>
    </div>
    <div class="col-4">
        <button (click)="helpersService.copyToClipboard([projectUrl,'invoice',invoice.id].join('/'), true)" class="btn btn-primary me-2" type="button">
            <i class="fas fa-copy"></i>
        </button>
        <button (click)="shareToWhatsapp()" class="btn btn-whatsapp" type="button">
            <i class="fab fa-whatsapp"></i>
        </button>
    </div>
</div>