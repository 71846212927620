import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  @Input() parent: string = 'page'; // page | button | line
  @Input() color: string = 'text-white';

  constructor() { }

  ngOnInit(): void {
  }

}
