<app-navbar></app-navbar>

<router-outlet></router-outlet>

<div class="separator-100"></div>

<app-toast></app-toast>

<footer>
    <div class="container">
        &copy; 2024 Invoicefy &middot; <a href="https://invoicefy.io/terms" class="underline-none" target="_blank" rel="noopener noreferrer">Termos de Uso e Privacidade</a>
    </div>
</footer>