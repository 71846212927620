<button class="btn btn-link underline-none btn-sm" type="button" (click)="openOffcanvas(typebot)">
    <i class="fas fa-life-ring me-2"></i> Solicitar Suporte
</button>

<ng-template #typebot let-offcanvas offcanvas-lg>
    <div class="offcanvas-header">
        <strong class="offcanvas-title"><i class="fas fa-life-ring me-2"></i> Suporte Técnico</strong>
        <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
    </div>
    <div class="offcanvas-body">

        <div class="custom-typebot">
            <div class="d-flex justify-content-center loading">
                <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>

            <iframe [src]="typebotUrl" width="100%" height="90vh"></iframe>
        </div>
    </div>
</ng-template>