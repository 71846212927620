import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import Customer from 'src/app/models/customer.interface';
import { CustomersService } from 'src/app/services/customers.service';
import { HelpersService } from 'src/app/services/helpers.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-customers-form',
  templateUrl: './customers-form.component.html',
  styleUrls: ['./customers-form.component.scss']
})
export class CustomersFormComponent implements OnInit {

  @Input() customer: Customer = {} as Customer;
  @Input() action: string = '' || 'create';

  @Output() onSubmit: any = new EventEmitter();
  @Output() onLoading: any = new EventEmitter();
  @Output() onLoadingId: any = new EventEmitter();

  @ViewChild('formCustomers') formCustomers: any;

  loading: boolean = false;
  loadingId: string | any = "";

  constructor(
    private customersService: CustomersService,
    private helpersService: HelpersService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
  }

  onCountryChange(event: any): void {
    this.customer.phone = "";
  }

  submit(formData: any): void {

    if (formData.email && !this.helpersService.isValidEmail(formData.email)) {
      this.messageService.send('toast', 'Invalid email address');
      return;
    }

    this.loading = true;
    this.onLoading.emit(this.loading);

    this.loadingId = this.customer.id;
    this.onLoadingId.emit(this.loadingId);

    let customer: Customer = {
      name: formData.name,
      email: formData.email ? formData.email.toLowerCase() : "",
      phone: formData.phone ? formData.phone : '',
      isWhatsapp: formData.isWhatsapp ? true : false,
      address: formData.address || '',
      notes: formData.notes || '',
    }

    if (this.action === 'update') {

      customer.id = this.customer.id;

      this.customersService.updateCustomer(customer).subscribe((response: any) => {
        this.onSubmit.emit({ response, action: this.action });
        this.loading = false;
        this.onLoading.emit(this.loading);

        this.loadingId = null;
        this.onLoadingId.emit(this.loadingId);
      });

    } else {

      this.customersService.createCustomer(customer).subscribe((response: any) => {
        this.onSubmit.emit({ response, action: this.action });
        this.loading = false;
        this.formCustomers.reset();
        this.onLoading.emit(this.loading);

        this.loadingId = null;
        this.onLoadingId.emit(this.loadingId);
      });

    }
  }

}
