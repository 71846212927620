<app-header>
    <div class="badge primary mb-3">Resumo</div>
    <span>Últimas Faturas</span>
    <p>
        Estas são as 10 faturas mais recentes. Acesse o menu <a [routerLink]="['/panel/reports']">Relatórios</a> para ver todas as faturas.
    </p>
</app-header>

<section class="bg-super-light content-section">

    <div class="container">
        <small class="text-muted">Números de Janeiro</small>

        <div class="separator-10"></div>

        <div class="row">
            <div class="col-3">
                <div class="card shadow-sm border-0">
                    <div class="card-body text-center">
                        <h1><strong>12</strong></h1>
                        <span class="text-muted">Faturas Enviadas</span>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="card shadow-sm border-0">
                    <div class="card-body text-center">
                        <h1><strong>6</strong></h1>
                        <span class="text-muted">Faturas Pagas (50%)</span>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="card shadow-sm border-0">
                    <div class="card-body text-center">
                        <h1><strong>R$ 200,90</strong></h1>
                        <span class="text-muted">Recebidos</span>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="card shadow-sm border-0">
                    <div class="card-body text-center">
                        <h1><strong>R$ 3.417,90</strong></h1>
                        <span class="text-muted">À receber</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="separator-50"></div>

    <div class="container">

        <app-loading *ngIf="loadingInvoices"></app-loading>

        <div *ngIf="!loadingInvoices && invoices.length === 0" class="text-center">
            <strong>
                Você ainda não criou nenhuma fatura.
            </strong>
            <div class="separator-20"></div>
            <button [disabled]="loadingInvoices || loadingCreateInvoice" (click)="createInvoice()" class="btn btn-primary shadow-sm">
                <app-loading *ngIf="loadingCreateInvoice" parent="button"></app-loading> 
                <i *ngIf="!loadingCreateInvoice" class="fas fa-plus-circle me-2"></i> Criar a Primeira Fatura
            </button>
        </div>

        <div *ngIf="!loadingInvoices && invoices.length > 0">
            <div class="row align-items-end">
                <div class="col-12 col-md-3">
                    <select
                        [disabled]="invoices.length == 0"
                        (change)="filterInvoices($event)"
                        class="form-control bg-white border-0 shadow-sm">
                            <option value="all">Todos os Status</option>
                            <option [disabled]="countInvoicesByStatus('cancelled') == 0" value="cancelled">Cancelada ({{ countInvoicesByStatus('cancelled') }})</option>
                            <option [disabled]="countInvoicesByStatus('draft') == 0" value="draft">Rascunho ({{ countInvoicesByStatus('draft') }})</option>
                            <option [disabled]="countInvoicesByStatus('scheduled') == 0" value="scheduled">Agendada ({{ countInvoicesByStatus('scheduled') }})</option>
                            <option [disabled]="countInvoicesByStatus('sent') == 0" value="sent">Enviada ({{ countInvoicesByStatus('sent') }})</option>
                            <option [disabled]="countInvoicesByStatus('paymentProof') == 0" value="paymentProof">Comprovante Recebido ({{ countInvoicesByStatus('paymentProof') }})</option>
                            <option [disabled]="countInvoicesByStatus('paid') == 0" value="paid">Paga ({{ countInvoicesByStatus('paid') }})</option>
                    </select>
                </div>
                <div class="col-12 col-md-9 text-end">
                    <button [disabled]="loadingInvoices || loadingCreateInvoice" (click)="createInvoice()" class="btn btn-primary shadow-sm">
                        <app-loading *ngIf="loadingCreateInvoice" parent="button"></app-loading> 
                        <i *ngIf="!loadingCreateInvoice" class="fas fa-plus-circle me-2"></i> Criar Fatura
                    </button>
                </div>
            </div>

            <div class="separator-20"></div>

            <app-invoice-list [invoices]="invoices"></app-invoice-list>
        </div>
    </div>
</section>