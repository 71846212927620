import { Routes } from "@angular/router";
import { InvoicesComponent } from "./views/invoices/invoices.component";
import { InvoiceSingleComponent } from "./views/invoice-single/invoice-single.component";
import { CustomersManagmentComponent } from "./views/customers/customers.component";
import { InvoicePublicComponent } from "./views/invoice-public/invoice-public.component";
import { AuthLoginComponent } from "./views/auth-login/auth-login.component";
import { LoggedInGuard } from "./services/loggedin.guard";
import { ReportsComponent } from "./views/reports/reports.component";
import { AuthSignupComponent } from "./views/auth-signup/auth-signup.component";
import { TermsComponent } from "./views/terms/terms.component";
import { CountersComponent } from "./views/counters/counters.component";
import { SettingsComponent } from "./views/settings/settings.component";
import { ConfirmAccountComponent } from "./views/confirm-account/confirm-account.component";
import { ResetPasswordComponent } from "./views/reset-password/reset-password.component";
import { WelcomeComponent } from "./views/welcome/welcome.component";
import { FeaturesComponent } from "./views/features/features.component";
import { UpgradeComponent } from "./views/upgrade/upgrade.component";
import { WelcomeToProComponent } from "./views/welcome-to-pro/welcome-to-pro.component";
import { NotFoundComponent } from "./views/not-found/not-found.component";
import { InvoiceEditorComponent } from "./views/invoice-editor/invoice-editor.component";
import { VersionComponent } from "./views/version/version.component";

export const ROUTES: Routes = [
    { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
    { path: 'terms', component: TermsComponent },
    { path: 'version', component: VersionComponent },
    { path: 'auth/signup', component: AuthSignupComponent },
    { path: 'auth/login', component: AuthLoginComponent },
    { path: 'auth/recover-password', component: ResetPasswordComponent },
    { path: 'welcome', component: WelcomeComponent },
    { path: 'features', component: FeaturesComponent },
    { path: 'counters', component: CountersComponent },
    { path: 'confirm-account/:uid', component: ConfirmAccountComponent },
    { path: 'invoice', redirectTo: 'auth/login', pathMatch: 'full' },
    { path: 'invoice/:id', component: InvoicePublicComponent },
    {
        path: 'panel',
        canActivate: [LoggedInGuard],
        children: [
            { path: '', redirectTo: 'invoices', pathMatch: 'full' },
            { path: 'invoices', component: InvoicesComponent },
            { path: 'invoices/:id', redirectTo: 'invoices/:id/review', pathMatch: 'full' },
            { path: 'invoices/:id/review', component: InvoiceSingleComponent },
            { path: 'invoices/:id/editor', component: InvoiceEditorComponent },
            { path: 'customers', component: CustomersManagmentComponent },
            { path: 'reports', component: ReportsComponent },
            { path: 'settings', component: SettingsComponent },
            { path: 'upgrade', component: UpgradeComponent },
            { path: 'welcome-to-pro', component: WelcomeToProComponent },
            { path: 'not-found', component: NotFoundComponent },
        ],
    },
    { path: '**', redirectTo: '/auth/login', pathMatch: 'full' },
];