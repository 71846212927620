<div *ngIf="!loading && isConfirmed" class="pyro">
    <div class="before"></div>
    <div class="after"></div>
</div>

<div class="separator-100"></div>

<div class="container">
    <div class="row d-flex justify-content-center">
        <div class="col-12 col-md-4">

            <app-loading *ngIf="loading"></app-loading>

            <div *ngIf="!loading && isConfirmed">

                <div class="page-header">
                    <h3>Sucesso!</h3>
                    <p>
                        Seu email foi confirmado.<br>
                        Agora você pode fazer login.
                    </p>
                </div>

                <a [routerLink]="['/auth/login']" class="btn btn-primary">Ir para o Login</a>
            </div>

        </div>
    </div>
</div>