import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'src/app/services/message.service';
import { UsersService } from 'src/app/services/users.service';
import { AuthService } from 'src/app/services/auth.service';
import Profile from 'src/app/models/profile.interface';

// Firebase
import 'firebase/auth'
import { AngularFireAuth } from '@angular/fire/compat/auth';

const fbq: any = window['fbq'];

@Component({
  selector: 'app-auth-signup',
  templateUrl: './auth-signup.component.html',
  styleUrls: ['./auth-signup.component.scss']
})
export class AuthSignupComponent implements OnInit {

  user: any = {};
  loading: boolean = false;

  password: string = '';
  passwordStrength: number = 0;

  constructor(
    private usersService: UsersService,
    private router: Router,
    private authService: AuthService,
    private messageService: MessageService,
    private auth: AngularFireAuth,
  ) { }

  ngOnInit(): void {
    if (window.location.hostname === 'app.invoicefy.io' && fbq) {
      fbq('track', 'Lead');
    }

    this.authService.logout();
  }

  signup(formLogin: any): any {
    if (formLogin.password !== formLogin.passwordConfirm) {
      this.messageService.send("toast", "As senhas são diferentes");
      return;
    }

    this.loading = true;

    this.auth.createUserWithEmailAndPassword(formLogin.email, formLogin.password).then((result: any) => {
      const newUser: Profile = {
        uid: result.user.uid,
        displayName: formLogin.name || result.user.displayName,
        photoURL: result.user.photoURL,
        email: result.user.email,
        emailVerified: result.user.emailVerified,
        phoneNumber: formLogin.phone || result.user.phoneNumber,
        refreshToken: result.user.refreshToken,
      };
      this.usersService.createProfile(newUser).subscribe((response: any) => {
        if (response.message === "OK") {
          this.loading = true;
          this.router.navigate(['/welcome']);
          return;
        }

        console.log("Error", response);
        this.loading = true;
        this.messageService.send('toast', 'Erro ao criar o usuário. Tente novamente mais tarde');
      }, (error: any) => {
        this.messageService.send('toast', 'Erro desconhecido ao criar o usuário. Tente novamente mais tarde');
        this.loading = false;
        console.log('Error', error);
      });
    }).catch((error: any) => {
      let message = "";
      switch (error.code) {
        case 'auth/email-already-in-use':
          message = 'Email já está cadastrado';
          break;
        case 'auth/invalid-email':
          message = 'Email é inválido';
          break;
        case 'auth/weak-password':
          message = 'A senha é muito fraca';
          break;
        case 'auth/operation-not-allowed':
          message = 'Operation not allowed';
          break;
        default:
          message = 'Erro desconhecido';
          break;
      }
      this.messageService.send('toast', message);
      this.loading = false;
    });
  }

}
