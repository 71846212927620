import { Component, OnInit } from '@angular/core';
import { CustomersService } from 'src/app/services/customers.service';
import { HelpersService } from 'src/app/services/helpers.service';
import { MessageService } from 'src/app/services/message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import Customer from 'src/app/models/customer.interface';
import Invoice from 'src/app/models/invoice.interface';

@Component({
  selector: 'app-customers-management',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersManagmentComponent implements OnInit {

  sub: Subscription[] = [];

  customers: Customer[] = [];
  loadingCustomers: boolean = false;
  loadingUpdateCustomers: boolean = false;
  loadingDeleteCustomers: boolean = false;
  loadingCustomerId: string = "";

  history: Invoice[] = [];
  loadingHistoryId: string = '';

  selectedCustomerToRemove: any = {};
  selectedCustomerToEdit: any = {};

  readLess: string = '';

  constructor(
    public helpersService: HelpersService,
    private messageService: MessageService,
    private customersService: CustomersService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
  }

  onCustomers($event: any): void {
    this.customers = $event;
    if (this.customers.length > 0) {
      this.customers.forEach((customer: Partial<Customer>) => {
        customer.notesLess = customer.notes ? this.helpersService.readLess(customer.notes, 'leia mais', 60) : '--';
      });
    }
  }

  openModalEditCustomer(content: any, customer: Customer): void {
    const customerToUpdate = customer;
    delete customerToUpdate.history;
    this.selectedCustomerToEdit = customerToUpdate;
    this.modalService.open(content, { backdrop: 'static' }).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(`Dismissed ${reason}`);
    });
  }

  openModalConfirmRemoveCustomer(content: any, customer: Customer): void {
    this.selectedCustomerToRemove = customer;
    this.modalService.open(content, { backdrop: 'static', size: "lg" }).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(`Dismissed ${reason}`);
    });
  }

  deleteCustomer(): void {
    this.loadingDeleteCustomers = true;
    this.loadingCustomerId = this.selectedCustomerToRemove.id;
    this.modalService.dismissAll();
    this.messageService.send('toast', 'Cliente removido!');
    this.sub.push(
      this.customersService.deleteCustomer(this.selectedCustomerToRemove).subscribe((response: any) => {
        this.loadingDeleteCustomers = false;
        this.loadingCustomerId = "";
        this.customers.splice(this.customers.indexOf(this.selectedCustomerToRemove), 1);
      })
    );
  }

  onSubmitCustomer(event: any): void {
    if (event.action === 'update') {
      let updatedCustomer: Customer | any = this.customers.find((customer: Customer) => customer.id == event.response.id);
      event.response.notesLess = event.response.notes;
      this.customers[this.customers.indexOf(updatedCustomer)] = event.response;
      this.modalService.dismissAll();
      this.messageService.send('toast', 'Cliente atualizado!');
    } else {
      event.response.notesLess = event.response.notes;
      this.customers.push(event.response);
      this.customers.sort((a: Customer, b: Customer) => a.name > b.name ? 1 : -1);
      this.messageService.send('toast', 'Cliente adicionado!');
    }
  }

  /*
  // Working but removed in the current version
  getCustomerHistory(customer: Customer): void {
    this.loadingHistoryId = customer.id || '';
    this.sub.push(
      this.customersService.getCustomerHistory(customer).subscribe((response: any) => {
        let updatedCustomer: Customer | any = this.customers.find((c: Customer) => c.id === customer.id);
        if (response.length === 0) {
          this.messageService.send('toast', 'Sem histórico');
        } else {
          updatedCustomer.history = response;
        }
        this.loadingHistoryId = '';
      })
    );
  }
  */

}
