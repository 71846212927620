<div class="pyro">
    <div class="before"></div>
    <div class="after"></div>
</div>

<div class="container">
    <div class="row d-flex justify-content-center">
        <div class="col-12 col-md-4">

            <div class="page-header">
                <h3>Not Found</h3>
                <p>
                    The page you requested doesn't exist.
                </p>
            </div>

            <div class="separator-70"></div>

            <img src="/assets/images/not-found.svg" class="img-fluid" alt="" width="350px">

            <div class="separator-70"></div>

            <a [routerLink]="['/panel/invoices']" class="btn btn-primary">Go to Invoices &rarr;</a>

        </div>
    </div>
</div>