<div class="separator-30"></div>

<app-loading *ngIf="loadingHistory"></app-loading>

<span *ngIf="!loadingHistory">
    <span *ngIf="title">
        <strong><i class="fas fa-history me-2 text-muted"></i> Histórico da Fatura</strong>
        <div class="separator-10"></div>
    </span>
    <span *ngIf="history.length == 0" class="text-muted">
        <i class="fas fa-exclamation-triangle me-2"></i> Nenhum histórico encontrado.
    </span>

    <div *ngIf="invoice && parent === 'page' && history.length > 0">
        <app-invoice-detailed-changes-history
            [invoice]="invoice"
            (onRestoreVersion)="onRestoreVersion($event)"
            [user]="user"></app-invoice-detailed-changes-history>
        <div class="separator-10"></div>
    </div>

    <ul *ngIf="history.length > 0" class="timeline">
        <li (click)="offcanvas.close()" *ngFor="let item of history">
            Fatura <a [routerLink]="['/panel/invoices', item.invoiceId]">#{{ item.invoiceRef }}</a> {{ helpersService.getInvoiceTrackingDescriptionByAction(item.action) }}
            {{ item.createdAt | date: "dd/MM/yyyy HH:mm" }}
        </li>
    </ul>
</span>
