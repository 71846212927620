<div *ngIf="invoices.length > 0">
    <div class="row mb-3">
        <div class="col-1" style="padding-left: 25px;">
            <small class="text-muted">Referência</small>
        </div>
        <div class="col-1" style="padding-left: 20px;">
            <small class="text-muted">Status</small>
        </div>
        <div class="col" style="padding-left: 20px;">
            <small class="text-muted">Cliente</small>
        </div>
        <div class="col-1" style="padding-left: 5px;">
            <small class="text-muted">Vencimento</small>
        </div>
        <div class="col-1" style="padding-left: 5px;">
            <small class="text-muted">Total</small>
        </div>
        <div *ngIf="canDuplicate" class="col-2 text-end">
            &nbsp;
        </div>
    </div>

    <div *ngFor="let invoice of invoices" (click)="openInvoice(invoice)" class="card shadow-sm border-0 mb-3 cursor-pointer">
        <div class="card-body">

            <div class="row mb-2">
                <div class="col-1">
                    <small class="text-muted">#{{ invoice.ref || '-' }}</small>
                </div>
                <div class="col-1">
                    <app-invoice-status [invoice]="invoice"></app-invoice-status>
                </div>
                <div class="col">

                    <span *ngIf="!invoice.customer; else hasCustomer">-</span>
                    <ng-template #hasCustomer>

                        <div class="row align-items-center">
                            <div class="col">
                                <span [innerHTML]="invoice.customer.name ? (invoice.customer.name | truncate: 80 : '...') : '-'"></span>
                            </div>
                            <div *ngIf="invoice.customer.email" class="col-8 text-end text-muted text-end">
                                <small><i class="fas fa-envelope me-2"></i> {{ invoice.customer.email }}</small>
                            </div>
                        </div>

                    </ng-template>

                    <div *ngIf="invoice.items && invoice.items.length > 0">
                        <div class="separator-5"></div>

                        <small class="text-muted">
                            <div *ngFor="let item of invoice.items.slice(0, 3)" class="row">
                                <div class="col-12 col-md-2">
                                    {{ item.price | currency : invoice.currency.toUpperCase() }}
                                </div>
                                <div class="col-12 col-md-10">
                                    <span [innerHTML]="(item && item.name) ? (item.name | truncate: 50) : '-'"></span>
                                </div>
                            </div>

                            <span *ngIf="invoice.items.length > 3">
                                +{{ invoice.items.length-3}} itens
                            </span>
                        </small>
                    </div>

                </div>
                <div class="col-1">

                    <span *ngIf="!invoice.dueDate || invoice.dueDate === ''; else hasDueDate">-</span>
                    <ng-template #hasDueDate>
                        <small class="text-muted">
                            {{ helpersService.convertFirebaseTimestampToString(invoice.dueDate, "DD/MM/YYYY") }}
                        </small>
                    </ng-template>

                </div>
                <div class="col-1">

                    <span *ngIf="!invoice.grandTotal; else hasGrandTotal">-</span>
                    <ng-template #hasGrandTotal>
                        {{ invoice.grandTotal | currency : invoice.currency.toUpperCase() }}
                    </ng-template>

                </div>
                <div *ngIf="canDuplicate" class="col-2 text-end">

                    <button
                        [disabled]="loadingDuplicateInvoice && loadingDuplicateInvoiceForId === invoice.id"
                        (click)="duplicateInvoice(invoice); $event.stopPropagation()"
                        type="button"
                        class="btn btn-link btm-sm p-0">
                            <app-loading *ngIf="loadingDuplicateInvoice && loadingDuplicateInvoiceForId === invoice.id" parent="button" color="text-primary"></app-loading> 
                            <i *ngIf="!loadingDuplicateInvoice || loadingDuplicateInvoiceForId !== invoice.id" class="fas fa-copy me-2"></i> Duplicar
                    </button>

                </div>
            </div>

        </div>
    </div>


    <div class="separator-10"></div>

    <small class="text-muted">
        Exibindo {{ invoices.length }} de {{ invoicesImmutable.length }} faturas.
    </small>
</div>
