import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { InvoicesService } from 'src/app/services/invoices.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnInit {

  selectedFile: any = null;
  imageData: any;

  @Input() label: string = 'Adicionar Item';
  @Input() loading: boolean = false;
  @Input() accept: string = "application/pdf";
  @Input() maxSize: number = 600000;
  @Input() initialPreview: string = '';

  // Uid is required only when uploading from the public area (when user is not logged in) - e.g. uploading payment proof
  // When user is logged in, the uid is retrieved from the auth service
  @Input() uid: string = '';

  @Input() folder: string = "/";
  user: any;

  // @Output() onPreview: any = new EventEmitter<any>();
  @Output() onLoading: any = new EventEmitter<any>();
  @Output() onUpload: any = new EventEmitter<any>();
  @Output() onPercentageChange: any = new EventEmitter<any>();

  constructor(
    private authService: AuthService,
    private invoicesService: InvoicesService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this._getUser();
  }

  private _getUser(): void {
    this.user = this.authService.getUser();
  }

  /*
  showPreview() {
    let reader = new FileReader();
    reader.readAsDataURL(this.selectedFile);
    reader.onload = (_event) => {
      this.imageData = reader.result;
      this.onPreview.emit(this.imageData);
    }
  }
  */

  fileChange(element: any) {
    const file = element.target.files;
    const ownerId: string = (this.user && this.user.uid) ? this.user.uid : this.uid;

    if (file.length > 0) {
      if (this.accept.indexOf(file[0].type) < 0) {
        this.messageService.send('toast', `Tipo de arquivo inválido. Formatos aceitos: ${this.accept}`);
        return;
      }

      if (file[0].size > this.maxSize) {
        this.messageService.send('toast', `Tamanho do arquivo excedido. Máximo: ${this.maxSize} bytes`);
        return;
      }

      this.onLoading.emit(true);
      this.loading = true;
      this.selectedFile = file[0];

      const timerIntervalProgress = setInterval(() => {
        this.onPercentageChange.emit(this.invoicesService.percentage);
      }, 100);

      this.invoicesService.uploadAttachmentToStorage(file[0], this.folder, ownerId).then((response: any) => {
        this.onUpload.emit(response);
        this.onLoading.emit(false);
        this.loading = false;
        clearInterval(timerIntervalProgress);
      }, (err: any) => {
        console.log('Error', err);
      });
    }
  }

}
