<button
    *ngIf="user"
    (click)="openModalInvoiceDetailedChangesHistory(invoiceDetailedChangesHistoryModal)"
    type="button"
    class="btn btn-sm btn-clean">
    <i class="fas fa-list me-2"></i> Ver histórico detalhado
</button>

<ng-template #invoiceDetailedChangesHistoryModal let-modalDetailedChangesHistory>
    <div class="modal-body">
        <strong>Histórico Detalhado de Alterações</strong>
        <div class="separator-20"></div>

        <div *ngIf="user && invoice.status !== 'draft'" class="mb-4">
            <small class="text-muted">
                Esta fatura já foi processada.<br/>
                Você pode visualizar o histórico mas não é possível restaurar versões anteriores.
            </small>
        </div>

        <app-loading *ngIf="loadingDetailedHistory"></app-loading>

        <div *ngIf="!loadingDetailedHistory && history.length > 0">
            <ngb-accordion
                #acc="ngbAccordion"
                [closeOthers]="true">

                <ngb-panel
                    *ngFor="let item of history; let i = index"
                    [id]="'ngb-panel-' + i"
                    [title]="helpersService.convertFirebaseTimestampToString(item.updatedAt, 'DD/MM/YYYY HH:mm:ss') + (i === 0 ? ' (versão atual)' : '')">
                        <ng-template ngbPanelContent>
                            <div *ngIf="i > 0 && user && user.plan === 'pro' && invoice.status === 'draft' && item.status === 'draft'" class="mb-4 text-end">
                                <button
                                    (click)="restoreVersion(item)" class="btn btn-primary btn-sm">
                                    <i class="fas fa-history me-2"></i> Restaurar esta versão
                                </button>
                            </div>
                            <app-invoice-details-readonly [invoice]="item" role="owner"></app-invoice-details-readonly>
                        </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>


        <div class="separator-10"></div>

        <div class="text-center">
            <button
                type="button"
                class="btn btn-link btn-sm"
                (click)="helpersService.closeModals()">Fechar</button>
        </div>
    </div>
</ng-template>