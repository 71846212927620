<small>
    <div placement="top" [ngbTooltip]="invoice.cancelNote"
        class="badge uppercase"
        [class.bg-warning]="['paymentProof', 'scheduled'].includes(invoice.status)"
        [class.bg-success]="invoice.status == 'sent'"
        [class.bg-primary]="invoice.status == 'paid'"
        [class.bg-secondary]="invoice.status == 'draft'"
        [class.bg-danger]="invoice.status == 'cancelled'">
            {{ helpersService.translateStatus(invoice.status) }}
        </div>
</small>