import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import Customer from 'src/app/models/customer.interface';
import Invoice from 'src/app/models/invoice.interface';
import { HelpersService } from 'src/app/services/helpers.service';
import { InvoicesService } from 'src/app/services/invoices.service';
import * as dayjs from 'dayjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, OnDestroy {

  sub: Subscription[] = [];
  loadingReport: boolean = false;
  loadingCustomers: boolean = true;
  invoices: Invoice[] = [];
  invoiceAsCSV: string = "";
  user: any = {};

  filter: any = {
    periodFrom: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    periodTo: dayjs().add(1, 'days').format('YYYY-MM-DD'),
    customer: 'all',
    status: 'all',
    currency: '',
  };
  customers: Customer[] = [];

  constructor(
    public helpersService: HelpersService,
    private authService: AuthService,
    private invoicesService: InvoicesService,
  ) { }

  ngOnInit(): void {
    this._getUser();
  }

  private _getUser(): void {
    this.user = this.authService.getUser();
  }

  ngOnDestroy(): void {
    this.sub.forEach(s => s.unsubscribe());
  }

  sumInvoicesAmount(): number {
    return this.invoices.reduce((acc, invoice) => acc + invoice.grandTotal, 0);
  }

  filterReport(formData: any): void {
    this.loadingReport = true;
    this.sub.push(
      this.invoicesService.reportsInvoice(formData).subscribe((response: any) => {
        this.invoices = response.data;
        this.invoiceAsCSV = response.csv;
        this.loadingReport = false;
      })
    );
  }

  exportToCSV(): void {
    if (this.invoices.length === 0) {
      return;
    }

    const a = document.createElement("a");

    let blob = new Blob(['\ufeff' + this.invoiceAsCSV], { type: "text/csv;charset=utf-8;" });
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  // If Safari, open in new window to save file with random filename.
      a.setAttribute("target", "_blank");
    }
    a.setAttribute("href", url);
    a.setAttribute("download", "Invoicefy.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

}
