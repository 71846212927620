import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AdminService } from 'src/app/services/admin.service';
import { MessageService } from 'src/app/services/message.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-counters',
  templateUrl: './counters.component.html',
  styleUrls: ['./counters.component.scss']
})
export class CountersComponent implements OnInit, OnDestroy {

  sub: Subscription[] = [];
  loading: boolean = false;
  counters: any = {};
  timer: any;
  validator: any = "";

  loadingResendEmailToId: string = '';

  manualLinkGenerator: any = {
    type: "",
    id: "",
    link: "",
  };

  constructor(
    private adminService: AdminService,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.validator = params.v;
      this._getCountersPublic();
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.timer);
    this.sub.forEach((s: any) => s.unsubscribe());
  }

  generateManualLink(): void {
    if (this.manualLinkGenerator.type === 'runQueueManually') {
      this.manualLinkGenerator.link = `${environment.api}/runQueueManually/${this.manualLinkGenerator.id}`;
    } else if (this.manualLinkGenerator.type === 'regeneratePdf') {
      this.manualLinkGenerator.link = `${environment.api}/invoices/${this.manualLinkGenerator.id}/regeneratePdf`;
    }
  }

  calcPercent(num1: number, num2: number): any {
    return ((num1 * 100) / num2).toFixed(0);
  }

  countTotalProfilesAccountActive(): number {
    return this.counters.profiles.filter((item: any) => item.isAccountActive).length;
  }

  countTotalProfilesEmailVerified(): number {
    return this.counters.profiles.filter((item: any) => item.emailVerified).length;
  }

  countTotalPlans(): { free: number, pro: number } {
    return {
      free: this.counters.profiles.filter((item: any) => item.emailVerified && item.isAccountActive && item.plan === 'free').length,
      pro: this.counters.profiles.filter((item: any) => item.emailVerified && item.isAccountActive && item.plan === 'pro').length,
    }
  }

  resendActivateAccountEmail(profile: any): void {
    if (window.confirm(`Confirm resend email to ${profile.displayName} (${profile.email})?`)) {
      this.loadingResendEmailToId = profile.uid;
      this.sub.push(
        this.adminService.reSendAccountValidationEmail(this.validator, profile.uid).subscribe((response: any) => {
          this.messageService.send('toast', 'Email scheduled');
          this.loadingResendEmailToId = '';
        })
      );
    }
  }

  private _getCountersPublic(): void {
    this.loading = true;
    this.sub.push(
      this.adminService.getCountersPublic(this.validator).subscribe((response: any) => {
        this.counters = response;
        this.loading = false;

        const _this = this;
        this.timer = setInterval(() => {
          _this._getCounters();
        }, 1200000); // 20 minutes

      }, (error: any) => {
        console.log(error);
        this.loading = false;
      }
    ));
  }

  private _getCounters(): void {
    this.sub.push(
      this.adminService.getCountersPublic(this.validator).subscribe((response: any) => {
        this.counters = response;
      })
    );
  }

}
