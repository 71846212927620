import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    user: any = Object.assign({});
    settings: any = Object.assign({});
    userStored: string = '_user';
    settingsStored: string = '_settings';

    constructor() { }

    setLogin(user: any): void {
        this.user = user;
        localStorage.setItem(this.userStored, JSON.stringify(this.user));
    }

    setSettings(settings: any): void {
        this.settings = settings;
        localStorage.setItem(this.settingsStored, JSON.stringify(this.settings));
    }

    updateUser(user: any): void {
        var updatedUser: any = { ...this.user, ...user };
        localStorage.setItem(this.userStored, JSON.stringify(updatedUser));
    }

    updateSettings(settings: any): void {
        var updatedSettings: any = { ...this.settings, ...settings };
        localStorage.setItem(this.settingsStored, JSON.stringify(updatedSettings));
    }

    isLoggedIn(): boolean {
        if (this.user.accessToken) {
            return true;
        }

        const storage: any = localStorage.getItem(this.userStored);
        if (storage) {
            this.user = JSON.parse(storage);
            return true;
        }

        return false;
    }

    getUser() {
        if (this.user.accessToken) {
            return this.user;
        }

        const storage = localStorage.getItem(this.userStored);
        if (storage) {
            return JSON.parse(storage);
        }

        return undefined;
    }

    getSettings() {
        if (this.settings.name) {
            return this.settings;
        }

        const storage = localStorage.getItem(this.settingsStored);
        if (storage) {
            return JSON.parse(storage);
        }

        return undefined;
    }

    getUserInterceptor() {
        if (this.user.accessToken) {
            return this.user;
        }

        const storage = localStorage.getItem(this.userStored);
        if (!storage) {
            return false;
        }

        return JSON.parse(storage);
    }

    logout(): void {
        this.user = {};
        localStorage.removeItem(this.userStored);
    }
}
