import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    if (!value || value.length === 0 || value === "") {
      return "";
    }
    const limit: number = args[0];
    const ending: string = args[1] ? `... <span class="cursor-pointer btn btn-link btn-clean d-inline">${args[1]}</span>` : '...';
    if (value.length > limit) {
      return value.substring(0, limit) + ending;
    } else {
      return value;
    }
  }

}
