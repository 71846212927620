import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Customer from 'src/app/models/customer.interface';
import Invoice from 'src/app/models/invoice.interface';
import { HelpersService } from 'src/app/services/helpers.service';
import { InvoicesService } from 'src/app/services/invoices.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {

  customers: Customer[] = [];
  invoices: Invoice[] = [];
  invoicesImmutable: Invoice[] = [];
  loadingInvoices: boolean = false;
  loadingCreateInvoice: boolean = false;
  loadingDuplicateInvoice: boolean = false;

  constructor(
    public helpersService: HelpersService,
    private invoicesService: InvoicesService,
    private messageService: MessageService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this._getInvoices();
  }

  filterInvoicesByColumn(status: string): Invoice[] {
    const invoices: Invoice[] = this.invoicesImmutable.filter((invoice: Invoice) => invoice.status === status);
    return invoices.length > 0 ? invoices : [];
  }

  private _getInvoices(): void {
    this.loadingInvoices = true;
    this.invoicesService.getInvoices().subscribe((response: any) => {
      if (response.message !== "OK") {
        this.messageService.send("toast", "Erro ao carregar as faturas");
        this.loadingInvoices = false;
        return;
      }

      this.invoices = this.invoicesImmutable = response.invoices;
      this.loadingInvoices = false;
    });
  }

  createInvoice(): void {
    this.loadingCreateInvoice = true;
    this.invoicesService.createInvoice().subscribe((response: any) => {
      this.messageService.send('toast', 'Fatura criada!');
      this.loadingCreateInvoice = false;

      this.messageService.send('InvoiceTrackingHistoryComponent', 'newAction', {
        invoiceId: response.newInvoiceId,
        invoiceRef: response.newInvoiceRef,
        action: 'create',
        createdAt: (new Date()),
      });

      this.router.navigate(['/panel/invoices', response.newInvoiceId, 'editor']);
    });
  }

  countInvoicesByStatus(status: string): number {
    return this.invoicesImmutable.filter((invoice: Invoice) => invoice.status === status).length;
  }

  filterInvoices($event: any): void {
    const filterValue = $event.target.value === 'paymentProof' ? $event.target.value : $event.target.value.toLowerCase();
    this.invoices = (filterValue === 'all') ? 
      this.invoicesImmutable : 
      this.invoicesImmutable.filter((invoice: Invoice) => invoice.status === filterValue);
  }

}
