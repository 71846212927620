import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MessageService } from 'src/app/services/message.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-confirm-account',
  templateUrl: './confirm-account.component.html',
  styleUrls: ['./confirm-account.component.scss']
})
export class ConfirmAccountComponent implements OnInit, OnDestroy {

  sub: Subscription = new Subscription();
  isConfirmed: boolean = false;
  loading: boolean = false;

  constructor(
    private usersService: UsersService,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      this._getConfirmationLink(params.uid);
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  private _getConfirmationLink(uid: string): void {
    this.loading = true;
    this.sub = this.usersService.confirmAccount(uid).subscribe((response: any) => {
      this.isConfirmed = true;
      this.loading = false;
    }, (err: any) => {
      this.messageService.send('toast', 'Profile not found');
      this.loading = false;
    });
  }

}
