import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  standalone: true,
  selector: 'app-typebot',
  templateUrl: './typebot.component.html',
  styleUrls: ['./typebot.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule],
})
export class TypebotComponent {

  @Input() user: any;
  typebotUrl: SafeResourceUrl = '';

  constructor(
    private offcanvasService: NgbOffcanvas,
    private _sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.typebotUrl = this._sanitizer.bypassSecurityTrustResourceUrl(`https://viewer.typebot.io/${environment.typebotId}?email=${this.user.email || ''}`);
  }

  /*
  openChat() {
    Typebot.initPopup({
      typebot: 'invoicefy-suporte-r35oa80',
      autoShowDelay: 0,
      autoShow: true,
      prefilledVariables: {
        email: this.user.email || '',
      },
    });
  }
  */

  openOffcanvas(content: any): void {
    this.offcanvasService.open(content, { backdrop: false }).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(`Dismissed ${reason}`);
    });
  }

}
