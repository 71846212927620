import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbOffcanvas, NgbOffcanvasOptions, OffcanvasDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { MessageService } from 'src/app/services/message.service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  isLoggedIn: boolean = false;
  user: any = {};

  constructor(
    private authService: AuthService,
    private router: Router,
    private offcanvasService: NgbOffcanvas,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this._checkIfUserIsLoggedIn();
      }
    });

    this._getUser();

    this.messageService.get().subscribe((message: any) => {
      if (message.to === 'NavbarComponent') {
        switch (message.action) {
          case 'upgradedToProSuccess':
            this._upgradedToProSuccess();
            break;
          case 'setUser':
            this._setUser(message.payload);
            break;
          default:
            break;
        }
      }
    });
  }

  private _getUser(): void {
    this.user = this.authService.getUser();
    if (!this.user) {
      this.logout();
    }
  }

  private _setUser(payload: any): void {
    this.user = payload;
  }

  private _upgradedToProSuccess(): void {
    this.user.plan = 'pro';
    this.authService.setLogin(this.user);
  }

  private _checkIfUserIsLoggedIn(): void {
    this.isLoggedIn = this.authService.isLoggedIn();
  }

  logout(): void {
    this.user = null;
    this.authService.logout();
  }

  openOffcanvas(content: any): void {
    this.offcanvasService.open(content).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(`Dismissed ${reason}`);
    });
  }

}
