import { Component, Input, OnInit } from '@angular/core';
import Invoice from 'src/app/models/invoice.interface';
import { HelpersService } from 'src/app/services/helpers.service';
import { environment } from 'src/environments/environment';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-invoice-public-link',
  templateUrl: './invoice-public-link.component.html',
  styleUrls: ['./invoice-public-link.component.scss']
})
export class InvoicePublicLinkComponent implements OnInit {

  @Input() invoice: Invoice = {} as Invoice;
  projectUrl: string = '';

  constructor(
    public helpersService: HelpersService,
  ) { }

  ngOnInit(): void {
    this.projectUrl = environment.front;
  }

  shareToWhatsapp(): void {
    const total: any = this.helpersService.currencyFormatter(
                          this.helpersService.getCurrencyDetails(this.invoice.currency).locale,
                          this.invoice.currency.toUpperCase(),
                          this.invoice.grandTotal
                        );
    const dueDate: string = (this.invoice.dueDate) ?
                              `, com data de vencimento em ${dayjs(this.invoice.dueDate).format(this.helpersService.getCurrencyDetails(this.invoice.currency).dateFormat.toUpperCase())},` :
                              '';

    let content: string = "Olá";

    content += (this.invoice.customer.name) ? ` ${this.invoice.customer.name}. ` : '. ';
    content += `Segue o link para visualizar a fatura #${this.invoice.ref}`;
    content += dueDate;
    content += ` no valor de ${total}: ${this.projectUrl}/invoice/${this.invoice.id}`;

    this.helpersService.shareToWhatsapp(content, this.invoice.customer.phone);
  }

}
