// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  front: 'https://dev.invoicefy.io',
  api: 'https://us-central1-invoices-dev-ff077.cloudfunctions.net/app',
  paymentLinkRecommendation: 'https://wise.com/invite/u/lucasm4097',
  typebotId: 'invoicefy-suporte-r35oa80',
  firebaseConfig: {
    apiKey: "AIzaSyBbR6W2ErPI7a1XU_FDcoLwJ1T8i1UaZto",
    authDomain: "invoices-dev-ff077.firebaseapp.com",
    projectId: "invoices-dev-ff077",
    storageBucket: "invoices-dev-ff077.appspot.com",
    messagingSenderId: "423115438029",
    appId: "1:423115438029:web:aa2aaa9614691f87a2f684",
    measurementId: "G-PWLPL4QSJK"
  },
  paypalClientId: "AWllio2WnLGfcZWFMo2jI_oIAZa7kIBOiCJOKcvCPhIGDdruEJGmpChhryuhzD8OYSzEJm-EPrLmln_e", // Sandbox - Business Account
  emailFromDisplay: 'hello@invoicefy.io',
  bugsnagApiKey: "ae77061271de992aa3c7de3772134350",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
