import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })

export class MessageService {

    private subject = new Subject<any>();

    send = (to: any, action: any, payload: any = null) => this.subject.next({ to, action, payload });

    get = (): Observable<any> => this.subject.asObservable();
}