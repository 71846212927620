<div class="container">
    <div class="row justify-content-center">
        <div class="col-12 col-md-6">

            <app-header>
                <span>Terms of Service</span>
                <p class="text-muted">
                    Read our terms of service.
                </p>
            </app-header>

            <div class="card border-0">
                <div class="card-body">

                    <p>
                        This document ("Terms of Use") was prepared to confirm our commitment to the confidentiality and protection of your information against loss, unauthorized use and manipulation of data, in addition to defining the permitted ways to use the tool.
                    </p>
                    <p>
                        Access to Invoicefy represents your express and unrestricted acceptance of the terms described below. Read them carefully.
                    </p>
                    <p>
                        <strong>1. System Access</strong>
                    </p>
                    <p>
                        Access to Invoicefy can be done in two ways:
                    </p>
                    <ul>
                        <li>(a) upon registration, when information capable of identifying you will be requested in order to allow, if necessary, our team to contact you. When deciding to provide your data, you declare to know and accept the terms of Collection and Use of Information of this policy;</li>
                        <li>(b) through a unique link, which allows access - without registration - to a specific page of the invoice, available exclusively for viewing the invoice, as well as its payment.</li>
                    </ul>
                    <p>
                        <strong>2. Collection and Use of Information</strong>
                    </p>
                    <p>
                        Invoicefy automatically receives and stores, through cookies and sessions, information about the browser's activities, including the IP address and the page accessed. These activity records (logs) are only used for the purposes described below:
                    </p>
                    <ul>
                        <li>(a) statistics and metrics for the use of the services made available;</li>
                        <li>(b) inform about new content and messages;</li>
                        <li>(c) updating contact information;</li>
                        <li>(d) optimize usability and experience while browsing the site;</li>
                        <li>(e) comply with legal or judicial determinations in cases of investigation of civil or criminal offenses, especially when there is suspicion of fraud, malicious attacks, changes in the system or improper access to protected information and registration data of other users.</li>
                    </ul>
                    <p>
                        <strong>2. Custody and Responsibility for Collected Information</strong>
                    </p>
                    <p>
                        Any information received will be stored in accordance with the strictest security and reliability standards available. Access to collected information is restricted to employees and authorized persons.
                    </p>
                    <p>
                        Those who misuse this information, in violation of these Terms, are subject to administrative and disciplinary sanctions, without excluding applicable legal measures.
                    </p>
                    <p>
                        Except for legal or judicial determination, the information of users registered with Invoicefy will never be transferred to third parties or used for purposes other than those for which they were collected.
                    </p>
                    <p>
                        <strong>3. Information, Opinions and Content Generated by Third Parties</strong>
                    </p>
                    <p>
                        Invoicefy enables interaction between you and your customers through the exchange of information and publication of content.
                        <br>
                        The information and contents, as well as their quality and veracity, are the sole responsibility of their creators.
                    </p>
                    <p>
                        <strong>4. Responsibility of Users</strong>
                    </p>
                    <p>
                        It is your responsibility to keep your account password safe. It is not recommended to use obvious passwords, such as special dates, names or numerical sequences.
                    </p>
                    <p>
                        If you become aware or just suspect that your access password has been discovered, change it immediately in your page settings.
                    </p>
                    <p>
                        This Policy is subject to constant improvements and enhancements. We recommend that you consult it regularly.
                    </p>

                    <div class="separator-20"></div>

                    <small class="text-muted">
                        Last updated: January 20, 2023.
                    </small>

                </div>
            </div>

        </div>
    </div>
</div>
