import { Component, Input, OnInit } from '@angular/core';
import Invoice from 'src/app/models/invoice.interface';
import { HelpersService } from 'src/app/services/helpers.service';

@Component({
  selector: 'app-invoice-status',
  templateUrl: './invoice-status.component.html',
  styleUrls: ['./invoice-status.component.scss']
})
export class InvoiceStatusComponent implements OnInit {

  @Input() invoice: Invoice = {} as Invoice;
  @Input() status: string = '';

  constructor(
    public helpersService: HelpersService,
  ) { }

  ngOnInit(): void {
    if (this.status !== '') {
      this.invoice = {
        status: this.status,
        cancelNote: '',
      } as Invoice;
    }
  }

}
