<div class="container">

    <div class="row justify-content-center">
        <div class="col-12 col-md-8">

            <div class="page-header text-center">
                <img src="/assets/images/pro.svg" class="img-fluid" alt="" width="220px">
                <div class="separator-30"></div>
                <h3>
                    Invoice like a <span class="text-primary"><strong>PRO</strong></span>.
                </h3>
                <p>
                    Check out the best features to help you on invoicing and billing.
                </p>
            </div>

            <div class="separator-20"></div>

            <div class="separator"><span class="dot"></span></div>

            <div class="separator-70"></div>

            <div *ngFor="let item of features; let i = index">
                <span *ngIf="item.beta" class="badge bg-secondary" [class.ms-2]="item.pro && item.beta">BETA</span>
                <span *ngIf="item.new" class="badge bg-success" [class.ms-2]="(item.pro || item.beta) && item.new">NEW</span>
                <div class="row mt-2">
                    <div class="col-6" [ngClass]="item.imageUrl ? 'col-md-6' : 'col-md-12'">
                        <h3><strong>{{ item.title }}</strong></h3>
                        <p class="justify-content">{{ item.description }}</p>
                    </div>
                    <div *ngIf="item.imageUrl" class="col-6 col-md-6 text-end">
                        <div class="card border-0 shadow-lg">
                            <div class="card-body">
                                <img [src]="item.imageUrl" [alt]="item.title" class="img-fluid">
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="+((features.length/2).toFixed(0)) == i">
                    <div class="separator-40"></div>

                    <a [routerLink]="['/auth/signup']" class="btn btn-sm btn-link p-0">Create Your Account &rarr;</a>
                </div>

                <div class="separator-40"></div>
            </div>

            <div class="separator-40"></div>

            <div class="text-center">
                <a [routerLink]="['/auth/signup']" class="btn btn-primary btn-lg">Create Account</a>
            </div>

        </div>
    </div>
</div>


<!--
<table class="table">
    <tr>
        <td></td>
        <td>
            <h4>
                <strong>
                    Free<br>
                    <span class="text-muted">$0</span>
                </strong>
            </h4>
        </td>
        <td>
            <h4>
                <strong>
                    Pro<br>
                    <span class="text-muted">$9.90 / mo</span>
                </strong>
            </h4>
        </td>
    </tr>

    <tr>
        <td class="text-start">Unlimited invoices</td>
        <td><i class="fas fa-check text-success"></i></td>
        <td><i class="fas fa-check text-success"></i></td>
    </tr>
    <tr>
        <td class="text-start">Unlimited customers</td>
        <td><i class="fas fa-check text-success"></i></td>
        <td><i class="fas fa-check text-success"></i></td>
    </tr>
    <tr>
        <td class="text-start">Unlimited attachments per invoice</td>
        <td>
            <i class="fas fa-times text-danger"></i>
            <br>
            <small class="text-muted">
                Limited to 1
            </small>
        </td>
        <td><i class="fas fa-check text-success"></i></td>
    </tr>
    <tr>
        <td class="text-start">Email Support</td>
        <td><i class="fas fa-check text-success"></i></td>
        <td><i class="fas fa-check text-success"></i></td>
    </tr>
    <tr>
        <td class="text-start">Phone Support</td>
        <td><i class="fas fa-times text-danger"></i></td>
        <td><i class="fas fa-check text-success"></i></td>
    </tr>
    <tr>
        <td class="text-start">USD and BRL currencies</td>
        <td><i class="fas fa-check text-success"></i></td>
        <td><i class="fas fa-check text-success"></i></td>
    </tr>
    <tr>
        <td class="text-start">Invoice tracking</td>
        <td><i class="fas fa-check text-success"></i></td>
        <td><i class="fas fa-check text-success"></i></td>
    </tr>
    <tr>
        <td class="text-start">Get paid via link</td>
        <td><i class="fas fa-check text-success"></i></td>
        <td><i class="fas fa-check text-success"></i></td>
    </tr>
    <tr>
        <td class="text-start">Get paid via integration</td>
        <td><i class="fas fa-check text-success"></i></td>
        <td><i class="fas fa-check text-success"></i></td>
    </tr>
    <tr>
        <td class="text-start">
            Transaction fee<br>
            <small class="text-muted">
                If you get paid via integration, we charge a small fee
            </small>
        </td>
        <td class="text-muted">2%</td>
        <td class="text-muted">No Fee</td>
    </tr>
    <tr>
        <td class="text-start">Invoice reply catcher</td>
        <td><i class="fas fa-times text-danger"></i></td>
        <td><i class="fas fa-check text-success"></i></td>
    </tr>
    <tr>
        <td class="text-start">Recurrent invoice</td>
        <td><i class="fas fa-times text-danger"></i></td>
        <td><i class="fas fa-check text-success"></i></td>
    </tr>
    <tr>
        <td class="text-start">Scheduled invoice</td>
        <td><i class="fas fa-times text-danger"></i></td>
        <td><i class="fas fa-check text-success"></i></td>
    </tr>
    <tr>
        <td class="text-start">Invoice customization</td>
        <td><i class="fas fa-times text-danger"></i></td>
        <td><i class="fas fa-check text-success"></i></td>
    </tr>
    <tr>
        <td class="border-0"></td>
        <td class="border-0">
            <div class="separator-20"></div>
            <a href="https://app.invoicefy.io/auth/signup?utm_source=pricing_free" target="_blank" rel="noopener noreferrer" class="btn btn-lg btn-block btn-outline-primary">Get Started</a>
        </td>
        <td class="border-0">
            <div class="separator-20"></div>
            <a href="https://app.invoicefy.io/auth/signup?utm_source=pricing_pro" target="_blank" rel="noopener noreferrer" class="btn btn-lg btn-block btn-primary">Try it out</a>
        </td>
    </tr>
</table>
-->