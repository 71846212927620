import { Component, Input } from '@angular/core';
import Invoice from 'src/app/models/invoice.interface';
import { HelpersService } from 'src/app/services/helpers.service';

@Component({
  selector: 'app-invoice-details-readonly',
  templateUrl: './invoice-details-readonly.component.html',
  styleUrls: ['./invoice-details-readonly.component.scss']
})
export class InvoiceDetailsReadonlyComponent {

  @Input() invoice: Invoice = {} as Invoice;
  @Input() role: string = "customer"; // customer | owner

  constructor(
    public helpersService: HelpersService,
  ) { }

}
