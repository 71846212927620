import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import Attachment from 'src/app/models/attachment.interface';
import Customer from 'src/app/models/customer.interface';
import Invoice from 'src/app/models/invoice.interface';
import Item from 'src/app/models/item.interface';
import { AuthService } from 'src/app/services/auth.service';
import { HelpersService } from 'src/app/services/helpers.service';
import { InvoicesService } from 'src/app/services/invoices.service';
import { MessageService } from 'src/app/services/message.service';
import { environment } from 'src/environments/environment';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-invoice-editor',
  templateUrl: './invoice-editor.component.html',
  styleUrls: ['./invoice-editor.component.scss']
})
export class InvoiceEditorComponent implements OnInit {

  invoiceId: string = '';
  invoice: Invoice = {} as Invoice;
  loadingInvoice: boolean = false;
  loadingSaveInvoice: boolean = false;
  loadingSendInvoice: boolean = false;
  toggleAddItem: boolean = false;
  toggleAdditionalRecipients: boolean = false;
  accordionActiveIds: string = '';
  loadingUploadAttachment: boolean = false;
  uploadProgress: number = 0;
  toggleAddPaymentLink: boolean = false;
  newLink: string = '';
  customers: Customer[] = [];
  loadingCreateCustomer: boolean = false;
  newItem: Item = {} as Item;
  isInvoiceReady: boolean = false;
  invoiceFileUrl: string = "";
  sub: Subscription[] = [];
  invoiceSettings: any = {};
  user: any = {};
  today: string = "";
  todayPlus2: string = ""; // scheduled Invoices
  todayPlus30: string = ""; // scheduled Invoices

  constructor(
    public helpersService: HelpersService,
    private activatedRoute: ActivatedRoute,
    private invoicesService: InvoicesService,
    private router: Router,
    private messageService: MessageService,
    private modalService: NgbModal,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this._getInvoiceId();
    this._getInvoiceSettings();
    this._getUser();

    this.today = dayjs().add(1, "day").format("YYYY-MM-DD");
    this.todayPlus2 = dayjs().add(2, "day").format("YYYY-MM-DD");
    this.todayPlus30 = dayjs().add(30, "day").format("YYYY-MM-DD");

    this.messageService.get().subscribe((message: any) => {
      if (message.to === 'InvoiceEditorComponent') {
        if (message.action === 'onRestoreVersion') {
          this.onRestoreVersion(message.payload);
        }
      }
    });
  }

  private _getUser(): void {
    this.user = this.authService.getUser();
  }

  private _getInvoiceSettings(): void {
    this.invoiceSettings = this.authService.getSettings();
  }

  clearDueDate(): void {
    this.invoice.dueDate = "";
    this.checkDueDateAndReminders();
  }

  parseEmailSubject(): string {
    return this.invoiceSettings.emailSubject.replace(/\[invoice_ref\]/g, `#${this.invoice.ref}`);
  }

  private _getInvoiceId(): void {
    this.sub.push(
      this.activatedRoute.params.subscribe((params: any) => {
        this.invoiceId = params.id;
        this._getInvoiceById(this.invoiceId);
      })
    );
  }

  // on this.invoice.dueDate change, check if it's today + 5 days
  checkDueDateAndReminders(): void {
    if (this.invoice.dueDate && !this.isTodayLessThan5DaysOfDueDate()) {
      this.invoice.sendReminder = false;
      return;
    }

    this.invoice.sendReminder = false;
  }

  getFirstReminderDate(): string {
    return dayjs(this.invoice.dueDate).subtract(2, 'day').format("DD/MM/YYYY");
  }

  private _getInvoiceById(id: string): void {
    this.loadingInvoice = true;
    this.sub.push(
      this.invoicesService.getInvoiceById(id).subscribe((response: Invoice) => {
        this.invoice = response;

        if (this.invoice.dueDate) {
          this.invoice.dueDate = this.helpersService.convertFirebaseTimestampToString(this.invoice.dueDate, "YYYY-MM-DD");

          if (this.isTodayLessThan5DaysOfDueDate()) {
            this.invoice.sendReminder = false;
          }
        }

        this._checkIfInvoiceHasCcOrBcc();

        if (this.invoice.items) {
          if (this.invoice.items.length === 0) {
            this.toggleAddItem = true;
          }

          // Check if invoice has items to order them by price ASC
          if (this.invoice.items.length > 0) {
            this.invoice.items.sort((a: Item, b: Item) => a.price - b.price);
          }
        }

        this._checkIfInvoiceHasExtraItems();

        this.loadingInvoice = false;
      }, (err: any) => {
        console.log('err', err);
        this.loadingInvoice = false;
        if (+err.status === 403) {
          this.router.navigate(['/panel/not-found']);
        }
      })
    );
  }

  // To start the CC/BCC closed or opened
  _checkIfInvoiceHasCcOrBcc(): void {
    if (this.invoice.cc || this.invoice.bcc) {
      this.toggleAdditionalRecipients = true;
    }
  }

  // To start the accordion closed or opened
  _checkIfInvoiceHasExtraItems(): void {
    this.accordionActiveIds = (this.invoice.attachments && this.invoice.attachments.length == 0 && this.invoice.notes.trim() == '') ? '' : 'ngb-panel-0';
  }

  // Called from messageService
  // When user restores an invoice version
  onRestoreVersion(invoice: Invoice): void {
    this.invoice = invoice;
    this._checkIfInvoiceHasCcOrBcc();
    this._checkIfInvoiceHasExtraItems();
  }

  addNewPaymentLink(): void {
    if (!this.invoice.paymentLinksAlternative || this.invoice.paymentLinksAlternative.length === 0) {
      this.invoice.paymentLinksAlternative = [this.newLink];
    } else {
      this.invoice.paymentLinksAlternative.push(this.newLink);
    }
    this.newLink = '';
    this.toggleAddPaymentLink = false;
  }

  removeInvoiceAlternativeLink(item: string): void {
    const index = this.invoice.paymentLinksAlternative.indexOf(item);
    this.invoice.paymentLinksAlternative.splice(index, 1);
  }

  openPaymentRecommendationLink(): void {
    window.open(environment.paymentLinkRecommendation, '_blank');
  }

  unselectCustomer(): void {
    this.invoice.customer = {} as Customer;
  }

  hasAtLeastOneItem(): boolean {
    if (this.invoice.items && this.invoice.items.length > 0 || this.invoice.items.filter((item: Item) => item.name.trim() === '' || item.price === 0).length > 0) {
      return true;
    }
    return false;
  }

  getCurrencyFormatOptions(): any {
    return this.helpersService.currencies.find((item: any) => item.currency.toUpperCase() === this.invoice.currency.toUpperCase()).format;
  }

  setCustomer($event: any, newCustomerModalContent: any = null): void {
    const selectedCustomerId: any = ($event.target && $event.target.value) ? $event.target.value : $event || 0;
    if (selectedCustomerId == 0) {
      return;
    }

    if (selectedCustomerId === 'create-new-customer') {
      this.modalService.open(newCustomerModalContent, { backdrop: 'static' }).result.then((result) => {
        console.log(`Closed with: ${result}`);
      }, (reason) => {
        console.log(`Dismissed ${reason}`);
      });
      return;
    }

    if (selectedCustomerId) {
      this.invoice.customer = this.customers.find((customer: any) => customer.id === selectedCustomerId) as Customer;
      return;
    }

    this.invoice.customer = {} as Customer;
  }

  onSubmitCustomer($event: any): void {
    this.loadingCreateCustomer = false;
    const response: any = $event;
    if (response.action === 'create') {
      this.customers.push(response.response);
      this.customers.sort((a: any, b: any) => a.name.localeCompare(b.name) ? 1 : -1);
      this.setCustomer(response.response.id);
      this.messageService.send('toast', 'Cliente adicionado!');
      this.helpersService.closeModals();
    }
  }

  private _calcInvoiceSubTotal(): void {
    this.invoice.total = this.invoice.grandTotal = this.invoice.items.reduce((total: number, item: Item) => {
      return total + item.price;
    }, 0);
    this.calcInvoiceDiscount();
  }

  calcInvoiceDiscount(): void {
    if (this.invoice.discount) {
      this.invoice.discount = +this.invoice.discount;
      this.invoice.grandTotal = this.invoice.total - this.invoice.discount;
      return;
    }
    this.invoice.discount = 0;
    this.invoice.grandTotal = this.invoice.total;
  }

  setCurrency(newCurrency: string): void {
    this.invoice.currency = newCurrency.toUpperCase();
  }

  removeInvoiceItem(item: Item): void {
    const index = this.invoice.items.indexOf(item);
    this.invoice.items.splice(index, 1);
    this.invoice.total = this.invoice.total - item.price;
    this.invoice.grandTotal = this.invoice.total - this.invoice.discount;
  }

  removeInvoiceAttachment(attachment: Attachment): void {
    const index = this.invoice.attachments.indexOf(attachment);
    this.invoice.attachments.splice(index, 1);
  }

  removeInvoiceDiscount(): void {
    this.invoice.discount = 0;
    this.calcInvoiceDiscount();
  }

  addItemToInvoice(): void {
    this.invoice.items = this.invoice.items || [];
    if (this.newItem.name && this.newItem.price) {
      this.invoice.items.push(this.newItem);
      this.newItem = {
        name: '',
        price: 0,
      }

      // Sort items by price ASC
      this.invoice.items.sort((a: Item, b: Item) => a.price - b.price);

      this._calcInvoiceSubTotal();
      this.toggleAddItem = false;
    }
  }

  onLoadingUploadFile($event: any): void {
    this.loadingUploadAttachment = $event;
  }

  onUploadFile($event: any): void {
    if (!this.invoice.attachments) {
      this.invoice.attachments = [];
    }

    this.invoice.attachments.push({
      fileName: $event.name,
      fileUrl: $event.downloadUrl,
      type: $event.type,
      filePath: $event.filePath,
    });
  }

  submitInvoice(action: string = 'draft'): void {
    if (this.toggleAdditionalRecipients) {
      if (this.invoice.cc && !this.helpersService.isValidEmail(this.invoice.cc)) {
        this.messageService.send('toast', 'O email adicionado no campo CC não é válido');
        return;
      }

      if (this.invoice.bcc && !this.helpersService.isValidEmail(this.invoice.bcc)) {
        this.messageService.send('toast', 'O email adicionado no campo CCO não é válido');
        return;
      }
    }

    if (!this.toggleAdditionalRecipients || !this.invoice.customer.email) {
      this.invoice.cc = '';
      this.invoice.bcc = '';
    }

    if (!this.invoice.sendCopyToMe) {
      this.invoice.sendCopyToMe = false;
    }

    if (!this.invoice.sendReminder) {
      this.invoice.sendReminder = false;
    }

    // Check if any item is added and all items have a name and price
    if (!this.hasAtLeastOneItem()) {
      this.messageService.send('toast', 'Adicione pelo menos um item');
      return;
    }

    if (!this.invoice.customer || (this.invoice.customer && !this.invoice.customer.id)) {
      this.invoice.customer = {} as Customer;
    }

    // If (no dueDate is set), then no reminder will be sent
    // Else, check if dueDate is set to past
    if (!this.invoice.dueDate || this.invoice.dueDate === '') {
      this.invoice.sendReminder = false;
    } else {
      if (this.isTodayLessThan5DaysOfDueDate()) {
        this.messageService.send('toast', 'A data de vencimento não pode ser no passado');
        return;
      }
    }

    if (action === 'draft') {
      this._submitAsDraft();
      return;
    }

    this._sendInvoice();
  }

  isTodayLessThan5DaysOfDueDate(): boolean {
    if (dayjs(this.invoice.dueDate).isBefore(dayjs().add(5, 'day'), 'day')) {
      return true;
    }
    return false;
  }

  private _submitAsDraft(): void {
    const currentDate: Date = new Date();
    this.loadingSaveInvoice = true;
    this.sub.push(
      this.invoicesService.updateInvoice(this.invoice).subscribe(() => {
        this.messageService.send('toast', 'Fatura salva como Rascunho');
        this.invoice.updatedAt = currentDate;
        this.loadingSaveInvoice = false;

        this.messageService.send('InvoiceTrackingHistoryComponent', 'newAction', {
          invoiceId: this.invoice.id,
          invoiceRef: this.invoice.ref,
          action: 'update',
          createdAt: currentDate,
        });
      }, (err: any) => {
        console.log('Error', err);
        this.messageService.send('toast', `Erro ao salvar a fatura: ${err.error.message}`);
        this.loadingSaveInvoice = false;
      })
    );
  }

  private _sendInvoice(): void {
    const currentDate: Date = new Date();
    this.loadingSaveInvoice = true;
    this.loadingSendInvoice = true;

    this.sub.push(
      this.invoicesService.sendInvoice(this.invoice).subscribe(() => {
        this.invoice.status = this.invoice.scheduledTo === '' ? 'sent' : 'scheduled';
        this.invoice.updatedAt = currentDate;
        this.invoice.sentAt = currentDate;
        this.messageService.send('InvoiceTrackingHistoryComponent', 'newAction', {
          invoiceId: this.invoice.id,
          invoiceRef: this.invoice.ref,
          action: this.invoice.scheduledTo === '' ? 'send' : 'scheduled',
          createdAt: currentDate,
        });
        this.modalService.dismissAll();
        this.loadingSaveInvoice = false;
        this.loadingSendInvoice = false;
        this.router.navigate(['/panel/invoices', this.invoice.id, 'review']);
      }, (err: any) => {
        console.log('Error', err);
        this.messageService.send('toast', `Erro ao enviar a fatura: ${err.error.message}`);
        this.loadingSaveInvoice = false;
        this.loadingSendInvoice = false;
      })
    );
  }

  openModalConfirmSendInvoice(content: any): void {
    this.modalService.open(content, { backdrop: 'static', size: 'lg' }).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(`Dismissed ${reason}`);
    });
  }

  removeAttachment(attachment: Attachment): void {
    this.invoice.attachments = this.invoice.attachments.filter((item: Attachment) => {
      return item !== attachment;
    });
  }

}
