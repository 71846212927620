<div class="container mb-4">

    <div class="row d-flex justify-content-center">
        <div class="col-12 col-md-4">

            <div class="page-header">
                <div class="badge primary mb-3">Identifique-se</div>
                <h3>Entrar</h3>
                <p>
                    Faça login para acessar sua conta.
                </p>
            </div>

        </div>
    </div>
</div>

<section class="bg-super-light content-section">
    <div class="container">
        <div class="row d-flex justify-content-center">
            <div class="col-12 col-md-4">

                <div class="card border-0">
                    <div class="card-body">

                        <form #formLogin="ngForm" (submit)="login(formLogin.value)" novalidate>
                            <div class="form-group mb-2">
                                <label>Email</label>
                                <input [disabled]="loading" ngModel name="email" type="email" email class="form-control lowercase" required autocomplete="off">
                            </div>
                            <div class="form-group mb-2">
                                <label>Senha</label>
                                <input [disabled]="loading" ngModel name="password" type="password" class="form-control" required autocomplete="off">
                            </div>

                            <div class="separator-5"></div>

                            <small>
                                <a [routerLink]="['/auth/recover-password']" class="underline-none">Esqueci minha senha</a>
                            </small>

                            <div class="separator-10"></div>

                            <button [disabled]="!formLogin.valid || loading" type="submit" class="btn btn-primary mt-4">
                                <app-loading *ngIf="loading" parent="button"></app-loading>
                                <i *ngIf="!loading" class="fas fa-check-circle me-2"></i> Entrar
                            </button>
                        </form>

                    </div>
                </div>

                <div class="text-center">
                    <div class="separator-20"></div>
                    <small class="text-muted">- ou -</small>
                    <div class="separator-20"></div>
                    <a [routerLink]="['/auth/signup']" class="btn btn-outline-primary">Criar Conta</a>
                </div>
            </div>
        </div>
    </div>
</section>