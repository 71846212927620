import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelpersService } from 'src/app/services/helpers.service';
import { InvoicesService } from 'src/app/services/invoices.service';

import Invoice from 'src/app/models/invoice.interface';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-invoice-detailed-changes-history',
  templateUrl: './invoice-detailed-changes-history.component.html',
  styleUrls: ['./invoice-detailed-changes-history.component.scss']
})
export class InvoiceDetailedChangesHistoryComponent {

  @Input() invoice: any = {};
  @Input() user: any;
  @Output() onRestoreVersion: EventEmitter<Invoice> = new EventEmitter<Invoice>();

  loadingDetailedHistory = false;
  history: any = [];

  constructor(
    public helpersService: HelpersService,
    private invoicesService: InvoicesService,
    private modalService: NgbModal,
    private messageService: MessageService,
  ) { }

  restoreVersion = (invoice: Invoice) => {
    this.onRestoreVersion.emit(invoice);
    this.modalService.dismissAll();
    this.messageService.send('toast', 'Fatura restaurada com sucesso!');
  }

  getInvoiceDetailedChangesHistory = () => {
    if (this.invoice && this.invoice.id) {
      this.loadingDetailedHistory = true;
      this.invoicesService.getInvoiceDetailedChangesHistory(this.invoice.id).subscribe((response: any) => {
        this.history = response;
        this.history.unshift(this.invoice);
        this.loadingDetailedHistory = false;
      });
    }
  }

  openModalInvoiceDetailedChangesHistory(content: any): void {
    this.getInvoiceDetailedChangesHistory();
    this.modalService.open(content, { backdrop: 'static', size: 'lg' }).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(`Dismissed ${reason}`);
    });
  }

}
