<div class="container mb-4">

    <div class="row d-flex justify-content-center">
        <div class="col-12 col-md-6">

            <div class="page-header">
                <div class="badge primary mb-3">Ótima Escolha!</div>
                <h3>Seja um <span class="text-primary"><strong>PRO</strong></span></h3>
                <p>
                    Ao se tornar um usuário PRO você terá acesso a todas as funcionalidades do sistema, sem limites.
                </p>
            </div>

        </div>
    </div>
</div>

<section class="bg-super-light content-section">
    <div class="container">

        <div class="row justify-content-center">
            <div class="col-12 col-md-6">

                <div class="card border-0">
                    <div class="card-body">

                        Por enquanto o plano <strong>PRO</strong> é somente para convidados.<br>
                        Informe seu email para receber um convite quando houverem mais vagas disponíveis.

                        <div class="separator-30"></div>

                        <app-loading *ngIf="!user"></app-loading>

                        <div *ngIf="user">
                            <form #formUpgrade="ngForm" (submit)="upgradeToPro(formUpgrade.value)" novalidate>
                                <div class="form-group">
                                    <input [disabled]="loadingUpgrade" type="email" email [(ngModel)]="user.email" name="email" class="form-control">
                                </div>

                                <div class="separator-20"></div>

                                <button [disabled]="!formUpgrade.valid || loadingUpgrade" type="submit" class="btn btn-primary">
                                    <app-loading *ngIf="loadingUpgrade" parent="button"></app-loading>
                                    <i *ngIf="!loadingUpgrade" class="fas fa-check-circle me-2"></i> Receber um convite <i>Beta</i>
                                </button>
                            </form>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</section>