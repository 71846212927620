<nav class="navbar navbar-expand-lg mb-2">
    <div class="container">
        <a *ngIf="!isLoggedIn" class="navbar-brand" [href]="['/']">
            <img src="assets/images/invoicefy-logo.png" alt="" class="img-fluid" height="30px" />
        </a>
        <a *ngIf="isLoggedIn" class="navbar-brand" [href]="['/panel']">
            <img src="assets/images/invoicefy-logo.png" alt="" class="img-fluid" height="25px" />
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul *ngIf="!isLoggedIn" class="navbar-nav ms-auto mb-2 mb-lg-0">
                <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" aria-current="page" [routerLink]="['/auth/login']">Login</a>
                </li>
            </ul>
            <ul *ngIf="isLoggedIn" class="navbar-nav ms-auto mb-2 mb-lg-0">
                <li class="nav-item">
                    <a (click)="openOffcanvas(content)" class="nav-link" aria-current="page" href="javascript:void(null)"><i class="fas fa-bell"></i></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" aria-current="page" [routerLink]="['/panel/invoices']">Faturas</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/panel/customers']">Clientes</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/panel/reports']">Relatórios</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/panel/settings']">Ajustes</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="logout()" [routerLink]="['/auth/login']">Sair</a>
                </li>
            </ul>
        </div>
    </div>
</nav>

<!--
<div *ngIf="isLoggedIn" class="container text-end">
    <app-typebot [user]="user"></app-typebot>
</div>
-->

<!-- Timeline invoices history -->
<ng-template #content let-offcanvas offcanvas-lg>
    <div class="offcanvas-header">
        <strong class="offcanvas-title"><i class="fas fa-history me-2"></i> Histórico das Faturas</strong>
        <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
    </div>
    <div class="offcanvas-body">
        <app-invoice-tracking-history parent="offcanvas" [title]="false" [offcanvas]="offcanvas"></app-invoice-tracking-history>
        <div class="separator-30"></div>
        <button type="button" class="btn btn-outline-dark btn-sm" (click)="offcanvas.close('Save click')">Fechar</button>
    </div>
</ng-template>