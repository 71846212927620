import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Invoice from 'src/app/models/invoice.interface';
import { HelpersService } from 'src/app/services/helpers.service';
import { InvoicesService } from 'src/app/services/invoices.service';
import { MessageService } from 'src/app/services/message.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-invoice-public',
  templateUrl: './invoice-public.component.html',
  styleUrls: ['./invoice-public.component.scss']
})
export class InvoicePublicComponent implements OnInit, OnDestroy {

  sub: Subscription[] = [];

  gateway: string = 'paypal';
  invoiceId: string = '';
  invoice: Invoice = {} as Invoice;
  error: any = null;

  loadingInvoice: boolean = false;
  loadingPaymentInvoice: boolean = false;

  uploadProgress: number = 0;
  loadingUploadPaymentProof: boolean = false;

  invoiceSafeUrl: string = '';

  constructor(
    public helpersService: HelpersService,
    private activatedRoute: ActivatedRoute,
    private invoicesService: InvoicesService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.sub.push(
      this.activatedRoute.params.subscribe((params: any) => {
        this.invoiceId = params.id;
        this._getInvoiceById();
      })
    );
  }

  ngOnDestroy(): void {
    this.sub.forEach((s: Subscription) => s.unsubscribe());
  }

  private _getInvoiceById(): void {
    this.loadingInvoice = true;
    this.sub.push(
      this.invoicesService.getPublicInvoiceById(this.invoiceId).subscribe((response: Invoice) => {
        this.invoice = response;

        if (this.invoice.invoiceFile && this.invoice.invoiceFile.fileUrl) {
          this.invoiceSafeUrl = this.helpersService.sanitizeUrl(this.invoice.invoiceFile.fileUrl);
        }

        this.loadingInvoice = false;
      }, (err: any) => {
        console.log('Error _getInvoiceById()', err)
        this.loadingInvoice = false;
        if (err.status === 404) {
          this.error = 'notFound';
        }
      })
    );
  }

  onUpdateUploadProgress(progress: number): void {
    this.uploadProgress = progress;
  }

  onLoadingUploader($event: any): void {
    this.loadingUploadPaymentProof = $event;
  }

  onUploadFile($event: any): void {
    this.invoice.paymentProof = {
      fileName: $event.name,
      fileUrl: $event.downloadUrl,
      type: $event.type,
      filePath: $event.filePath,
      updatedAt: (new Date()),
    };

    this.invoice.status = 'paymentProof';
    this.messageService.send('toast', 'Comprovante de pagamento enviado. Aguardando aprovação.');
    this.loadingUploadPaymentProof = false;

    this.sub.push(
      this.invoicesService.addPaymentProofToInvoice(this.invoice, 'customer').subscribe(() => {}, (err: any) => {
        this.messageService.send('toast', 'Erro ao enviar comprovante de pagamento.');
        this.invoice.status = 'sent';
      })
    );
  }

  openExternalLink(link: string): void {
    window.open(link, '_blank');
  }

}
