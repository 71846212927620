import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import Invoice from 'src/app/models/invoice.interface';
import { HelpersService } from 'src/app/services/helpers.service';
import { InvoicesService } from 'src/app/services/invoices.service';
import { MessageService } from 'src/app/services/message.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-invoice-tracking-history',
  templateUrl: './invoice-tracking-history.component.html',
  styleUrls: ['./invoice-tracking-history.component.scss']
})
export class InvoiceTrackingHistoryComponent implements OnInit, OnDestroy {

  @Input() offcanvas: any;

  @Input() parent: string = 'page'; // page | offcanvas
  @Input() title: boolean = true;
  @Input() invoice: Invoice | any = {};
  @Input() newAction: { invoiceId: string, invoiceRef: string, createdAt: Date } | any = {} as any;
  history: any = [];
  loadingHistory: boolean = false;

  user: any;
  sub: Subscription[] = [];

  constructor(
    public helpersService: HelpersService,
    private authService: AuthService,
    private invoicesService: InvoicesService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.user = this.authService.getUser();
    this._getHistory();

    this.messageService.get().subscribe((message: any) => {
      if (message.to === 'InvoiceTrackingHistoryComponent') {
        if (message.action === 'newAction') {
          this._getNewActions(message.payload);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.sub.forEach((s: Subscription) => s.unsubscribe());
  }

  onRestoreVersion(invoice: Invoice): void {
    this.messageService.send('InvoiceEditorComponent', 'onRestoreVersion', invoice);
  }

  private _getNewActions(payload: { invoiceId: string, invoiceRef: string, action: string, createdAt: Date }): void {
    this.history.unshift(payload);
    this.history = this.history.slice(0, 15);
  }

  private _getHistory(): void {
    const getHistoryOf: any = this.invoice.id ? this.invoice.id : 'all';
    this.loadingHistory = true;
    this.sub.push(
      this.invoicesService.getInvoiceTrackingHistory(getHistoryOf).subscribe((response: any) => {
        this.history = response;
        this.loadingHistory = false;
      })
    );
  }

  getCreatedAtFormat(item: any): string {
    return (this.parent === 'invoice') ?
      this.helpersService.getCurrencyDetails(item.createdAt).format :
      this.helpersService.convertFirebaseTimestampToString(item.createdAt, 'MM/DD/YYYY HH:mm');
  }

}
