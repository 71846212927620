import { Component } from '@angular/core';

interface Feature {
  title: string
  description: string
  pro: boolean
  beta?: boolean
  new?: boolean
  imageUrl: string | null
}

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent {

  features: Feature[] = [
    {
      title: 'Unlimited Invoices and Customers',
      description: 'You can create and send as many invoices and customers as you need. No limits.',
      pro: false,
      new: false,
      imageUrl: null,
    },
    {
      title: 'Unlimited Attachments per Invoice',
      description: "Time tracking, receipts, contracts, etc. You're welcome to attach as many documents as you need to your invoices.",
      pro: true,
      new: false,
      beta: false,
      imageUrl: 'assets/images/features/multiple-attachments.png',
    },
    {
      title: 'Email Support',
      description: 'Need help? We are here to help you. Just send us an email and we will get back to you as soon as possible.',
      pro: true,
      new: false,
      imageUrl: null,
    },
    {
      title: 'Phone Support',
      description: 'Need a quick answer? We are here to help you. Just give us a call and we will get back to you as soon as possible.',
      pro: true,
      new: false,
      imageUrl: null,
    },
    {
      title: 'Simple Invoice Tracking',
      description: "Simple history tracking of changes on invoice's lifetime.",
      pro: false,
      new: false,
      beta: false,
      imageUrl: 'assets/images/features/invoice-history.png',
    },
    {
      title: 'USD and BRL Currencies',
      description: 'We support USD and BRL currencies. You can choose which one you want to use. Each invoice will be sent and charged (when using "Payment via Integration") in the currency you choose.',
      pro: false,
      new: false,
      beta: false,
      imageUrl: null,
    },
    {
      title: 'Advanced Invoice Tracking',
      description: "Go back in time to get a previous version of your invoice and see when your customer has received and opened your invoices.",
      pro: true,
      new: false,
      beta: false,
      imageUrl: 'assets/images/features/advanced-invoice-tracking.png',
    },
    {
      title: 'Get Paid via Link',
      description: "Send your invoice via email and get paid via link. It's that simple. We don't charge any transaction fee.",
      pro: false,
      new: false,
      beta: false,
      imageUrl: null,
    },
    {
      title: 'Get Paid via Integration',
      description: "Use our integration with Stripe to get paid via credit card directly into your bank account. On the free plan, we charge a 5% transaction fee.",
      pro: false,
      new: true,
      beta: true,
      imageUrl: 'assets/images/features/payment-integration.png',
    },
    {
      title: 'Get Paid via Integration - No Transaction fee',
      description: "The same integration as above, but we won't charge any transaction fee.",
      pro: true,
      new: true,
      beta: false,
      imageUrl: null,
    },
    {
      title: 'Invoice Reply Catcher',
      description: "We will catch your customer's reply and add it to your invoice history. No need to copy and paste. No need to forward the email. No more confusion.",
      pro: true,
      new: true,
      beta: true,
      imageUrl: null,
    },
    {
      title: 'Invoice Customization',
      description: "Customize your invoice with your company's logo, name, and address.",
      pro: false,
      new: false,
      beta: false,
      imageUrl: 'assets/images/features/custom-invoice.png',
    },
    {
      title: 'Scheduled and Recurrent Invoices',
      description: "Create a scheduled invoice and we will send it to your customer automatically. Just set the frequency and we will take care of the rest.",
      pro: true,
      new: false,
      beta: false,
      imageUrl: null,
    },
  ];

}
