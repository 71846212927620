<div class="container mb-4">

    <div class="row d-flex justify-content-center">
        <div class="col-12 col-md-4">

            <div class="badge primary mb-3">Acesso</div>
            <h3>Recuperar Senha</h3>
            <p>
                Informe o email cadastrado para recuperar a senha.
            </p>
            <p>
                <a [routerLink]="['/auth/login']" class="btn btn-sm btn-link p-0">&larr; voltar para o login</a>
            </p>
        </div>

    </div>
</div>

<section class="bg-super-light content-section">
    <div class="container">
        <div class="row d-flex justify-content-center">
            <div class="col-12 col-md-4">

                <div class="card border-0">
                    <div class="card-body">

                        <form #formResetPassword="ngForm" (submit)="sendPasswordResetEmail(formResetPassword.value)">
                            <div class="form-group mb-2">
                                <label>Email</label>
                                <input [disabled]="loading" ngModel name="email" type="email" email class="form-control disabled lowercase" autocomplete="off">
                            </div>

                            <div class="separator-10"></div>

                            <button [disabled]="!formResetPassword.valid || loading" type="submit" class="btn btn-primary">
                                <app-loading *ngIf="loading" parent="button"></app-loading>
                                <i *ngIf="!loading" class="fas fa-check-circle me-2"></i> Recuperar senha
                            </button>
                        </form>

                    </div>
                </div>

            </div>
        </div>
    </div>
</section>