import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { CustomersService } from 'src/app/services/customers.service';

@Component({
  selector: 'app-customers',
  template: '',
})
export class CustomersComponent implements OnInit, OnDestroy {

  @Output() onCustomers: any = new EventEmitter();
  @Output() onLoading: any = new EventEmitter();
  customers: any[] = [];
  sub: Subscription[] = [];

  constructor(
    private customersService: CustomersService,
  ) { }

  ngOnInit(): void {
    this._getCustomers();
  }

  ngOnDestroy(): void {
    this.sub.forEach((s: Subscription) => s.unsubscribe());
  }

  private _getCustomers(): void {
    this.onLoading.emit(true);
    this.sub.push(
      this.customersService.getCustomers().subscribe((response: any) => {
        this.customers = response;
        this.onCustomers.emit(this.customers);
        this.onLoading.emit(false);
      })
    );
  }

}
