import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { MessageService } from 'src/app/services/message.service';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
})
export class SettingsComponent implements OnInit, OnDestroy {

  sub: Subscription[] = [];
  user: any = {};
  loadingUpdateProfile: boolean = false;
  loadingUpdateInvoiceSettings: boolean = false;
  loadingUploadLogo: boolean = false;

  newPassword: string = '';
  newPasswordConfirm: string = '';
  newPasswordStrength: number = 0;

  invoiceSettings: any;

  logoPreview: any = "";
  uploadProgress: number = 0;

  emailFromDisplay: string = environment.emailFromDisplay;

  constructor(
    private authService: AuthService,
    private usersService: UsersService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this._getUser();

    this.messageService.get().subscribe((message: any) => {
      if (message.to == 'SettingsComponent') {
        this[message.action](message.payload);
      }
    });
  }

  ngOnDestroy(): void {
    this.sub.forEach(s => s.unsubscribe());
  }

  // Called from messageService
  private _updateUploadProgress(progress: number): void {
    this.uploadProgress = progress;
  }

  private _getUser(): void {
    this.user = this.authService.getUser();
    this.invoiceSettings = this.authService.getSettings();
  }

  onUploadFile($event: any): void {
    this.invoiceSettings.logo = $event;
  }

  updateProfile(formData: any): void {
    if (formData.password !== formData.passwordConfirm) {
      this.messageService.send("toast", "As senhas são diferentes");
      return;
    }

    const mailing: boolean = formData.mailing ? true : false;

    this.loadingUpdateProfile = true;
    this.sub.push(
      this.usersService.updateProfile(formData).subscribe(() => {
        this.authService.updateUser({ displayName: formData.displayName, mailing, phoneNumber: formData.phoneNumber });
        this.user = { ...this.user, displayName: formData.displayName, mailing, phoneNumber: formData.phoneNumber };
        this.messageService.send('toast', 'Perfil atualizado');
        this.newPassword = '';
        this.newPasswordConfirm = '';
        this.loadingUpdateProfile = false;
      })
    );
  }

  updateInvoiceSettings(formData: any): void {
    this.loadingUpdateInvoiceSettings = true;
    formData.logo = this.logoPreview ? this.logoPreview : this.invoiceSettings.logo;
    this.sub.push(
      this.usersService.updateSettings(formData).subscribe(() => {
        this.authService.updateSettings(formData);
        this.messageService.send('toast', 'Configurações atualizadas');
        this.loadingUpdateInvoiceSettings = false;
      }
    ));
  }

  removeLogo(): void {
    this.invoiceSettings.logo = {};
  }

}
