import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import Customer from '../models/customer.interface';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  constructor(
    private http: HttpClient,
  ) { }

  getCustomers(): any {
    return this.http.get<any>(`${environment.api}/customers`);
  }

  getCustomerHistory(customer: Customer): any {
    return this.http.get<any>(`${environment.api}/customers/${customer.id}/history`);
  }

  updateCustomer(customer: Customer): any {
    return this.http.post<any>(`${environment.api}/customers/${customer.id}`, customer);
  }

  createCustomer(customer: Customer): any {
    return this.http.post<any>(`${environment.api}/customers`, customer);
  }

  deleteCustomer(customer: Customer): any {
    return this.http.delete<any>(`${environment.api}/customers/${customer.id}`);
  }
}
