<app-header>
    <div class="badge primary mb-3">Configurações da sua conta</div>
    <span>Ajustes</span>
    <p>
        Configurações gerais da sua conta Invoicefy.
    </p>
</app-header>

<div class="container">
    <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav-tabs">
        <li ngbNavItem [ngbNavItem]="1">
            <a ngbNavLink><i class="fas fa-user me-2"></i> Perfil</a>
            <ng-template ngbNavContent>

                <div class="row">
                    <div class="col-12 col-md-4">

                        <div class="card border-0">
                            <div class="card-body">

                                <strong>Informações Pessoais</strong>

                                <div class="separator-10"></div>

                                <p class="text-muted">
                                    Configure seu nome e email.
                                </p>

                                <div class="separator-10"></div>

                                <form #formProfile="ngForm" (submit)="updateProfile(formProfile.value)">
                                    <div class="form-group mb-2">
                                        <label>Seu nome <span class="text-danger">*</span></label>
                                        <input [disabled]="loadingUpdateProfile" [(ngModel)]="user.displayName" name="displayName" type="text" class="form-control" required autocomplete="off" maxlength="70">
                                    </div>
                                    <div class="form-group mb-4">
                                        <label>Email <span class="text-danger">*</span></label>
                                        <input disabled [ngModel]="user.email" name="email" type="email" email class="form-control disabled" autocomplete="off">
                                        <div class="separator-10"></div>
                                        <small class="text-muted">
                                            Para alterar seu email, entre em contato conosco pelo chat ou pelo email <a href="mailto:{{ emailFromDisplay }}">{{ emailFromDisplay }}</a>.
                                        </small>
                                    </div>
                                    <div class="form-group mb-4">
                                        <label>Telefone <span class="text-danger">*</span></label>
                                        <input
                                            [(ngModel)]="user.phoneNumber"
                                            [disabled]="loadingUpdateProfile"
                                            name="phoneNumber"
                                            type="text"
                                            class="form-control"
                                            autocomplete="off"
                                            [specialCharacters]="['(',')','-']" mask="(00)00000-0000"
                                            placeholder="(00)00000-0000">
                                    </div>

                                    <div class="form-group mb-2">
                                        <label>
                                            <input type="checkbox" [ngModel]="user.mailing" name="mailing" class="me-2" value="1" /> Desejo receber novidades da Invoicefy.
                                        </label>
                                    </div>

                                    <div class="separator-10"></div>

                                    <hr class="text-muted">

                                    <div class="separator-10"></div>

                                    <small class="text-muted">
                                        Caso queira alterar sua senha, por favor digite uma nova abaixo.
                                    </small>

                                    <div class="separator-10"></div>
            
                                    <div class="form-group mb-2">
                                        <label>Nova senha</label>
                                        <input [disabled]="loadingUpdateProfile" [(ngModel)]="newPassword" name="password" type="password" class="form-control" autocomplete="off">
                                    </div>
                                    <div class="form-group mb-2">
                                        <label>Repita a nova senha</label>
                                        <input [disabled]="loadingUpdateProfile" [(ngModel)]="newPasswordConfirm" name="passwordConfirm" type="password" class="form-control" autocomplete="off">
                                    </div>

                                    <password-strength-meter (strengthChange)="newPasswordStrength = $event" [password]="newPasswordConfirm"></password-strength-meter>

                                    <div class="separator-5"></div>

                                    <small class="text-muted">
                                        Sua senha deve ter entre 8-20 caracteres, conter letras e números, e <strong>não deve</strong> conter espaços ou emojis.
                                    </small>

                                    <div class="separator-20"></div>

                                    <button [disabled]="!formProfile.valid || loadingUpdateProfile || (newPasswordConfirm && newPasswordStrength < 3)" type="submit" class="btn btn-primary mt-4">
                                        <app-loading *ngIf="loadingUpdateProfile" parent="button"></app-loading>
                                        <i *ngIf="!loadingUpdateProfile" class="fas fa-check-circle me-2"></i> Atualizar perfil
                                    </button>
                                </form>

                            </div>
                        </div>

                    </div><!-- /.col -->
                </div><!-- /.row -->

            </ng-template>
        </li>
        <li ngbNavItem [ngbNavItem]="2">
            <a ngbNavLink><i class="fas fa-wrench me-2"></i> Ajustes Gerais</a>
            <ng-template ngbNavContent>

                <div class="row">
                    <div class="col-12 col-md-4">

                        <div class="card border-0">
                            <div class="card-body">

                                <form #formInvoiceSettings="ngForm" (submit)="updateInvoiceSettings(formInvoiceSettings.value)">

                                    <strong>Ajustes da Fatura e Email</strong>

                                    <div class="separator-10"></div>

                                    <p class="text-muted">
                                        Estes dados vão aparecer nas faturas que você enviar.
                                    </p>

                                    <div class="separator-10"></div>

                                    <div class="form-group mb-2">
                                        <label>Nome da sua empresa</label>
                                        <input [disabled]="loadingUpdateInvoiceSettings" [(ngModel)]="invoiceSettings.name" name="name" type="text" class="form-control" autocomplete="off">
                                    </div>
                                    <div class="form-group mb-3">
                                        <label>Email</label>
                                        <input [disabled]="loadingUpdateInvoiceSettings" [ngModel]="invoiceSettings.email" name="email" type="email" email class="form-control" autocomplete="off">
                                        <div class="separator-5"></div>
                                        <small class="text-muted">
                                            <strong><span class="text-danger">*</span> Importante: </strong><br>
                                            O email acima será utilizado apenas para exibição no corpo do email e na fatura.<br>
                                            Por motivos de segurança e proteção anti-SPAM, o remetente real dos emails continua sendo "{{ emailFromDisplay }}".<br>
                                        </small>
                                    </div>
                                    <div class="form-group mb-3">
                                        <label>Endereço</label>
                                        <input [disabled]="loadingUpdateInvoiceSettings" [ngModel]="invoiceSettings.address" name="address" type="text" class="form-control" autocomplete="off">
                                    </div>
                                    <div class="form-group mb-2">
                                        <label>Sua Logo</label>
                                    </div>

                                    <div class="card card-body">
                                        <img [src]="logoPreview.downloadUrl || invoiceSettings.logo?.downloadUrl" alt="" class="img-fluid">
                                    </div>

                                    <div class="separator-10"></div>

                                    <div class="row">
                                        <div class="col">
                                            <app-uploader
                                                label="Select Logo"
                                                [loading]="loadingUpdateInvoiceSettings"
                                                accept="image/jpg,image/jpeg,image/png"
                                                [maxSize]="1000000"
                                                [initialPreview]="invoiceSettings.logoUrl"
                                                (onPreview)="logoPreview = $event"
                                                (onLoading)="loadingUploadLogo = $event"
                                                folder="logos"
                                                (onUpload)="onUploadFile($event)"
                                                (onPercentageChange)="uploadProgress = $event"></app-uploader>
                                            <small *ngIf="loadingUploadLogo" class="text-muted">{{uploadProgress}}%</small>
                                        </div>
                                        <div class="col text-end">
                                            <button [disabled]="!invoiceSettings.logo?.downloadUrl || loadingUpdateInvoiceSettings" (click)="removeLogo()" type="button" class="btn btn-link btn-sm btn-clean">
                                                <app-loading *ngIf="loadingUpdateInvoiceSettings" parent="button"></app-loading>
                                                <i *ngIf="!loadingUpdateInvoiceSettings" class="fas fa-times"></i> remover logo
                                            </button>
                                        </div>
                                    </div>



                                    <div class="separator-60"></div>


                                    <strong>Personalização do Email</strong>

                                    <div class="separator-10"></div>

                                    <div class="form-group mb-2">
                                        <label>Assunto <span class="text-danger">*</span></label>
                                        <input
                                            [disabled]="loadingUpdateInvoiceSettings"
                                            [ngModel]="invoiceSettings.emailSubject"
                                            name="emailSubject"
                                            type="text"
                                            class="form-control"
                                            autocomplete="off"
                                            required
                                            maxlength="70">
                                        <div class="separator-10"></div>
                                        <small class="text-muted">
                                            Você pode usar a variável <code>[invoice_ref]</code> para incluir o número da fatura no assunto.<br>
                                            Exemplo: "Sua Fatura [invoice_ref] chegou!" vai ficar "Sua Fatura #INV-0123 chegou!"
                                        </small>
                                    </div>

                                    <div class="separator-10"></div>

                                    <button [disabled]="!formInvoiceSettings.valid || loadingUpdateInvoiceSettings" type="submit" class="btn btn-primary mt-4">
                                        <app-loading *ngIf="loadingUpdateInvoiceSettings" parent="button"></app-loading>
                                        <i *ngIf="!loadingUpdateInvoiceSettings" class="fas fa-check-circle me-2"></i> Atualizar detalhes da fatura
                                    </button>

                                </form>

                            </div>
                        </div>

                    </div>
                </div>

            </ng-template>
        </li>
    </ul>

</div>

<section class="bg-super-light content-section">
    <div class="container">
        <div [ngbNavOutlet]="nav"></div>
    </div>
</section>