import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'src/app/services/message.service';

// Firebase
import 'firebase/auth'
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  loading: boolean = false;

  constructor(
    private auth: AngularFireAuth,
    private router: Router,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
  }

  sendPasswordResetEmail(formData: any): void {
    this.loading = true;
    this.auth.sendPasswordResetEmail(formData.email).then((response: any) => {
      this.messageService.send('toast', 'Recover password email sent. Please check your inbox.');
      this.loading = false;
      this.router.navigate(['/auth/login']);
    }, (err: any) => {
      this.messageService.send('toast', 'Email not found.');
      this.loading = false;
    });
  }

}
