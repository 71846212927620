import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import Invoice from '../models/invoice.interface';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(
    private http: HttpClient,
  ) { }

  /*
  trackPayment(payload: any): Promise<any> {
    return this.http.post<any>(`${environment.api}/payments/track`, payload).toPromise();
  }

  notifyUserAboutPayment(invoice: Invoice): Promise<any> {
    return this.http.post<any>(`${environment.api}/payments/notifyUserAboutPayment`, invoice).toPromise();
  }
  */
}
