<app-header>
    <div class="badge primary mb-3">Gestão de Clientes e Histórico</div>
    <span>Meus Clientes</span>
    <p>
        Gerencie os seus clientes e visualize rapidamente o histórico de cada um deles.
    </p>
</app-header>

<section class="bg-super-light content-section">
    <div class="container">
        <app-customers (onCustomers)="onCustomers($event)" (onLoading)="loadingCustomers = $event"></app-customers>

        <app-loading *ngIf="loadingCustomers"></app-loading>

        <div *ngIf="!loadingCustomers">

            <div class="row">
                <div class="col-12 col-md-4">

                    <div class="card border-0">
                        <div class="card-body">
                            <strong>Novo Cliente</strong>
                            <div class="separator-5"></div>
                            <small class="text-muted">
                                Adicione um novo cliente que irá receber suas faturas.
                            </small>
                            <div class="separator-20"></div>

                            <app-customers-form
                                (onSubmit)="onSubmitCustomer($event)"
                                (onLoading)="loadingUpdateCustomers = $event"
                                action="create"></app-customers-form>

                        </div><!-- /.card-body -->
                    </div><!-- /.card -->

                </div><!-- /.col -->
                <div class="col-12 col-md-8">

                    <div *ngIf="customers.length == 0">
                        <i class="fas fa-exclamation-triangle me-2"></i> Nenhum cliente encontrado
                    </div>

                    <div *ngIf="customers.length > 0">
                        <div *ngFor="let customer of customers" class="card border-0 mb-2">
                            <div class="card-body">

                                <div class="row">
                                    <div class="col-12 col-md-8">
                                        {{ customer.name }}
                                        <div class="separator-5"></div>
                                        <small class="text-muted">
                                            <div *ngIf="customer.email">
                                                <i style="width: 20px" class="text-center fas fa-envelope me-2"></i> {{ customer.email }}<br>
                                            </div>
                                            <div *ngIf="customer.phone">
                                                <i style="width: 20px" class="text-center fas fa-phone me-2"></i> {{ (customer.phone | mask: helpersService.getDynamicPhoneMask(customer.phone)) || '' }}
                                                <strong *ngIf="customer.isWhatsapp" class="text-success cursor-pointer" (click)="helpersService.openWhatsappChat(customer.phone)">
                                                    <i class="fab fa-whatsapp ms-2"></i>
                                                </strong> <br>
                                            </div>
                                            <div *ngIf="customer.address">
                                                <i style="width: 20px" class="text-center fas fa-link me-2"></i> {{ customer.address || '' }}<br>
                                            </div>
                                            <div *ngIf="customer.notes">
                                                <i style="width: 20px" class="text-center fas fa-sticky-note me-2"></i> <span (click)="customer.notesLess = this.helpersService.readMore(customer.notes)" [innerHTML]="customer.notesLess"></span><br>
                                            </div>
                                        </small>
                                    </div>
                                    <div class="col-12 col-md-4 text-end">
                                        <button
                                            (click)="openModalConfirmRemoveCustomer(modalConfirmRemoveCustomer, customer)"
                                            [disabled]="(loadingDeleteCustomers && loadingCustomerId === customer.id) || (loadingUpdateCustomers && loadingCustomerId === customer.id)"
                                            type="button"
                                            class="btn btn-link btn-sm text-danger">
                                                <app-loading *ngIf="(loadingDeleteCustomers && loadingCustomerId === customer.id)" parent="button" color="text-danger"></app-loading>
                                                <i *ngIf="(!loadingDeleteCustomers || loadingCustomerId !== customer.id)" class="fas fa-trash me-2"></i> remover
                                        </button>
                                        <button
                                            (click)="openModalEditCustomer(modalEditCustomer, customer)"
                                            [disabled]="(loadingUpdateCustomers && loadingCustomerId === customer.id) || (loadingDeleteCustomers && loadingCustomerId === customer.id)"
                                            type="button"
                                            class="btn btn-link btn-sm">
                                                <app-loading *ngIf="loadingUpdateCustomers && loadingCustomerId === customer.id" parent="button" color="text-primary"></app-loading>
                                                <i *ngIf="!loadingUpdateCustomers || loadingCustomerId !== customer.id" class="fas fa-edit me-2"></i> editar
                                        </button>
                                    </div>
                                </div>

                                <!--
                                Working but removed in the current version
                                <div *ngIf="!customer.history || customer.history.length == 0">
                                    <div class="separator-10"></div>

                                    <button (click)="getCustomerHistory(customer)" type="button" class="btn btn-link btn-sm">
                                        <i class="fas fa-history me-2"></i> Ver histórico do cliente
                                    </button>
                                </div>

                                <app-loading *ngIf="loadingHistoryId === customer.id"></app-loading>

                                <app-customers-history [customer]="customer"></app-customers-history>
                                -->

                            </div>
                        </div>

                        <div class="separator-10"></div>

                        <small class="text-muted">
                            {{ customers.length }} clientes encontrados.
                        </small>

                    </div>

                </div><!-- /.col -->
            </div>

        </div>
    </div>
</section>

<!-- Modal edit customer -->
<ng-template #modalEditCustomer let-modalEdit>
    <div class="modal-body">

        <strong>Editar {{ selectedCustomerToEdit.name || "" }}</strong>
        <div class="separator-20"></div>

        <app-customers-form
            (onSubmit)="onSubmitCustomer($event)"
            (onLoading)="loadingUpdateCustomers = $event"
            (onLoadingId)="loadingCustomerId = $event"
            [customer]="selectedCustomerToEdit"
            action="update"></app-customers-form>

        <div class="separator-10"></div>

        <div class="text-center">
            <button type="button" class="btn btn-link btn-sm" (click)="helpersService.closeModals()">Fechar</button>
        </div>

    </div>
</ng-template>


<!-- Modal confirm remove customer -->
<ng-template #modalConfirmRemoveCustomer let-modalPaid>
    <app-confirm-content [loading]="loadingDeleteCustomers || loadingUpdateCustomers">
        <span class="content">
            <p>
                Ao confirmar esta ação:
            </p>
            <ul class="list-group">
                <li class="list-group-item"><i class="fas fa-check-circle text-primary me-2"></i> O cliente <strong>"{{selectedCustomerToRemove.name || ""}}"</strong> não será notificado</li>
                <li class="list-group-item"><i class="fas fa-check-circle text-primary me-2"></i> O histórico deste cliente será perdido</li>
                <li class="list-group-item">
                    <i class="fas fa-check-circle text-primary me-2"></i> Este cliente será removido de todas as faturas em <strong>rascunho</strong>
                    <div class="ms-4 ps-1 text-muted">
                        <small>
                            As faturas com outros status (<strong>enviadas</strong>, <strong>pagas</strong>, <strong>canceladas</strong>) não serão afetadas
                        </small>
                    </div>
                </li>
            </ul>
        </span>

        <button
            (click)="deleteCustomer()"
            [disabled]="loadingDeleteCustomers || loadingUpdateCustomers"
            type="button"
            class="btn btn-danger">
                <app-loading *ngIf="loadingDeleteCustomers || loadingUpdateCustomers" parent="button"></app-loading> 
                <i *ngIf="!loadingDeleteCustomers || loadingUpdateCustomers" class="fas fa-trash me-2"></i> remover
        </button>
    </app-confirm-content>
</ng-template>