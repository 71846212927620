import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'src/app/services/message.service';
import { UsersService } from 'src/app/services/users.service';
import { AuthService } from 'src/app/services/auth.service';

// Firebase
import 'firebase/auth'
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Component({
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.scss']
})
export class AuthLoginComponent implements OnInit {

  user: any = {};
  loading: boolean = false;
  redirectUrl: string = '';

  constructor(
    private usersService: UsersService,
    private router: Router,
    private authService: AuthService,
    private messageService: MessageService,
    private auth: AngularFireAuth,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.authService.logout();
    this.messageService.send('NavbarComponent', 'setUser', null);

    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params.next && params.next !== '' && params.next !== '/' && (params.next.startsWith('/panel') || params.next.startsWith('panel'))) {
        this.redirectUrl = params.next;
      }
    });
  }

  private _getUserDataFromFirebaseResult(result: any): any {
    return {
      uid: result.user.uid,
      name: result.user.displayName,
      email: result.user.email,
      emailVerified: result.user.emailVerified,
      photoURL: result.user.photoURL,
      phoneNumber: result.user.phoneNumber,
      refreshToken: result.user.refreshToken,
      provider: result.additionalUserInfo.providerId,
    }
  };

  login(formLogin: any): any {
    this.loading = true;
    this.auth.signInWithEmailAndPassword(formLogin.email, formLogin.password).then((result: any) => {
      this.user = this._getUserDataFromFirebaseResult(result);

      this.usersService.getProfile(this.user.uid).subscribe((response: any) => {
        if (!response.user.isAccountActive) {
          this.messageService.send('toast', 'Sua conta não está ativa. Verifique seu email para ativar sua conta.');
          this.loading = false;
          return;
        }

        if (!response.user.emailVerified) {
          this.messageService.send('toast', 'Você precisa verificar seu e-mail antes de continuar');
          this.loading = false;
          return;
        }

        this.authService.setSettings({ ...response.settings });
        this.authService.setLogin({ ...response.user });
        this.messageService.send('NavbarComponent', 'setUser', { ...response.user })
        this.loading = false;
        if (this.redirectUrl === '') {
          this.router.navigate(['/panel']);
          return;
        }

        this.router.navigate([this.redirectUrl]);
      }, (error: any) => {
        this.messageService.send('toast', 'Erro ao fazer login. Tente novamente mais tarde');
        this.loading = false;
        console.log('Error', error);
      });

    }).catch((error: any) => {
      if (['auth/user-not-found', 'auth/wrong-password'].includes(error.code)) {
        this.messageService.send('toast', 'Dados incorretos');
      }
      this.loading = false;
    });
  }

  logout() {
    this.authService.logout();
  }

}
