import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-content',
  templateUrl: './confirm-content.component.html',
  styleUrls: ['./confirm-content.component.scss']
})
export class ConfirmContentComponent implements OnInit {

  @Input() loading: boolean = false;

  constructor(
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
  }

  close(): void {
    this.modalService.dismissAll();
  }

}
