<app-header>
    <div class="badge primary mb-3">Visualizando a fatura {{ invoice.ref || "" }}</div>
    <span>Fatura <span *ngIf="!loadingInvoice"> <span class="text-muted">&raquo; </span><strong>{{ invoice.ref }}</strong></span></span>
    <p>
        <a [routerLink]="['/panel/invoices']" class="btn btn-sm btn-link p-0">&larr; todas as faturas</a>
    </p>
</app-header>

<app-loading *ngIf="loadingInvoice"></app-loading>

<div *ngIf="!loadingInvoice && invoice">
    <div class="container">

        <!--
            --
            --
            --
            --
            -- STATUS WARNINGS
            --
            --
            --
            --
        -->

        <!-- PAYMENTPROOF -->
        <div *ngIf="invoice.status == 'paymentProof' && invoice.paymentProof" class="alert alert-warning mb-4">
            <strong><i class="fas fa-paperclip me-2"></i> Comprovante de Pagamento Adicionado</strong>
            <div class="separator-5"></div>
            É necessário que você confirme o pagamento e marque a fatura como paga.
        </div>

        <!-- PAID -->
        <div *ngIf="invoice.status == 'paid'" class="alert alert-primary mb-4">
            <strong><i class="fas fa-check me-2"></i> Fatura Paga</strong> &mdash; <small>Última atualização em {{ helpersService.convertFirebaseTimestampToString(invoice.updatedAt, 'DD/MM/YYYY HH:mm') }}</small>
            <div *ngIf="(invoice.paymentPrivateNote && invoice.paymentPrivateNote !== '') || (invoice.paymentPublicNote && invoice.paymentPublicNote !== '')">
                <div class="separator-5"></div>
                <small>Esta fatura contém anotações sobre o pagamento. Confira as seções de "Anotações" nos Detalhes da Fatura.</small>
            </div>
            <div *ngIf="getPaymentDateDifference() !== ''">
                <hr />
                <small>
                    <i class="fas fa-hand-holding-usd me-2"></i> Pagamento recebido <strong>{{ getPaymentDateDifference() }}</strong>.
                </small>
            </div>
        </div>

        <!-- SENT -->
        <div *ngIf="invoice.status == 'sent'" class="alert alert-primary mb-4">
            <strong>Fatura Enviada{{invoice.scheduledTo !== '' ? ' (via agendamento)' : ''}}</strong>
        </div>

        <!-- SCHEDULED -->
        <div *ngIf="invoice.status == 'scheduled'" class="alert alert-warning mb-4">
            <div class="row align-items-center">
                <div class="col-12 col-md-6">
                    <!-- <strong><i class="fas fa-clock me-2"></i> Envio agendado para {{invoice.scheduledTo | date: "dd/MM/yyyy"}}</strong> -->
                </div>
                <div class="col-12 col-md-6 text-end">
                    <button
                        (click)="openModalConfirmCancelInvoice(modalCancelInvoice)"
                        [disabled]="loadingCancelInvoice || loadingReSendInvoice"
                        class="btn btn-warning"
                        type="button">Cancelar agendamento</button>
                </div>
            </div>
        </div>

        <!-- CANCELLED -->
        <div *ngIf="invoice.status == 'cancelled'" class="alert alert-danger mb-4">
            <strong>Fatura Cancelada</strong>
            <div *ngIf="invoice.cancelNote || cancelNote">
                <div class="separator-5"></div>
                <strong><i class="fas fa-exclamation-triangle me-2"></i> </strong> {{ cancelNote || invoice.cancelNote }}
            </div>
        </div>

        <!--
            --
            --
            --
            --
            -- PAGE BODY
            --
            --
            --
            --
        -->

        <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav-tabs">
            <li ngbNavItem [ngbNavItem]="1">
                <a ngbNavLink><i class="fas fa-eye me-2"></i> Visualizar Fatura</a>
                <ng-template ngbNavContent>

                    <div *ngIf="invoiceSafeUrl || (invoice.invoiceFile && invoice.invoiceFile.fileUrl !== '' && invoice.invoiceFile.fileUrl !== 'error')" class="mb-3">
                        <small class="text-muted">
                            Não consegue visualizar a fatura? <a [href]="invoice.invoiceFile.fileUrl" target="_blank" rel="noopener noreferrer">Abrir em outra aba <i class="fas fa-external-link-alt ms-2"></i></a>
                        </small>
                    </div>

                    <div *ngIf="!invoiceSafeUrl && invoice.invoiceFile && invoice.invoiceFile.fileUrl === ''" class="text-center text-muted mb-4">
                        <small>
                            <app-loading></app-loading>
                            <div class="separator-10"></div>
                            Carregando a pré-visualização da fatura.
                        </small>
                    </div>

                    <div class="iframe-placeholder">
                        <iframe
                            *ngIf="invoiceSafeUrl"
                            width="100%"
                            height="1250px"
                            frameborder="0"
                            [src]="invoiceSafeUrl">
                        </iframe>
                    </div>

                </ng-template>
            </li>
            <li ngbNavItem [ngbNavItem]="2">
                <a ngbNavLink><i class="fas fa-list me-2"></i> Detalhes da Fatura</a>
                <ng-template ngbNavContent>

                    <app-invoice-details-readonly [invoice]="invoice" role="owner"></app-invoice-details-readonly>

                </ng-template>
            </li>
        </ul>
    </div>

    <section class="bg-super-light content-section">
        <div class="container">

            <div class="row">
                <div class="col-12 col-md-8">

                    <div [ngbNavOutlet]="nav"></div>

                </div>
                <div class="col-12 col-md-4">

                    <!-- ACTIONS WHEN SENT -->
                    <div *ngIf="invoice.status == 'sent'" class="d-grid">
                        <button
                            (click)="openModalConfirmCancelInvoice(modalCancelInvoice)"
                            [disabled]="loadingCancelInvoice || loadingReSendInvoice"
                            type="button"
                            class="btn btn-outline-primary">
                                <i class="fas fa-times me-2"></i> Cancelar Fatura
                        </button>

                        <div class="separator-5"></div>

                        <button
                            (click)="openModalConfirmReSend(modalConfirmReSend)"
                            [disabled]="loadingCancelInvoice"
                            type="submit"
                            class="btn btn-outline-primary">
                                <app-loading *ngIf="loadingReSendInvoice" parent="button"></app-loading> 
                                <i *ngIf="!loadingReSendInvoice" class="fas fa-paper-plane me-2"></i> 
                                Re-enviar
                        </button>

                        <div class="separator-5"></div>

                        <button
                            (click)="openModalConfirmMarkAsPaid(modalMarkAsPaid)"
                            [disabled]="loadingMarkAsPaid"
                            type="button"
                            class="btn btn-primary">
                                <i class="fas fa-check-circle me-2"></i> Marcar como Paga
                        </button>

                        <div class="separator-40"></div>
                    </div>

                    <!-- ACTIONS WHEN PAYMENTPROOF -->
                    <div *ngIf="invoice.status == 'paymentProof' && invoice.paymentProof" class="d-grid">
                        <button
                            (click)="openModalConfirmMarkAsPaid(modalMarkAsPaid)"
                            [disabled]="loadingMarkAsPaid"
                            type="button"
                            class="btn btn-warning">
                                <i class="fas fa-check-circle me-2"></i> Marcar como Paga
                        </button>

                        <div class="separator-40"></div>
                    </div>

                    <!-- PUBLIC SHAREABLE LINK -->

                    <app-invoice-public-link [invoice]="invoice"></app-invoice-public-link>

                    <!-- TRACKING HISTORY -->

                    <app-invoice-tracking-history [invoice]="invoice"></app-invoice-tracking-history>

                </div>
            </div>


        </div>
    </section>

    <!--
        --
        --
        --
        --
        -- CONFIRMATION MODALS
        --
        --
        --
        --
    -->

    <!-- Modal confirm Cancel Invoice -->
    <ng-template #modalCancelInvoice let-modalCancel>
        <app-confirm-content [loading]="loadingCancelInvoice">
            <span class="content">
                <p>
                    Ao confirmar esta ação:
                </p>

                <ul class="list-group">
                    <li class="list-group-item">
                        <i class="fas fa-check-circle text-primary me-2"></i> Esta fatura será marcada como 
                            <div class="badge uppercase bg-danger ms-1">
                                {{ helpersService.translateStatus("cancelled") }}
                            </div>
                    </li>
                    <li class="list-group-item">
                        <i class="fas fa-check-circle text-primary me-2"></i> Esta fatura não poderá ser modificada
                    </li>
                    <li class="list-group-item" *ngIf="invoice.sendReminder">
                        <i class="fas fa-check-circle text-primary me-2"></i> O envio de lembretes desta fatura está <strong>ativo</strong> e os lembretes que ainda não foram enviados serão cancelados
                    </li>
                    <li class="list-group-item" *ngIf="invoice.status === 'scheduled'">
                        <i class="fas fa-check-circle text-primary me-2"></i> Esta fatura foi agendada para {{ invoice.scheduledTo | date: "dd/MM/YYYY" }} e <strong>ainda não foi enviada</strong> para o cliente
                    </li>
                    <li class="list-group-item" *ngIf="invoice.status === 'scheduled'">
                        <i class="fas fa-check-circle text-primary me-2"></i> O agendamento do envio será cancelado e nenhuma notificação será enviada ao cliente
                    </li>
                    <li class="list-group-item" *ngIf="invoice.customer.email">
                        <span *ngIf="invoice.status === 'scheduled'"><i class="fas fa-check-circle text-primary me-2"></i> O cliente <strong>não será notificado</strong> deste cancelamento</span>
                        <span *ngIf="invoice.status === 'sent'"><i class="fas fa-check-circle text-primary me-2"></i> O cliente será notificado pelo email: <strong>"{{ invoice.customer.email }}"</strong></span>
                    </li>
                    <li class="list-group-item"><i class="fas fa-check-circle text-primary me-2"></i> O cliente não vê a nota de cancelamento</li>
                </ul>

                <div class="separator-10"></div>

                <div class="form-group">
                    <label class="form-label">Escreva um motivo para o cancelamento (opcional)</label>
                    <textarea
                        [(ngModel)]="cancelNote"
                        [disabled]="loadingCancelInvoice"
                        autosize
                        cols=""
                        rows="3"
                        class="form-control"
                        placeholder="ex: Descrição incorreta ou faltou a data de vencimento"></textarea>
                </div>
            </span>

            <button
                (click)="cancelInvoice()"
                [disabled]="loadingCancelInvoice || loadingReSendInvoice"
                type="button"
                class="btn btn-danger">
                    <app-loading *ngIf="loadingCancelInvoice || loadingReSendInvoice" parent="button"></app-loading> 
                    <i *ngIf="!loadingCancelInvoice || loadingReSendInvoice" class="fas fa-check-circle me-2"></i> Cancelar Fatura
            </button>
        </app-confirm-content>
    </ng-template>



    <!-- Modal confirm ReSend -->
    <ng-template #modalConfirmReSend let-modalReSend>
        <app-confirm-content [loading]="loadingCancelInvoice || loadingReSendInvoice">
            <span class="content">
                <p>
                    Ao confirmar esta ação:
                </p>
                <ul class="list-group">
                    <li class="list-group-item" *ngIf="invoice.customer.email">
                        <i class="fas fa-check-circle text-primary me-2"></i> 
                        O cliente vai receber a fatura pelo email <strong>{{ invoice.customer.email }}</strong>
                    </li>
                    <li class="list-group-item" *ngIf="!invoice.customer.email">
                        <i class="fas fa-check-circle text-primary me-2"></i> 
                        O cliente não possui um email cadastrado. Entregue a fatura manualmente
                    </li>
                    <li class="list-group-item" *ngIf="invoice.cc">
                        <i class="fas fa-check-circle text-primary me-2"></i> 
                        Uma cópia desta fatura será enviada para <strong>{{ invoice.cc }}</strong>
                    </li>
                    <li class="list-group-item" *ngIf="invoice.bcc">
                        <i class="fas fa-check-circle text-primary me-2"></i> 
                        Uma cópia oculta desta fatura será enviada para <strong>{{ invoice.bcc }}</strong>
                    </li>
                    <li class="list-group-item" *ngIf="invoice.paymentMethod === 'link'">
                        <i class="fas fa-check-circle text-primary me-2"></i> 
                        O cliente fará o pagamento desta fatura via link
                    </li>
                </ul>

                <div *ngIf="invoice.sendCopyToMe" class="text-muted">
                    <div class="separator-10"></div>

                    <small>
                        <i class="fas fa-check-circle text-primary me-2"></i> Você vai receber uma cópia desta fatura.
                    </small>

                    <div class="separator-10"></div>
                </div>
            </span>

            <button
                (click)="reSendInvoice()"
                [disabled]="loadingCancelInvoice || loadingReSendInvoice"
                type="button"
                class="btn btn-outline-primary">
                    <app-loading *ngIf="loadingCancelInvoice || loadingReSendInvoice" parent="button" color="text-primary"></app-loading> 
                    <i *ngIf="!loadingCancelInvoice || loadingReSendInvoice" class="fas fa-paper-plane me-2"></i> Re-enviar
            </button>
        </app-confirm-content>
    </ng-template>



    <!-- Modal confirm mark as paid -->
    <ng-template #modalMarkAsPaid let-modalPaid>
        <app-confirm-content [loading]="loadingMarkAsPaid">
            <span class="content">
                <p>
                    Ao confirmar esta ação:
                </p>

                <ul class="list-group">
                    <li class="list-group-item">
                        <i class="fas fa-check-circle text-primary me-2"></i> Esta fatura será marcada como 
                            <div class="badge uppercase bg-success ms-1">
                                {{ helpersService.translateStatus("paid") }}
                            </div>
                    </li>
                    <li class="list-group-item" *ngIf="invoice.customer.email">
                        <i class="fas fa-check-circle text-primary me-2"></i> O cliente será notificado pelo <strong>"{{ invoice.customer.email }}"</strong> email
                    </li>
                    <li class="list-group-item" *ngIf="!invoice.paymentProof">
                        <i class="fas fa-check-circle text-primary me-2"></i> Comprovante de pagamento <strong>não foi adicionado</strong>. Não será possível adicionar um comprovante de pagamento ou modificar a fatura após marcar como paga.
                    </li>
                </ul>

                <div class="separator-20"></div>

                <div class="card bg-light border-0 shadow-0">
                    <div class="card-body">

                        <strong><i class="fas fa-paperclip me-2 text-muted"></i> Comprovante de Pagamento</strong>
                        <div class="separator-10"></div>

                        <div *ngIf="invoice.paymentProof && invoice.paymentProof.fileUrl && invoice.paymentProof.fileUrl !== ''; else noPaymentProof">
                            <small class="text-muted">
                                <a
                                    [href]="invoice.paymentProof.fileUrl"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    class="btn btn-sm btn-link btn-clean">
                                        <i class="fas fa-file-pdf me-2"></i> {{ invoice.paymentProof.fileName || '' }} <i class="fas fa-external-link-alt ms-2"></i>
                                </a>
                                <div class="separator-10"></div>
                                <i class="fas fa-check-circle text-success me-2"></i> Recebido em {{ helpersService.convertFirebaseTimestampToString(invoice.paymentProof.updatedAt, "DD/MM/YYYY") }}
                            </small>
                        </div>

                        <ng-template #noPaymentProof>
                            <small class="text-muted">
                                <i class="fas fa-exclamation-triangle me-2"></i> Nenhum comprovante foi enviado para esta fatura.
                            </small>

                            <div class="separator-10"></div>

                            <div *ngIf="loadingUploadPaymentProof">
                                <app-loading parent="button" color="text-primary"></app-loading>
                                <small class="text-muted">{{uploadProgress}}%</small>
                            </div>
                            <app-uploader
                                label="Selecionar Comprovante"
                                folder="receipts"
                                accept="application/pdf,image/jpg,image/jpeg,image/png"
                                (onLoading)="onLoadingUploader($event)"
                                (onUpload)="onUploadFile($event)"
                                (onPercentageChange)="onUpdateUploadProgress($event)"
                                [loading]="loadingUploadPaymentProof">
                            </app-uploader>
                        </ng-template>
                    </div>
                </div>

                <div class="separator-10"></div>

                <div class="card bg-light border-0 shadow-0">
                    <div class="card-body">

                        <strong><i class="fas fa-sticky-note me-2 text-muted"></i> Observações (opcional)</strong>
                        <div class="separator-10"></div>

                        <div class="form-group mb-2">
                            <label class="form-label">Anotações para seu cliente</label>
                            <textarea
                                [(ngModel)]="invoice.paymentPublicNote"
                                [disabled]="loadingInvoice || loadingMarkAsPaid"
                                autosize
                                name="paymentPublicNote"
                                class="form-control"
                                autocomplete="off">
                            </textarea>
                        </div>

                        <div class="separator-10"></div>

                        <div class="form-group mb-2">
                            <label class="form-label">Anotações particulares</label>
                            <p>
                                <small class="text-muted">
                                    Seu cliente não verá esta anotação.
                                </small>
                            </p>
                            <textarea
                                [(ngModel)]="invoice.paymentPrivateNote"
                                [disabled]="loadingInvoice || loadingMarkAsPaid"
                                autosize
                                name="paymentPrivateNote"
                                class="form-control"
                                autocomplete="off">
                            </textarea>
                        </div>

                    </div>
                </div>

                <div class="separator-10"></div>
            </span>

            <button
                (click)="markAsPaid()"
                [disabled]="loadingMarkAsPaid || loadingUploadPaymentProof"
                type="button"
                class="btn btn-primary">
                    <app-loading *ngIf="loadingMarkAsPaid" parent="button"></app-loading> 
                    <i *ngIf="!loadingMarkAsPaid" class="fas fa-check-circle me-2"></i> Marcar como Paga
            </button>
        </app-confirm-content>
    </ng-template>

</div><!-- /!loadingInvoice && invoice -->