import { Component, Input } from '@angular/core';
import Customer from 'src/app/models/customer.interface';
import { HelpersService } from 'src/app/services/helpers.service';

@Component({
  selector: 'app-customers-history',
  templateUrl: './customers-history.component.html',
  styleUrls: ['./customers-history.component.scss']
})
export class CustomersHistoryComponent {

  @Input() customer: Customer = {} as Customer;

  constructor(
    public helpersService: HelpersService,
  ) { }

}
