<div class="bg-transparent pt-4">
    <div class="container">

        <ng-content select="div.badge"></ng-content>
        <h3>
            <ng-content select="span"></ng-content>
        </h3>
        <ng-content select="p"></ng-content>

    </div>
</div>

<div class="separator-20"></div>
