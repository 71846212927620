<app-header>
    <div class="badge primary mb-3">Visualizando a fatura {{ invoice.ref || "" }}</div>
    <span>Fatura <span *ngIf="!loadingInvoice"> <span class="text-muted">&raquo; </span><strong>{{ invoice.ref }}</strong></span></span>
    <p>
        <a [routerLink]="['/panel/invoices']" class="btn btn-sm btn-link p-0">&larr; todas as faturas</a>
    </p>
</app-header>

<section class="bg-super-light content-section">
    <div class="container">

        <app-loading *ngIf="loadingInvoice"></app-loading>


        <form #formInvoice="ngForm" *ngIf="!loadingInvoice && invoice">

            <!-- Actions bar for DRAFT -->
            <div class="alert alert-primary">
                <div class="row align-items-center">
                    <div class="col-12 col-md-8">
                        <strong>Rascunho</strong>
                    </div>
                    <div class="col-12 col-md-2 d-grid">
                        <button
                            (click)="submitInvoice('draft')"
                            [disabled]="
                                loadingSaveInvoice || 
                                loadingSendInvoice || 
                                !hasAtLeastOneItem() || 
                                invoice.grandTotal < 0"
                            type="button" class="btn btn-primary">
                            <app-loading *ngIf="loadingSaveInvoice" parent="button"></app-loading> 
                            <i *ngIf="!loadingSaveInvoice" class="fas fa-check-circle me-2"></i> Salvar
                        </button>
                    </div>
                    <div class="col-12 col-md-2 d-grid">
                        <button
                            (click)="openModalConfirmSendInvoice(content)"
                            [disabled]="
                                loadingSaveInvoice || 
                                loadingSendInvoice || 
                                !hasAtLeastOneItem() || 
                                invoice.grandTotal < 0 ||
                                !invoice.customer || (invoice.customer && !invoice.customer.id) ||
                                (invoice.paymentMethod === 'link' && (invoice.paymentLink === '' || !helpersService.isValidUrl(invoice.paymentLink)))"
                            type="button"
                            class="btn btn-success">
                                <app-loading *ngIf="loadingSendInvoice" parent="button" color="text-dark"></app-loading> 
                                <strong> 
                                    <i *ngIf="!loadingSendInvoice" class="fas fa-envelope me-2"></i> Enviar Fatura
                                </strong>
                        </button>
                    </div>
                </div>
            </div>

            <div class="separator-5"></div>

            <div class="row">
                <div class="col-12 col-md-8">
    
                    <div class="card border-0">
                        <div class="card-body">

                            <app-customers (onCustomers)="customers = $event"></app-customers>


                            <!--
                                --
                                --
                                --
                                --
                                Currency
                                --
                                --
                                --
                                --
                            -->
                            <div class="row">
                                <div class="col-6">
                                    <strong>Cliente</strong>
                                </div>
                                <div class="col-6 text-end">
                                    <small>
                                        <span class="text-muted">
                                            Moeda: 
                                            <strong>
                                                <span
                                                    *ngFor="let curr of helpersService.currencies"
                                                    (click)="setCurrency(curr.currency)"
                                                    class="ms-2 cursor-pointer"
                                                    [class.text-primary]="invoice.currency.toLowerCase() === curr.currency.toLowerCase()">{{ curr.currency | uppercase }}</span>
                                            </strong>
                                        </span>
                                    </small>
                                </div>
                            </div>

                            <div class="separator-10"></div>


                            <!--
                                --
                                --
                                --
                                --
                                Customer
                                --
                                --
                                --
                                --
                            -->

                            <ng-template #hasNoCustomerSelected>
                                <div class="row">
                                    <div class="col-12 col-md-6 mb-3">
                                        <div class="form-group">
                                            <select
                                                [disabled]="loadingSaveInvoice"
                                                (change)="setCustomer($event, createNewCustomerModal)"
                                                name="customer"
                                                required
                                                class="form-control">
                                                    <option value=""></option>
                                                    <option *ngFor="let customer of customers" [value]="customer.id">{{ customer.name }} <span *ngIf="customer.email">({{ customer.email }})</span></option>
                                                    <option value="" disabled>-</option>
                                                    <option value="create-new-customer">Criar novo Cliente</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>

                            <div *ngIf="invoice.customer && invoice.customer.id; else hasNoCustomerSelected">
                                <div class="card bg-light border-0 shadow-0">
                                    <div class="card-body">

                                        <button (click)="unselectCustomer()" type="button" [disabled]="loadingSaveInvoice" class="btn btn-clean float-end">
                                            <i class="fas fa-times-circle text-muted-40"></i>
                                        </button>

                                        <strong>{{ invoice.customer.name }}</strong>
                                        <div class="separator-5"></div>
                                        <small class="text-muted">
                                            <i style="width: 20px" class="text-center fas fa-envelope me-2"></i> {{ invoice.customer.email || '-' }}<br>
                                            <i style="width: 20px" class="text-center fas fa-phone me-2"></i> {{ (invoice.customer.phone | mask: helpersService.getDynamicPhoneMask(invoice.customer.phone)) || '-' }}<br>
                                            <i style="width: 20px" class="text-center fas fa-map-pin me-2"></i> {{ invoice.customer.address || '-' }}<br>
                                        </small>

                                        <div *ngIf="invoice.customer.notes">
                                            <div class="separator-20"></div>
                                            <small>
                                                <span class="text-muted">
                                                    <i class="fas fa-sticky-note me-2"></i> <span [innerHTML]="helpersService.detectUrlInString(invoice.customer.notes || '')"></span>
                                                </span>
                                            </small>
                                        </div>
                                    </div>
                                </div>

                                <div class="separator-10"></div>
                            </div>


                            <!--
                                --
                                --
                                --
                                --
                                Additional Recipients
                                --
                                --
                                --
                                --
                            -->
                            <div *ngIf="invoice.customer && (invoice.customer.id && invoice.customer.email)">
                                <div class="row">
                                    <div class="col-12 text-end">
                                        <button [disabled]="loadingSaveInvoice" *ngIf="!toggleAdditionalRecipients" (click)="toggleAdditionalRecipients = true" type="button" class="btn btn-link btn-sm"><i class="fas fa-plus me-2"></i> Adicionar destinatários adicionais</button>
                                        <button [disabled]="loadingSaveInvoice" *ngIf="toggleAdditionalRecipients" (click)="toggleAdditionalRecipients = false" type="button" class="btn btn-link btn-sm"><i class="fas fa-minus me-2"></i> Remover destinatários adicionais</button>
                                    </div>
                                </div>

                                <div *ngIf="toggleAdditionalRecipients">
                                    <div class="separator-5"></div>

                                    <div class="row">
                                        <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <input
                                                    [disabled]="loadingSaveInvoice"
                                                    [(ngModel)]="invoice.cc"
                                                    placeholder="Cópia (cc)"
                                                    name="cc" type="email" email class="form-control" autocomplete="off">

                                                <div *ngIf="invoice.cc && !helpersService.isValidEmail(invoice.cc)" class="mt-1 text-danger">
                                                    <small>
                                                        <i>{{ invoice.cc }}</i> não é um email válido
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6">
                                            <div class="form-group">
                                                <input
                                                    [disabled]="loadingSaveInvoice"
                                                    [(ngModel)]="invoice.bcc"
                                                    placeholder="Cópia Oculta (bcc)"
                                                    name="bcc" type="email" email class="form-control" autocomplete="off">

                                                <div *ngIf="invoice.bcc && !helpersService.isValidEmail(invoice.bcc)" class="mt-1 text-danger">
                                                    <small>
                                                        <i>{{ invoice.bcc }}</i> não é um email válido
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="separator-30"></div>


                            <!--
                                --
                                --
                                --
                                --
                                Items
                                --
                                --
                                --
                                --
                            -->
                            <strong>Itens da fatura <span *ngIf="invoice.items && invoice.items.length > 0" class="text-muted">({{ invoice.items.length }})</span></strong>

                            <div class="separator-5"></div>

                            <small class="text-muted">
                                Descreva cada item da fatura. Pelo menos um item é obrigatório.
                            </small>

                            <div class="separator-10"></div>

                            <ul *ngIf="invoice.items && invoice.items.length > 0" class="list-group">
                                <li *ngFor="let item of invoice.items; let i = index" class="list-group-item">

                                    <div class="row">
                                        <div class="col-1 text-muted">
                                            <small>#{{ i+1 }}</small>
                                        </div>
                                        <div class="col-6">{{ item.name }}</div>
                                        <div class="col-4 text-end">{{ item.price | currency : invoice.currency.toUpperCase() }}</div>
                                        <div class="col-1 text-end">
                                            <button (click)="removeInvoiceItem(item)" [disabled]="loadingSaveInvoice" class="btn btn-sm btn-clean text-danger">
                                                <i class="fas fa-minus-circle"></i>
                                            </button>
                                        </div>
                                    </div>

                                </li>
                            </ul>

                            <div class="separator-10"></div>

                            <div *ngIf="toggleAddItem" class="row">
                                <div class="col-12 col-md-8">
                                    <div class="form-group">
                                        <input
                                            [disabled]="loadingSaveInvoice"
                                            [(ngModel)]="newItem.name"
                                            name="name"
                                            type="text"
                                            class="form-control form-control-sm"
                                            placeholder="Descreva o serviço ou produto que você está cobrando"
                                            autocomplete="off">
                                    </div>
                                </div>
                                <div class="col-12 col-md-2">
                                    <div class="form-group">
                                        <input
                                            [(ngModel)]="newItem.price"
                                            [disabled]="loadingSaveInvoice"
                                            currencyMask
                                            [options]="getCurrencyFormatOptions()"
                                            name="price"
                                            type="tel"
                                            class="form-control form-control-sm"
                                            placeholder="Preço do Item"
                                            autocomplete="off">
                                    </div>
                                </div>
                                <div class="col-12 col-md-2 d-grid">
                                    <button [disabled]="loadingSaveInvoice" (click)="addItemToInvoice()" type="button" class="btn btn-outline-primary btn-sm">Salvar</button>
                                </div>

                                <div class="separator-10"></div>
                            </div>

                            <button [disabled]="loadingSaveInvoice" (click)="toggleAddItem = !toggleAddItem" type="button" class="btn btn-link btn-sm p-0">
                                <i class="fas fa-plus me-2"></i> Adicionar Item
                            </button>


                            <div class="separator-40"></div>


                            <!--
                                --
                                --
                                --
                                --
                                Payment method
                                --
                                --
                                --
                                --
                            -->
                            <strong>Como você quer receber por esta fatura?</strong>
                            <div class="separator-5"></div>

                            <small class="text-muted">
                                Clique nas opções abaixo para selecionar e saber mais sobre cada uma.
                            </small>

                            <div class="separator-10"></div>

                            <div class="row">
                                <div class="col-12 col-md-4">
                                    <div class="card bg-light payment-option shadow-0" [class.selected]="invoice.paymentMethod === 'none'">
                                        <div class="card-body">
                                            <div class="form-group">
                                                <label class="cursor-pointer">
                                                    <input type="radio" [(ngModel)]="invoice.paymentMethod" name="paymentMethod" value="none" class="d-none"><strong>Manualmente</strong>
                                                    <div class="separator-10"></div>
                                                    <small class="text-muted">
                                                        Receba o pagamento pessoalmente, via transferência bancária ou PIX.
                                                    </small>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 col-md-4">
                                    <div class="card bg-light payment-option shadow-0" [class.selected]="invoice.paymentMethod === 'link'">
                                        <div class="card-body">
                                            <div class="form-group">
                                                <label class="cursor-pointer">
                                                    <input type="radio" [(ngModel)]="invoice.paymentMethod" name="paymentMethod" value="link" class="d-none"><strong>Pagamento via Link</strong>
                                                    <div class="separator-10"></div>
                                                    <small class="text-muted">
                                                        Informe um link de terceiros para receber o pagamento desta fatura.
                                                    </small>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 col-md-4">
                                    <div class="card bg-light payment-option shadow-0" [class.selected]="invoice.paymentMethod === 'creditCard'">
                                        <div class="card-body">
                                            <div class="form-group">
                                                <label class="cursor-pointer">
                                                    <input type="radio" [(ngModel)]="invoice.paymentMethod" name="paymentMethod" value="creditCard" class="d-none"><strong>Cartão de Crédito</strong>
                                                    <div class="separator-10"></div>
                                                    <small class="text-muted">
                                                        Seu cliente faz o pagamento via cartão de crédito com opção de parcelamento.
                                                    </small>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="invoice.paymentMethod !== ''" class="separator-30"></div>

                            <!-- Payment method = none -->
                            <div *ngIf="invoice.paymentMethod === 'none'">
                                <div class="form-group">
                                    <label class="form-label"><strong>Receber Manualmente</strong></label>
                                </div>
                                <div class="separator-5"></div>
                                <small class="text-muted">
                                    <ul>
                                        <li>Receba pagamentos diretamente do seu cliente via transferência bancária ou PIX</li>
                                        <li>Quando você for pago, clique em "Marcar como paga" para fechar esta fatura</li>
                                        <li>Dica: Informe os dados bancários ou PIX no campo "Observações para o cliente"</li>
                                    </ul>
                                </small>
                            </div>

                            <!-- Payment method = link -->
                            <div *ngIf="invoice.paymentMethod === 'link'">
                                <div class="form-group">
                                    <label class="form-label"><strong>Receber via Link</strong></label>
                                </div>
                                <div class="separator-5"></div>
                                <small class="text-muted">
                                    <ul>
                                        <li>Ao menos um link é obrigatório</li>
                                        <li>Você pode informar vários links adicionais como opções para seu cliente</li>
                                        <li>O cliente fará o pagamento através de um dos links que você fornecer</li>
                                        <li>O link de pagamento será visível para o cliente</li>
                                        <li>Quando o cliente clicar no link ele será redirecionado para a página de pagamento</li>
                                        <li>Quando você for pago, clique em "Marcar como pago" para fechar esta fatura</li>
                                        <li>Nós recomendamos a <a (click)="openPaymentRecommendationLink()" href="javascript: void(null)" target="_blank" rel="noopener noreferrer" class="link">Wise</a> para receber os pagamentos</li>
                                    </ul>
                                </small>

                                <div class="separator-10"></div>

                                <div class="form-group">
                                    <input
                                        type="url"
                                        [disabled]="loadingSaveInvoice"
                                        [(ngModel)]="invoice.paymentLink"
                                        name="paymentLink"
                                        placeholder="Insira o link completo, incluindo http:// ou https://"
                                        class="form-control"
                                        autocomplete="off" />
                                            <div *ngIf="invoice.paymentLink && !helpersService.isValidUrl(invoice.paymentLink)" class="mt-1 text-danger">
                                                <small>
                                                    <i>{{ invoice.paymentLink }}</i> não é um link válido
                                                </small>
                                            </div>
                                </div>

                                <div class="separator-10"></div>

                                <!-- Additional links list -->
                                <div *ngIf="invoice.paymentLinksAlternative && invoice.paymentLinksAlternative.length > 0">
                                    <div class="separator-5"></div>

                                    <small class="text-muted">
                                        Links Adicionais
                                    </small>

                                    <div class="separator-10"></div>

                                    <ul *ngIf="invoice.paymentLinksAlternative && invoice.paymentLinksAlternative.length > 0" class="list-group">
                                        <li *ngFor="let item of invoice.paymentLinksAlternative; let i = index" class="list-group-item">
                                            <div class="row">
                                                <div class="col-1 text-muted">
                                                    <small>#{{ i+1 }}</small>
                                                </div>
                                                <div class="col-9">
                                                    <a [href]="item" target="_blank">{{ item | truncate: 100 }}</a>
                                                </div>
                                                <div class="col-2 text-end">
                                                    <button (click)="removeInvoiceAlternativeLink(item)" [disabled]="loadingSaveInvoice" class="btn btn-sm btn-clean text-danger">
                                                        <i class="fas fa-minus-circle me-2"></i> remover
                                                    </button>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div class="separator-10"></div>

                                <!-- Add new alternative payment link -->
                                <div *ngIf="toggleAddPaymentLink">
                                    <div class="separator-5"></div>

                                    <div class="row align-items-center">
                                        <div class="col-12 col-md-10">
                                            <div class="form-group">
                                                <input
                                                    [(ngModel)]="newLink"
                                                    name="newLink"
                                                    type="url"
                                                    class="form-control form-control-sm"
                                                    placeholder="Insira o link completo, incluindo http:// ou https://"
                                                    autocomplete="off">
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-2 d-grid">
                                            <button
                                                [disabled]="!newLink || (newLink && !helpersService.isValidUrl(newLink))"
                                                (click)="addNewPaymentLink()" type="button" class="btn btn-outline-primary btn-sm"><i class="fas fa-plus me-2"></i> Adicionar</button>
                                        </div>
                                    </div>
                                    <div *ngIf="newLink && !helpersService.isValidUrl(newLink)" class="mt-1 text-danger">
                                        <small>
                                            <i>{{ newLink }}</i> não é um link válido
                                        </small>
                                    </div>

                                    <div class="separator-10"></div>
                                </div>

                                <button [disabled]="loadingSaveInvoice" (click)="toggleAddPaymentLink = !toggleAddPaymentLink" type="button" class="btn btn-link btn-sm p-0">
                                    <i class="fas fa-plus me-2"></i> Incluir Link Adicional
                                </button>
                            </div>

                            <!-- Payment method = link -->
                            <div *ngIf="invoice.paymentMethod === 'creditCard'">
                                <div class="form-group">
                                    <label class="form-label"><strong>Receber via Cartão de Crédito</strong></label>
                                </div>
                                <div class="separator-5"></div>
                                <small class="text-muted">
                                    <ul>
                                        <li>Seu cliente pode parcelar este pagamento em até 12x</li>
                                        <li>Você recebe o valor total direto na sua conta bancária</li>
                                        <li>O cliente fará o pagamento sem sair da plataforma da Invoicefy</li>
                                        <li>Esta fatura será automaticamente marcada como paga quando o pagamento for confirmado</li>
                                        <li>O pagamento é processado de forma segura pela <a href="https://pagar.me/" target="_blank" rel="noopener noreferrer">Pagar.me</a></li>
                                    </ul>
                                </small>
                            </div>


                            <div class="separator-30"></div>


                            <!--
                                -- 
                                -- 
                                -- 
                                -- 
                                Customer notes
                                -- 
                                -- 
                                -- 
                                -- 
                            -->
                            <div class="form-group">
                                <label class="form-label"><strong>Observações para o cliente</strong></label>
                                <div class="separator-5"></div>

                                <small class="text-muted">
                                    Adicione anotações extras para o cliente. Por exemplo: dados bancários, PIX, etc.<br>
                                    Isso será visível para o cliente no email da fatura e na página de pagamento.
                                </small>

                                <div class="separator-10"></div>

                                <textarea
                                    [disabled]="loadingSaveInvoice"
                                    [(ngModel)]="invoice.description"
                                    autosize
                                    name="description"
                                    cols="30"
                                    rows="2"
                                    class="form-control"
                                    placeholder="Adicione alguma informação extra para o cliente. Isso será visível para o cliente."
                                    autocomplete="off"></textarea>
                            </div>
    
                            <div class="separator-40"></div>


                            <!--
                                --
                                --
                                --
                                --
                                Extra
                                --
                                --
                                --
                                --
                            -->
    
                            <ngb-accordion #acc="ngbAccordion" [activeIds]="accordionActiveIds" [closeOthers]="true">

                                <!--
                                    --
                                    --
                                    --
                                    --
                                    Attachments
                                    --
                                    --
                                    --
                                    --
                                -->
                                <ngb-panel id="ngb-panel-0" title="Opções Extras">
                                    <ng-template ngbPanelContent>

                                        <strong>Anexos <span class="text-muted">({{ invoice.attachments ? invoice.attachments.length : 0 }})</span></strong>
                                        <div class="separator-5"></div>
                                        <small class="text-muted">
                                            Adicione arquivos extras para enviar junto com a fatura, como relatórios, comprovantes, etc.
                                        </small>

                                        <div *ngIf="invoice.attachments && invoice.attachments.length > 0">
                                            <div class="separator-20"></div>

                                            <ul class="list-group">
                                                <li *ngFor="let item of invoice.attachments; let i = index" class="list-group-item">
                                                    <div class="row align-items-center">
                                                        <div class="col-1 text-muted">
                                                            <small>#{{ i+1 }}</small>
                                                        </div>
                                                        <div class="col-7">{{ item.fileName | truncate: 40 }}</div>
                                                        <div class="col-3 text-end">
                                                            <a [href]="item.fileUrl" target="_blank" rel="noopener noreferrer" class="btn btn-link btn-sm p-0">
                                                                abrir <i class="fas fa-external-link-alt ms-2"></i>
                                                            </a>
                                                        </div>

                                                        <div class="col-1 text-end">
                                                            <button (click)="removeAttachment(item)" class="btn btn-sm btn-clean text-danger">
                                                                <i class="fas fa-minus-circle"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                        <div class="separator-10"></div>

                                        <div *ngIf="loadingUploadAttachment">
                                            <app-loading parent="button" color="text-primary"></app-loading>
                                            <small class="text-muted">{{uploadProgress}}% enviando anexo</small>
                                        </div>

                                        <app-uploader
                                            *ngIf="!invoice.attachments || invoice.attachments.length <= 6"
                                            label="Incluir Anexo"
                                            folder="attachments"
                                            (onLoading)="onLoadingUploadFile($event)"
                                            (onUpload)="onUploadFile($event)"
                                            (onPercentageChange)="uploadProgress = $event"
                                            [loading]="loadingSaveInvoice || loadingSendInvoice"></app-uploader>

                                        <div class="separator-30"></div>


                                        <!--
                                            --
                                            --
                                            --
                                            --
                                            Private notes
                                            --
                                            --
                                            --
                                            --
                                        -->

                                        <strong>Observações Privadas</strong>
                                        <div class="separator-5"></div>

                                        <small class="text-muted">
                                            Adicione anotações privadas para você. Isso não será visível para o cliente.
                                        </small>

                                        <div class="separator-10"></div>

                                        <div class="form-group">
                                            <textarea
                                                [disabled]="loadingSaveInvoice"
                                                [(ngModel)]="invoice.notes"
                                                autosize
                                                name="notes" cols="30"
                                                rows="2"
                                                class="form-control"
                                                placeholder="Adicione alguma informação extra para você. Isso não será visível para o cliente."
                                                autocomplete="off"></textarea>
                                        </div>

                                    </ng-template>
                                </ngb-panel>
                            </ngb-accordion>

                        </div><!-- /.card-body -->
                    </div><!-- /.card -->

                </div><!-- /.col -->
                <div class="col-12 col-md-4">
                    <div class="card border-0">
                        <div class="card-body">

                            <!--
                                -- 
                                -- 
                                -- 
                                -- 
                                Due Date
                                -- 
                                -- 
                                -- 
                                -- 
                            -->
                            <div class="row align-items-start">
                                <div class="col-12 col-md-4">
                                    <div class="form-group">
                                        <label class="form-label text-muted">
                                            <small>Vencimento</small>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-8">
                                    <input
                                        [disabled]="loadingSaveInvoice"
                                        [(ngModel)]="invoice.dueDate"
                                        (ngModelChange)="checkDueDateAndReminders()"
                                        name="dueDate"
                                        type="date"
                                        class="form-control form-control-sm"
                                        [min]="today"
                                        [max]="todayPlus30"
                                        autocomplete="off">
                                    <div *ngIf="invoice.dueDate && invoice.dueDate !== ''" class="text-end">
                                        <div class="separator-5"></div>
                                        <button (click)="clearDueDate()" type="button" class="btn btn-sm btn-clean btn-link text-muted">
                                            <i class="fas fa-times-circle me-2"></i> Remover data de vencimento
                                        </button>
                                    </div>
                                </div>
                            </div>



                            <!-- REMINDERS -->

                                <div class="separator-10"></div>

                                <div class="card bg-super-light shadow-0 border-0">
                                    <div class="card-body">
                                        <div class="form-check form-switch">
                                            <label class="form-check-label">
                                                <input
                                                    [disabled]="loadingSaveInvoice || !invoice.dueDate || isTodayLessThan5DaysOfDueDate()"
                                                    type="checkbox"
                                                    [(ngModel)]="invoice.sendReminder"
                                                    name="sendReminder"
                                                    class="me-2 form-check-input"
                                                    role="switch"> <strong>Enviar lembretes</strong> <i class="fas fa-bell ms-2 text-muted"></i>
                                            </label>
                                        </div>

                                        <div class="separator-10"></div>

                                        <small class="text-muted">
                                            O cliente receberá um lembrete por email 2 dias antes do vencimento e também no dia do vencimento.
                                        </small>

                                    </div>
                                </div>

                                <div class="separator-10"></div>
                            <!-- </div> -->



                            <div class="separator-20"></div>

                            <!--
                                --
                                --
                                --
                                --
                                Discount
                                --
                                --
                                --
                                --
                            -->
                            <div class="row align-items-end">
                                <div class="col-12 col-md-4">
                                    <div class="form-group">
                                        <label class="form-label text-muted">
                                            <small>Desconto</small>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 offset-md-2">
                                    <input
                                        [(ngModel)]="invoice.discount"
                                        [disabled]="loadingSaveInvoice || loadingSendInvoice"
                                        (keyup)="calcInvoiceDiscount()"
                                        currencyMask
                                        [options]="getCurrencyFormatOptions()"
                                        name="discount"
                                        type="tel"
                                        class="form-control form-control-sm"
                                        autocomplete="off">
                                </div>
                            </div>
                            <div class="separator-5"></div>
                            <div class="row align-items-end">
                                <div class="col-12 col-md-6 offset-md-6 text-end">
                                    <button
                                        [disabled]="loadingSaveInvoice || loadingSendInvoice"
                                        (click)="removeInvoiceDiscount()"
                                        *ngIf="invoice.discount && invoice.discount !== 0"
                                        class="btn btn-sm btn-clean btn-link text-muted"
                                        type="button">
                                            <i class="fas fa-times-circle me-2"></i>
                                            Remover desconto
                                    </button>
                                </div>
                            </div>

                            <div class="separator-30"></div>


                            <!--
                                --
                                --
                                --
                                --
                                Subtotal
                                --
                                --
                                --
                                --
                            -->
                            <div class="row text-muted">
                                <div class="col-12 col-md-6">
                                    <small>Subtotal</small>
                                </div>
                                <div class="col-12 col-md-6 text-end">
                                    <small>{{ invoice.total | currency : invoice.currency.toUpperCase() }}</small>
                                </div>
                            </div>

                            <!-- SUM discount -->
                            <div class="row text-muted">
                                <div class="separator-10"></div>

                                <div class="col-12 col-md-6">
                                    <small>Desconto</small>
                                </div>
                                <div class="col-12 col-md-6 text-end">
                                    <small>
                                        <span *ngIf="invoice.discount != 0">-</span>{{ invoice.discount | currency : invoice.currency.toUpperCase() }}
                                    </small>
                                </div>
                            </div>

                            <div class="separator-10"></div>


                            <!--
                                --
                                --
                                --
                                --
                                Total
                                --
                                --
                                --
                                --
                            -->
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <h4><strong>TOTAL</strong></h4>
                                </div>
                                <div class="col-12 col-md-6 text-end">
                                    <h4><strong>{{ invoice.grandTotal | currency : invoice.currency.toUpperCase() }}</strong></h4>
                                </div>
                            </div>

                        </div><!-- /.card-body -->
                    </div><!-- /.card -->

                    <div class="separator-20"></div>

                    <small class="text-muted">
                        Criada em: {{ helpersService.convertFirebaseTimestampToString(invoice.createdAt) | date: "dd/MM/YYYY HH:mm:ss" }}<br>
                        Última atualização: {{ helpersService.convertFirebaseTimestampToString(invoice.updatedAt) | date: "dd/MM/YYYY HH:mm:ss" }}
                    </small>

                    <!--
                        --
                        --
                        --
                        --
                        Tracking history
                        --
                        --
                        --
                        --
                    -->
                    <app-invoice-tracking-history *ngIf="invoice" [invoice]="invoice"></app-invoice-tracking-history>

                </div>
            </div><!-- /.row -->

        </form>
    </div>
</section>




<!-- 
    --
    --
    --
    -- MODALS
    --
    --
    --
 -->

 <!-- Modal create new customer -->
<ng-template #createNewCustomerModal let-modalNewCustomer>
    <div class="modal-body">
        <strong>Novo Cliente</strong>
        <div class="separator-20"></div>

        <app-customers-form
            (onSubmit)="onSubmitCustomer($event)"
            (onLoading)="loadingCreateCustomer = $event"
            action="create"></app-customers-form>

        <div class="separator-10"></div>

        <div class="text-center">
            <button type="button" [disabled]="loadingCreateCustomer" class="btn btn-link btn-sm" (click)="helpersService.closeModals()">Fechar</button>
        </div>
    </div>
</ng-template>



<!-- Modal confirm send invoice -->
<ng-template #content let-modal>
    <app-confirm-content [loading]="loadingSaveInvoice || loadingSendInvoice || invoice.items.length === 0 || !invoice.customer.id || (invoice.paymentMethod === 'link' && !invoice.paymentLink)">
        <span class="content">

            <!-- SCHEDULE -->
            <!--
            <div class="card bg-super-light shadow-0 border-0">
                <div class="card-body">

                    <strong>Envio Agendado</strong>

                    <div class="separator-10"></div>

                    <small class="text-muted">
                        Selecione uma data para que esta fatura seja automaticamente enviada para o cliente selecionado.
                    </small>

                    <div class="separator-10"></div>

                    <div class="row align-items-center">
                        <div class="col-12 col-md-6">
                            Agendar envio para
                        </div>
                        <div class="col-12 col-md-6">
                            <input type="date"
                                [min]="todayPlus2"
                                [max]="todayPlus30"
                                [(ngModel)]="invoice.scheduledTo"
                                [disabled]="loadingSaveInvoice || !invoice.customer || invoice.customer.email === '' || invoice.dueDate !== ''"
                                name="scheduledTo"
                                class="form-control">

                            <div *ngIf="invoice.scheduledTo !== ''">
                                <div class="separator-5"></div>
                                <small class="text-muted">
                                    <button type="button" (click)="invoice.scheduledTo = ''" class="btn btn-sm btn-clean btn-link text-muted">
                                        <i class="fas fa-times-circle me-2"></i> Limpar agendamento
                                    </button>
                                </small>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="separator-10"></div>
            -->

            <p>
                <strong>Ao clicar em "Continuar" você confirma que:</strong>
            </p>
            <ul class="list-group">
                <li class="list-group-item">
                    <i class="fas fa-check-circle text-primary me-2"></i> Esta fatura será marcada como 
                        <div class="badge uppercase bg-success ms-1">
                            {{ helpersService.translateStatus("sent") }}
                        </div>
                </li>
                <li class="list-group-item">
                    <i class="fas fa-check-circle text-primary me-2"></i> Não será possível modificar esta fatura após enviar
                </li>
                <li class="list-group-item" *ngIf="invoice.dueDate && invoice.dueDate !== ''">
                    <i class="fas fa-check-circle text-primary me-2"></i> A data de vencimento é <strong>{{ invoice.dueDate | date: "dd/MM/yyyy" }}</strong>
                </li>
                <li class="list-group-item" *ngIf="invoice.dueDate !== '' && invoice.sendReminder && !isTodayLessThan5DaysOfDueDate()">
                    <i class="fas fa-check-circle text-primary me-2"></i> O envio de lembretes está <strong>ativo</strong>
                    <div class="ms-4 ps-1 text-muted">
                        <small>
                            O cliente vai receber a fatura agora, um lembrete dois dias antes do vencimento ({{ getFirstReminderDate() }}) e outro lembrete no dia do vencimento
                        </small>
                    </div>
                </li>
                <li class="list-group-item" *ngIf="invoice.customer.email">
                    <i class="fas fa-check-circle text-primary me-2"></i> A fatura será enviada para <strong>"{{ invoice.customer.email }}"</strong>
                </li>
                <li class="list-group-item" *ngIf="toggleAdditionalRecipients && invoice.cc">
                    <i class="fas fa-check-circle text-primary me-2"></i> Uma cópia desta fatura será enviada para <strong>{{ invoice.cc }}</strong>
                </li>
                <li class="list-group-item" *ngIf="toggleAdditionalRecipients && invoice.bcc">
                    <i class="fas fa-check-circle text-primary me-2"></i> Uma cópia oculta desta fatura será enviada para <strong>{{ invoice.bcc }}</strong>
                </li>
                <li class="list-group-item" *ngIf="invoice.paymentMethod === 'link'">
                    <i class="fas fa-check-circle text-primary me-2"></i> O cliente fará o pagamento desta fatura via link
                </li>
                <li class="list-group-item">
                    <small class="text-muted">Configurações do email:</small>

                    <div class="separator-5"></div>

                    <div *ngIf="invoiceSettings.name; else settingsHasNoName"><i class="fas fa-check-circle text-primary me-2"></i> O email vai mostrar o nome <strong>"{{ invoiceSettings.name }}"</strong> como remetente;</div>
                    <div *ngIf="invoiceSettings.emailSubject; else settingsHasNoEmailSubject"><i class="fas fa-check-circle text-primary me-2"></i> O assunto do email será <strong>"{{ parseEmailSubject() }}"</strong>;</div>
                    <div *ngIf="invoiceSettings.logo.downloadUrl; else settingsHasNoLogo"><i class="fas fa-check-circle text-primary me-2"></i> A sua logo será utilizada no corpo do email e na invoice;</div>

                    <ng-template #settingsHasNoName>
                        <i class="fas fa-times-circle text-danger me-2"></i> Você não configurou um nome para o seu remetente. Acesse o menu <i>Ajustes</i> para configurar;
                    </ng-template>

                    <ng-template #settingsHasNoEmailSubject>
                        <i class="fas fa-times-circle text-danger me-2"></i> Você não configurou um assunto para os emails com as faturas. Acesse o menu <i>Ajustes</i> para configurar;
                    </ng-template>

                    <ng-template #settingsHasNoLogo>
                        <i class="fas fa-times-circle text-danger me-2"></i> Você não configurou uma logo para a sua fatura. Acesse o menu <i>Ajustes</i> para configurar;
                    </ng-template>
                </li>
                <li class="list-group-item">
                    <i class="fas fa-check-circle text-primary me-2"></i> Os valores serão exibidos em <strong class="uppercase">{{ helpersService.getCurrencyName(invoice.currency) }}</strong>;
                </li>
            </ul>

            <div class="separator-20"></div>

            <!-- SEND A COPY -->
            <div class="card bg-super-light shadow-0 border-0">
                <div class="card-body">

                    <div class="form-check form-switch">
                        <label class="form-check-label">
                            <input type="checkbox" role="switch" [(ngModel)]="invoice.sendCopyToMe" name="sendCopyToMe" [value]="true" class="form-check-input me-2"> Enviar uma cópia da fatura para o meu e-mail
                        </label>
                    </div>

                </div>
            </div>

            <div class="separator-20"></div>
        </span>

        <button
            (click)="submitInvoice('send')"
            [disabled]="loadingSaveInvoice || loadingSendInvoice || invoice.items.length === 0 || !invoice.customer.id || (invoice.paymentMethod === 'link' && !invoice.paymentLink)"
            type="button"
            class="btn btn-success">
                <span *ngIf="loadingSendInvoice">
                    <app-loading parent="button" color="text-dark"></app-loading> <strong>Preparando Fatura...</strong>
                </span>
                <span *ngIf="!loadingSendInvoice">
                    <i class="fas fa-check me-2"></i> <strong>Confirmar Envio</strong>
                </span>
        </button>
    </app-confirm-content>
</ng-template>