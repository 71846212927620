import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { MessageService } from 'src/app/services/message.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.scss']
})
export class UpgradeComponent implements OnInit, OnDestroy {

  sub: Subscription[] = [];
  user: any = {};
  loadingUpgrade: boolean = false;

  constructor(
    private authService: AuthService,
    private usersService: UsersService,
    private router: Router,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this._getUser();
  }

  ngOnDestroy(): void {
    this.sub.forEach(s => s.unsubscribe());
  }

  private _getUser(): void {
    this.user = this.authService.getUser();
  }

  upgradeToPro(formData: any): void {
    this.loadingUpgrade = true;
    formData.subscribeTo = 'pro';
    this.sub.push(
      this.usersService.subscribeToPlan(formData).subscribe(() => {
        this.loadingUpgrade = false;
        this.messageService.send('NavbarComponent', 'upgradedToProSuccess');
        this.router.navigate(['/panel/welcome-to-pro']);
      })
    );
  }

}
