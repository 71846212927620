import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import Profile from "../models/profile.interface";

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    constructor(
        private http: HttpClient,
    ) { }

    getProfile(uid: string): any {
        return this.http.get<any>(`${environment.api}/users/${uid}`);
    }

    confirmAccount(uid: string): any {
        return this.http.get<any>(`${environment.api}/users/${uid}/confirm-account`);
    }

    createProfile(payload: Profile): any {
        return this.http.post<any>(`${environment.api}/users`, payload);
    }

    subscribeToPlan(payload: any): any {
        return this.http.post<any>(`${environment.api}/users/subscribeToPlan`, payload);
    }

    updateProfile(payload: Profile): any {
        return this.http.put<any>(`${environment.api}/users`, payload);
    }

    updateSettings(payload: Profile): any {
        return this.http.put<any>(`${environment.api}/users/settings`, payload);
    }

}