<form #formCustomers="ngForm" novalidate>
    <input *ngIf="action === 'update'" [ngModel]="customer.id" name="id" [disabled]="loading" type="hidden" autocomplete="off" required>

    <div class="form-group">
        <label class="form-label">Nome do Cliente <span class="text-danger">*</span></label>
        <input
            [ngModel]="customer.name"
            [disabled]="loading"
            name="name"
            type="text"
            class="form-control"
            autocomplete="off"
            required
            placeholder="João da Silva">
    </div>

    <div class="separator-10"></div>

    <div class="form-group">
        <label class="form-label">Email</label>
        <input
            [ngModel]="customer.email"
            [disabled]="loading"
            name="email"
            type="email"
            email
            class="form-control lowercase"
            autocomplete="off"
            placeholder="joao@email.com.br">
    </div>

    <div class="separator-10"></div>

    <div class="form-group">
        <label class="form-label">Telefone</label>
        <!--
        <ngx-intl-tel-input
            [enableAutoCountrySelect]="true"
            [enablePlaceholder]="true"
            [onlyCountries]="onlyCountries"
            [searchCountryFlag]="true"
            [searchCountryField]="SearchCountryField"
            [selectFirstCountry]="false"
            [selectedCountryISO]="CountryISO.Brazil"
            [maxLength]="18"
            [phoneValidation]="true"
            [separateDialCode]="false"
            [numberFormat]="PhoneNumberFormat.National"
            name="phone"
            type="text"
            class="form-control"
            [ngModel]="customer.phone"
            [disabled]="loading"
            (countryChange)="onCountryChange($event)"
        ></ngx-intl-tel-input>
        -->
        <input
            [ngModel]="customer.phone"
            [disabled]="loading"
            name="phone"
            type="text"
            class="form-control"
            autocomplete="off"
            [specialCharacters]="['(',')','-']" mask="(00)00000-0000"
            placeholder="(00)0000-0000">
    </div>

    <div class="separator-5"></div>

    <div class="form-group">
        <label>
            <input type="checkbox" [(ngModel)]="customer.isWhatsapp" name="isWhatsapp" [value]="customer.isWhatsapp ? true : false" class="me-2" /> Este número é WhatsApp <i class="fab fa-whatsapp ms-1 text-muted"></i>
        </label>
    </div>

    <div class="separator-10"></div>

    <div class="form-group">
        <label class="form-label">Site</label>
        <input
            [ngModel]="customer.address"
            [disabled]="loading"
            name="address"
            type="text"
            class="form-control"
            autocomplete="off"
            placeholder="https://exemplo.com.br">
    </div>

    <div class="separator-10"></div>

    <div class="form-group">
        Anotações Privadas
        <div class="separator-5"></div>
        <small class="text-muted">
            Seu cliente não verá esta anotação.
        </small>
        <div class="separator-5"></div>
        <textarea
            [ngModel]="customer.notes"
            [disabled]="loading"
            autosize
            name="notes"
            class="form-control"
            autocomplete="off">
        </textarea>
    </div>

    <div class="separator-20"></div>

    <div class="d-grid">
        <button
            (click)="submit(formCustomers.value)"
            [disabled]="loading || !formCustomers.valid"
            type="button"
            class="btn btn-primary">
                <app-loading *ngIf="loading" parent="button"></app-loading> 
                <i *ngIf="!loading" class="fas fa-check-circle me-2"></i> 
                <span *ngIf="action === 'create'">Adicionar Cliente</span>
                <span *ngIf="action === 'update'">Atualizar Cliente</span>
        </button>
    </div>
</form>