import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import Invoice from '../models/invoice.interface';
import { HelpersService } from './helpers.service';
import { MessageService } from './message.service';

// Firebase
import 'firebase/storage'
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireStorage } from '@angular/fire/compat/storage';


@Injectable({
  providedIn: 'root'
})
export class InvoicesService {

  percentage: any = 0;

  constructor(
    private http: HttpClient,
    private helpersService: HelpersService,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    private messageService: MessageService,
  ) { }

  getInvoices(): any {
    return this.http.get<any>(`${environment.api}/invoices`);
  }

  getInvoiceTrackingHistory(id: string): any {
    return this.http.get<any>(`${environment.api}/invoices/${id}/trackingHistory`);
  }

  getInvoiceDetailedChangesHistory(id: string): any {
    return this.http.get<any>(`${environment.api}/invoices/${id}/detailedChangesHistory`);
  }

  // This DB node (/{invoiceId}) is created in the backend (api/functions/src/common/generateInvoicePdf.ts) when the invoice file is generated.
  getRealTimeInvoiceFileUrl(id: string): any {
    this.db.list(`${id}`).valueChanges().subscribe((snapshot: any) => {
      const invoiceFileUrl: string =  snapshot[0];
      if (invoiceFileUrl) {
        this.messageService.send('InvoiceSingleComponent', 'updateSignedUrl', invoiceFileUrl);
      }
    });
  }

  createInvoice(): any {
    return this.http.post<any>(`${environment.api}/invoices`, {});
  }

  reportsInvoice(payload: any): any {
    return this.http.post<any>(`${environment.api}/invoices/report`, payload);
  }

  getInvoiceById(id: string): any {
    return this.http.get<any>(`${environment.api}/invoices/${id}`);
  }

  getPublicInvoiceById(id: string): any {
    return this.http.get<any>(`${environment.api}/invoices/${id}/public`);
  }

  duplicateInvoice(invoice: Invoice): any {
    return this.http.post<any>(`${environment.api}/invoices/${invoice.id}/duplicate`, {});
  }

  cancelInvoice(invoice: Invoice): any {
    return this.http.post<any>(`${environment.api}/invoices/${invoice.id}/cancel`, invoice);
  }

  updateInvoice(invoice: Invoice): any {
    return this.http.post<any>(`${environment.api}/invoices/${invoice.id}`, invoice);
  }

  markInvoiceAsPaid(invoice: Invoice): any {
    return this.http.post<any>(`${environment.api}/invoices/${invoice.id}/markAsPaid`, invoice);
  }

  reSendInvoice(invoice: Invoice): any {
    return this.http.post<any>(`${environment.api}/invoices/${invoice.id}/reSend`, invoice);
  }

  sendInvoice(invoice: Invoice): any {
    return this.http.post<any>(`${environment.api}/invoices/${invoice.id}/send`, invoice);
  }

  addPaymentProofToInvoice(invoice: Invoice, role: string = 'customer'): any {
    return this.http.post<any>(`${environment.api}/invoices/${invoice.id}/addPaymentProof`, {uid: invoice.uid, paymentProof: invoice.paymentProof, role});
  }

  uploadAttachmentToStorage(file: File, folder: string = '', uid: string): Promise<any> {
    if (folder.charAt(folder.length - 1) === '/') {
      folder = folder.slice(0, -1);
    }

    return new Promise(async (resolve, reject) => {
      const randomString: string = this.helpersService.randomString(20);
      const extension = file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length);
      const filePath: string = [uid,folder,randomString].join('/') + `.${extension}`;
      const storageRef: any = this.storage.ref(encodeURI(filePath));

      const putFile: any = storageRef.put(file);

      putFile.percentageChanges().subscribe((progress: number) => {
        this.percentage = progress.toFixed(0);
      });

      putFile.then(async () => {
        await storageRef.getDownloadURL().subscribe((downloadUrl: string) => {
          resolve({
            name: file.name,
            extension: extension,
            nameHashed: `${randomString}.${extension}`,
            downloadUrl,
            type: file.type || "application/pdf",
            filePath: filePath,
          });
        });
      }).catch((err: any) => reject(err));
    });
  }
}
