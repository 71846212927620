<!-- DUE DATE -->

<div *ngIf="role === 'owner'">
    <div class="row align-items-center">
        <div class="col-12 col-md-3">
            <small>
                <strong>&rtrif; Data de vencimento</strong>
            </small>
        </div>
        <div class="col-12 col-md-9">

            <div class="card border-0">
                <div class="card-body">

                    {{ (invoice.dueDate | date: 'dd/MM/yyyy') || '--' }}

                </div>
            </div>

        </div>
    </div>
    <div class="separator-20"></div>
</div>

<!-- REMINDERS -->

<div *ngIf="role === 'owner' && invoice.dueDate">
    <div class="row">
        <div class="col-12 col-md-3">
            <small>
                <strong>&rtrif; Envio de lembretes</strong>
            </small>
        </div>
        <div class="col-12 col-md-9">

            <div class="card border-0">
                <div class="card-body">

                    <span *ngIf="invoice.sendReminder; else noReminders">
                        <i class="fas fa-bell me-2 text-primary"></i> <strong>Ativado</strong>
                        <div *ngIf="invoice.status === 'sent'">
                            <div class="separator-5"></div>
                            <small class="text-muted">
                                O cliente vai receber o primeiro lembrete por email 2 dias antes do vencimento desta fatura e outro no dia do vencimento.
                            </small>
                        </div>
                    </span>
                    <ng-template #noReminders>
                        <i class="fas fa-bell-slash me-2 text-muted"></i> <strong>Desativado</strong>
                        <div *ngIf="invoice.status === 'sent'">
                            <div class="separator-5"></div>
                            <small class="text-muted">
                                O cliente não vai receber lembretes sobre a data de vencimento desta fatura.
                            </small>
                        </div>
                    </ng-template>

                </div>
            </div>

        </div>
    </div>

    <div class="separator-20"></div>
</div>

<!-- CUSTOMER -->

<div *ngIf="invoice.customer" class="row">
    <div class="col-12 col-md-3">
        <small>
            <strong>&rtrif; Cliente</strong>
        </small>
    </div>
    <div class="col-12 col-md-9">

        <div class="card border-0">
            <div class="card-body">

                <strong>{{ invoice.customer.name }}</strong>
                <div class="separator-5"></div>
                <small class="text-muted">
                    <i style="width: 20px" class="text-center fas fa-envelope me-2"></i> {{ invoice.customer.email || '-' }}<br>
                    <i style="width: 20px" class="text-center fas fa-phone me-2"></i> {{ (invoice.customer.phone | mask: helpersService.getDynamicPhoneMask(invoice.customer.phone)) || '-' }}<br>
                    <i style="width: 20px" class="text-center fas fa-map-pin me-2"></i> {{ invoice.customer.address || '-' }}<br>
                </small>

                <div *ngIf="role === 'owner' && invoice.customer.notes">
                    <div class="separator-20"></div>
                    <small>
                        <span class="text-muted">
                            <i class="fas fa-sticky-note me-2"></i> <span [innerHTML]="invoice.customer.notes || ''"></span>
                            <div class="separator-10"></div>
                            (seu cliente não vê esta informação)
                        </span>
                    </small>
                </div>

            </div>
        </div>

    </div>
</div>

<div class="separator-20"></div>

<div class="row">
    <div class="col-12 col-md-3">
        <small>
            <strong>&rtrif; Fatura enviada para</strong><br/>
            <span *ngIf="invoice.scheduledTo !== ''" class="text-muted">Enviada via agendamento</span>
        </small>
    </div>
    <div class="col-12 col-md-9">

        <div class="card border-0">
            <div class="card-body">

                <div *ngIf="invoice.customer">
                    <strong><i class="fas fa-envelope me-2 text-muted"></i> Cliente: </strong> {{ invoice.customer.email || '--' }}

                    <div class="separator-10"></div>

                    <div class="row">
                        <div class="col-12 col-md-6">
                            <small>
                                <strong><i class="fas fa-envelope me-2 text-muted"></i> Cc: </strong> {{ invoice.cc || '--' }}
                            </small>
                        </div>
                        <div *ngIf="role === 'owner'" class="col-12 col-md-6">
                            <small>
                                <strong><i class="fas fa-envelope me-2 text-muted"></i> Cco: </strong> {{ invoice.bcc || '--' }}
                            </small>
                        </div>
                    </div>

                    <div *ngIf="invoice.sendCopyToMe">
                        <div class="separator-10"></div>

                        <small class="text-muted">
                            <i class="fas fa-check-circle text-primary me-2"></i> Uma cópia desta fatura foi enviada para o seu email
                        </small>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>

<div class="separator-20"></div>


<!-- PUBLIC AND PRIVATE NOTES -->

<div class="row">
    <div class="col-12 col-md-3">
        <small>
            <strong>&rtrif; Anotações</strong>
        </small>
    </div>
    <div class="col-12 col-md-9">

        <div class="card border-0">
            <div class="card-body">

                <div class="row">
                    <div class="col-12" [class.col-md-6]="role === 'owner'" [class.col-md-12]="role === 'customer'">
                        <div class="card bg-light shadow-0 border-0">
                            <div class="card-body">
                                <small class="text-muted">
                                    Anotações para o cliente:
                                </small>
                                <div class="separator-10"></div>
                                <span class="line-break break-content" [innerHTML]="invoice.description || ''"></span>

                                <div class="separator-10"></div>
                                <small class="text-muted">Anotações de pagamento</small>
                                <div class="separator-10"></div>
                                <span class="line-break break-content" [innerHTML]="invoice.paymentPublicNote || ''"></span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="role === 'owner'" class="col-12 col-md-6">
                        <div class="card bg-light shadow-0 border-0">
                            <div class="card-body">
                                <small class="text-muted">
                                    Anotações somente para você:
                                </small>
                                <div class="separator-10"></div>
                                <span class="line-break break-content" [innerHTML]="invoice.notes || ''"></span>
                                <div class="separator-10"></div>
                                <small class="text-muted">Anotações de pagamento</small>
                                <div class="separator-10"></div>
                                <span class="line-break break-content" [innerHTML]="invoice.paymentPrivateNote || ''"></span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>


<div class="separator-20"></div>


<!-- ITEMS -->

<div *ngIf="invoice.items" class="row">
    <div class="col-12 col-md-3">
        <small>
            <strong>&rtrif; Itens da fatura <small class="text-muted">({{ invoice.items.length }} itens)</small></strong><br/>
            <span class="text-muted">Moeda: <strong class="uppercase">{{ invoice.currency }}</strong></span>
        </small>
    </div>
    <div class="col-12 col-md-9">

        <div class="card border-0">
            <div class="card-body">

                <ul class="list-group">
                    <li *ngFor="let item of invoice.items; let i = index" class="list-group-item">
                        <small class="text-muted me-2">#{{ i+1 }} </small> {{ item.name | truncate: 80 }}
                        <div class="float-end">
                            {{ item.price | currency : invoice.currency.toUpperCase() }}
                        </div>
                    </li>
                </ul>
                <ul class="list-unstyled">
                    <li class="text-muted pt-2 pe-3">
                        <small class="text-muted me-2">Subtotal</small>
                        <div class="float-end">
                            {{ invoice.total | currency : invoice.currency.toUpperCase() }}
                        </div>
                    </li>
                    <li class="text-muted pe-3">
                        <small class="text-muted me-2">Desconto</small>
                        <div class="float-end">
                            {{ invoice.discount | currency : invoice.currency.toUpperCase() }}
                        </div>
                    </li>
                    <li class="pt-2 pe-3">
                        <small class="me-2"><strong>Total</strong></small>
                        <div class="float-end">
                            {{ invoice.grandTotal | currency : invoice.currency.toUpperCase() }}
                        </div>
                    </li>
                </ul>

            </div>
        </div>

    </div>
</div>

<div class="separator-20"></div>

<!-- PAYMENT OPTIONS -->

<div *ngIf="role === 'owner'">
    <div class="row">
        <div class="col-12 col-md-3">
            <small>
                <strong>&rtrif; Método de Pagamento</strong>
            </small>
        </div>
        <div class="col-12 col-md-9">

            <div class="card border-0">
                <div class="card-body">

                    Pagamento via: 
                    <strong *ngIf="invoice.paymentMethod === 'none'">Sem Pagamentos / Manual</strong>
                    <strong *ngIf="invoice.paymentMethod === 'link'">Link</strong>
                    <div *ngIf="invoice.paymentMethod === 'link'">
                        <div class="separator-20"></div>

                        <a [href]="invoice.paymentLink" target="_blank" rel="noopener noreferrer">{{ invoice.paymentLink | truncate: 80 }}</a>

                        <div *ngIf="invoice.paymentLinksAlternative && invoice.paymentLinksAlternative.length > 0">
                            <div class="separator-20"></div>

                            <small class="text-muted">
                                Outras opções para pagamento:
                            </small>

                            <div class="separator-10"></div>

                            <ul class="list-unstyled">
                                <li *ngFor="let link of invoice.paymentLinksAlternative; let i = index">
                                    <a [href]="link">{{ link | truncate: 80 }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>

    <div class="separator-20"></div>
</div>

<!-- FILES -->

<div *ngIf="(invoice.paymentProof && invoice.paymentProof.fileUrl && invoice.paymentProof.fileUrl !== '') || (invoice.attachments && invoice.attachments.length > 0)">
    <div class="row">
        <div class="col-12 col-md-3">
            <small>
                <strong>&rtrif; Arquivos</strong> <small class="text-muted ms-2">({{ (invoice.paymentProof ? 1 : 0) + (invoice.attachments ? invoice.attachments.length : 0) }} itens)</small>
            </small>
        </div>
        <div class="col-12 col-md-9">

            <div class="card border-0">
                <div class="card-body">

                    <!-- Payment proof -->
                    <div *ngIf="invoice.paymentProof && (invoice.paymentProof.fileUrl && invoice.paymentProof.fileUrl !== '') && (invoice.paymentProof.fileName && invoice.paymentProof.fileName !== '')">
                        <small class="text-muted">Comprovante de Pagamento (adicionado em {{ helpersService.convertFirebaseTimestampToString(invoice.paymentProof.updatedAt, "DD/MM/YYYY") }})</small>
                        <div class="separator-5"></div>
                        <a
                            [href]="invoice.paymentProof.fileUrl"
                            target="_blank"
                            class="btn btn-link btn-clean"
                            rel="noopener noreferrer">{{ invoice.paymentProof.fileName | truncate: 40 }} <i class="fas fa-external-link-alt ms-2"></i></a>

                        <div class="separator-10"></div>
                    </div>

                    <!-- Extra attachments -->
                    <div *ngIf="invoice.attachments && invoice.attachments.length > 0 && invoice.attachments[0].fileUrl !== ''">
                        <small class="text-muted">Outros Anexos</small>
                        <div class="separator-5"></div>

                        <ul class="list-unstyled">
                            <li *ngFor="let link of invoice.attachments; let i = index;">
                                <a
                                    [href]="link.fileUrl"
                                    target="_blank"
                                    class="btn btn-link btn-clean"
                                    rel="noopener noreferrer">{{ link.fileName | truncate: 40 }} <i class="fas fa-external-link-alt ms-2"></i></a>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>

        </div>

    </div>
</div>
