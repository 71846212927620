import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class LoggedInGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private router: Router,
    ) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isLoggedIn = this.authService.isLoggedIn();
        if (!isLoggedIn) {
            const currentUrl = state.url;
            this.router.navigate(['/auth/login'], { queryParams: { next: currentUrl } });
            return false;
        }

        return true;
    }

}