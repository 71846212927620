import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import Invoice from 'src/app/models/invoice.interface';
import { HelpersService } from 'src/app/services/helpers.service';
import { InvoicesService } from 'src/app/services/invoices.service';
import { MessageService } from 'src/app/services/message.service';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-invoice-single',
  templateUrl: './invoice-single.component.html',
  styleUrls: ['./invoice-single.component.scss']
})
export class InvoiceSingleComponent implements OnInit, OnDestroy {

  sub: Subscription[] = [];

  invoiceId: string = '';
  invoice: Invoice = {} as Invoice;
  loadingInvoice: boolean = false;

  loadingReSendInvoice: boolean = false;
  loadingCancelInvoice: boolean = false;
  loadingMarkAsPaid: boolean = false;

  cancelNote: string = '';
  sendCopyToMe: boolean = true;

  uploadProgress: number = 0;
  loadingUploadPaymentProof: boolean = false;

  invoiceSafeUrl: string | null = null;

  constructor(
    public helpersService: HelpersService,
    private activatedRoute: ActivatedRoute,
    private invoicesService: InvoicesService,
    private router: Router,
    private messageService: MessageService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this._getInvoiceId();

    this.sub.push(
      this.messageService.get().subscribe((message: any) => {
        if (message.to === 'InvoiceSingleComponent') {
          if (message.action === 'updateSignedUrl') {
            this._updateSignedUrl(message.payload);
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.sub.forEach((s: any) => s.unsubscribe());
  }

  private _getInvoiceId(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      this.invoiceId = params.id;
      this._getInvoiceById(this.invoiceId);
    });
  }

  private _getInvoiceById(id: string): void {
    this.loadingInvoice = true;
    this.invoicesService.getInvoiceById(id).subscribe((response: Invoice) => {
      // if (response.dueDate) {
      //   response.dueDate = new Date(response.dueDate).toISOString().split('T')[0];
      // }
      this.invoice = response;

      if (this.invoice.status === 'draft') {
        this.router.navigate(['/panel/invoices', this.invoice.id, 'editor']);
        return;
      }

      // If invoice file is not generated yet, get the realtime notification from API when it's ready
      if (this.invoice && this.invoice.invoiceFile && this.invoice.invoiceFile.fileUrl === "") {
        this.invoicesService.getRealTimeInvoiceFileUrl(this.invoiceId);

        // If invoice file is generated, get the signed url
      } else if (this.invoice.invoiceFile && this.invoice.invoiceFile.fileUrl) {
        this.invoiceSafeUrl = this.helpersService.sanitizeUrl(this.invoice.invoiceFile.fileUrl);
      }

      this.loadingInvoice = false;
    }, (err: any) => {
      console.log('err', err);
      this.loadingInvoice = false;
      if (+err.status === 403 || +err.status === 404) {
        this.router.navigate(['/panel/not-found']);
      }
    });
  }

  private _updateSignedUrl(signedUrl: string): void {
    this.invoiceSafeUrl = this.helpersService.sanitizeUrl(signedUrl);
  }

  openModalConfirmCancelInvoice(content: any): void {
    this.modalService.open(content, { backdrop: 'static', size: 'lg' }).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(`Dismissed ${reason}`);
    });
  }

  cancelInvoice(): void {
    this.loadingCancelInvoice = true;
    this.invoice.cancelNote = this.cancelNote || "";
    this.invoicesService.cancelInvoice(this.invoice).subscribe(() => {
      this.invoice.status = 'cancelled';
      this.messageService.send('toast', 'Fatura cancelada');
      this.loadingCancelInvoice = false;

      this.messageService.send('InvoiceTrackingHistoryComponent', 'newAction', {
        invoiceId: this.invoice.id,
        invoiceRef: this.invoice.ref,
        action: 'cancel',
        createdAt: (new Date()),
      });

      this.modalService.dismissAll();
    });
  }

  openModalConfirmReSend(content: any): void {
    this.modalService.open(content, { backdrop: 'static', size: 'lg' }).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(`Dismissed ${reason}`);
    });
  }

  reSendInvoice(): void {
    this.loadingReSendInvoice = true;
    this.invoicesService.reSendInvoice(this.invoice).subscribe(() => {
      this.messageService.send('toast', 'Fatura enviada!');
      this.loadingReSendInvoice = false;

      this.messageService.send('InvoiceTrackingHistoryComponent', 'newAction', {
        invoiceId: this.invoice.id,
        invoiceRef: this.invoice.ref,
        action: 'resend',
        createdAt: (new Date()),
      });

      this.modalService.dismissAll();
    });
  }

  openModalConfirmMarkAsPaid(content: any): void {
    this.modalService.open(content, { backdrop: 'static', size: 'lg' }).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(`Dismissed ${reason}`);
    });
  }

  markAsPaid(): void {
    this.loadingMarkAsPaid = true;
    this.invoicesService.markInvoiceAsPaid(this.invoice).subscribe(() => {
      this.invoice.status = 'paid';
      this.messageService.send('toast', 'Fatura marcada como paga');
      this.loadingMarkAsPaid = false;

      this.messageService.send('InvoiceTrackingHistoryComponent', 'newAction', {
        invoiceId: this.invoice.id,
        invoiceRef: this.invoice.ref,
        action: 'paid',
        createdAt: (new Date()),
      });

      this.modalService.dismissAll();
      this.router.navigate(['/panel/invoices', this.invoice.id, 'review']);
    });
  }

  getPaymentDateDifference(): string {
    let diffDays: string = '';
    let sentAt: string = this.helpersService.convertFirebaseTimestampToString(this.invoice.sentAt);
    let updatedAt: string = this.helpersService.convertFirebaseTimestampToString(this.invoice.updatedAt);
    if (sentAt !== "" && updatedAt !== "") {
      diffDays = dayjs(updatedAt).diff(dayjs(sentAt), 'day').toString();
      if (diffDays === 'NaN') {
        return diffDays = '';
      }

      if (diffDays === '0') {
        diffDays = 'no mesmo dia do envio';
      } else if (diffDays === '1') {
        diffDays = '1 dia após o envio';
      } else {
        diffDays = `${diffDays} dias após o envio`;
      }
    }
    return diffDays;
  }

  paymentProofSent($event: any): void {
    this.invoice.paymentProof = $event;
    this.invoice.status = 'paid';
    this.messageService.send('toast', 'Comprovante de pagamento enviado. Fatura marcada como Paga.');
  }

  onUpdateUploadProgress(progress: number): void {
    this.uploadProgress = progress;
  }

  onLoadingUploader($event: any): void {
    this.loadingUploadPaymentProof = $event;
  }

  onUploadFile($event: any): void {
    this.invoice.paymentProof = {
      fileName: $event.name,
      fileUrl: $event.downloadUrl,
      type: $event.type,
      filePath: $event.filePath,
      updatedAt: $event.updatedAt || (new Date()),
    };
  }

}
