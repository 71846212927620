<div class="pyro">
    <div class="before"></div>
    <div class="after"></div>
</div>

<div class="container">
    <div class="row d-flex justify-content-center">
        <div class="col-12 col-md-4">

            <div class="page-header">
                <div class="badge primary mb-3">Sucesso</div>
                <h3>Verifique seu email</h3>
                <p>
                    Nós te enviamos um email com um link para ativar sua conta.
                </p>
            </div>

            <div class="separator-70"></div>

            <div class="row text-center">
                <div class="col-12 col-md-6">
                    <a href="https://gmail.com/" target="_blank" rel="noopener noreferrer">
                        <img src="/assets/images/icon-gmail.png" alt="" class="img-fluid" width="80px">
                        <div class="separator-20"></div>
                        Abrir Gmail
                    </a>
                </div>
                <div class="col-12 col-md-6">
                    <a href="https://login.live.com/" target="_blank" rel="noopener noreferrer">
                        <img src="/assets/images/icon-outlook.png" alt="" class="img-fluid" width="80px">
                        <div class="separator-20"></div>
                        Abrir Outlook
                    </a>
                </div>
            </div>

        </div>
    </div>
</div>