<app-header>
    <div class="badge primary mb-3">Visualização de Fatura</div>
    <span>Fatura <span *ngIf="!loadingInvoice"> <span class="text-muted">&raquo; </span><strong>{{ invoice.ref }}</strong></span></span>
</app-header>

<app-loading *ngIf="loadingInvoice"></app-loading>


<div *ngIf="!loadingInvoice && ['draft','scheduled'].includes(invoice.status)">
    <div class="container">
        <div class="alert alert-warning mb-3">
            <div class="row align-items-center">
                <div class="col-12 col-md-10">
                    <strong>Fatura não disponível para visualização.</strong>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!loadingInvoice && error">
    <div class="container">
        <div class="alert alert-danger mb-3">
            <div class="row align-items-center">
                <div class="col-12 col-md-10">
                    <span *ngIf="error === 'notFound'">
                        <strong>Fatura não existe.</strong>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="!loadingInvoice && invoice.id && !['draft','scheduled'].includes(invoice.status)">
    <div class="container">

        <!-- PAYMENTPROOF -->
        <div *ngIf="invoice.status == 'paymentProof'" class="alert alert-warning mb-4">
            <strong>Aguardando a validação do comprovante de pagamento.</strong>
            <div class="separator-10"></div>
            Seu comprovante de pagamento foi enviado com sucesso e será analisado em breve.
        </div>

        <!-- SENT -->
        <div *ngIf="invoice.status == 'sent'" class="alert alert-secondary mb-4">
            <div class="row align-items-center">
                <div class="col-12 col-md-10">
                    <strong><i class="fas fa-clock me-2"></i> Aguardando pagamento</strong>
                </div>
            </div>
        </div>

        <!-- PAID -->
        <div *ngIf="invoice.status == 'paid'" class="alert alert-primary mb-4">
            <div class="row align-items-center">
                <div class="col-12 col-md-10">
                    <strong><i class="fas fa-check me-2"></i> Fatura Paga</strong> &mdash; <small>Última atualização em {{ helpersService.convertFirebaseTimestampToString(invoice.updatedAt, "DD/MM/YYYY HH:mm:ss") }}</small>
                </div>
            </div>
        </div>

        <!-- CANCELLED -->
        <div *ngIf="invoice.status == 'cancelled'" class="alert alert-danger mb-4">
            <strong>Fatura Cancelada</strong>
            <div class="separator-10"></div>
            Esta fatura foi marcada como "Cancelada".<br>
            Por favor, entre em contato com o emissor para mais informações.
        </div>


        <div class="separator-5"></div>

        <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav-tabs">
            <li ngbNavItem [ngbNavItem]="1">
                <a ngbNavLink><i class="fas fa-eye me-2"></i> Visualizar Fatura</a>
                <ng-template ngbNavContent>

                    <div *ngIf="!invoiceSafeUrl" class="text-center text-muted mb-4">
                        <small>
                            <app-loading></app-loading>
                            <div class="separator-10"></div>
                            Carregando a pré-visualização da fatura.
                        </small>
                    </div>

                    <div *ngIf="invoice.invoiceFile && invoice.invoiceFile.fileUrl === 'error'" class="text-center text-muted">
                        <small>
                            <i class="fas fa-exclamation-triangle"></i>
                            <div class="separator-10"></div>
                            Não foi possível carregar a pré-visualização da fatura.<br>
                            Tente novamente mais tarde.
                        </small>
                    </div>

                    <div class="iframe-placeholder">
                        <iframe
                            *ngIf="invoiceSafeUrl"
                            width="100%"
                            height="1250px"
                            frameborder="0"
                            [src]="invoiceSafeUrl">
                        </iframe>
                    </div>

                </ng-template>
            </li>
            <li ngbNavItem [ngbNavItem]="2">
                <a ngbNavLink><i class="fas fa-plus-circle me-2"></i> Informações Adicionais</a>
                <ng-template ngbNavContent>

                    <app-invoice-details-readonly [invoice]="invoice" role="customer"></app-invoice-details-readonly>

                </ng-template>
            </li>
        </ul>

    </div>

    <section class="bg-super-light content-section">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-8">

                    <div [ngbNavOutlet]="nav"></div>

                </div>
                <div class="col-12 col-md-4">

                    <div *ngIf="invoice.status === 'sent'" class="card border-0 mb-4">
                        <div class="card-body">

                            <div *ngIf="invoice.paymentMethod === 'none'">
                                <span class="line-break break-content" [innerHTML]="invoice.description || ''"></span>
                            </div>

                            <div *ngIf="invoice.paymentMethod === 'link'">
                                <div class="form-group">

                                    <label class="form-label"><strong>Pagamento da Fatura</strong></label>

                                    <div *ngIf="invoice.dueDate">
                                        <small>
                                            <i class="fas fa-exclamation-circle text-primary me-2"></i> <strong>Vencimento:</strong> {{ (invoice.dueDate | date: 'dd/MM/yyyy') || '--' }}
                                        </small>
                                        <div class="separator-20"></div>
                                    </div>

                                    <p>
                                        Para fazer o pagamento desta fatura, clique no botão abaixo <span *ngIf="invoice.paymentLinksAlternative && invoice.paymentLinksAlternative.length > 0"> ou escolha uma das opções alternativas disponíveis</span>.
                                    </p>

                                    <div class="separator-10"></div>

                                    <div class="d-grid">
                                        <button 
                                            type="button"
                                            [disabled]="loadingUploadPaymentProof"
                                            (click)="openExternalLink(invoice.paymentLink)"
                                            class="btn btn-primary">
                                                <strong>Pagar {{ invoice.grandTotal | currency : invoice.currency.toUpperCase() }}</strong>
                                        </button>
                                    </div>

                                    <div *ngIf="invoice.paymentLinksAlternative && invoice.paymentLinksAlternative.length > 0">
                                        <div class="separator-20"></div>

                                        <small class="text-muted">
                                            Outras opções para pagamento: <i class="fas fa-info-circle me-2" placement="top" ngbTooltip="Além do link principal, o emissor desta fatura disponibilizou outras opções de pagamento para que você possa escolher a que mais se adequar a você."></i>
                                        </small>

                                        <div class="separator-10"></div>

                                        <button
                                            *ngFor="let link of invoice.paymentLinksAlternative; let i = index"
                                            (click)="openExternalLink(link)"
                                            [disabled]="loadingUploadPaymentProof"
                                            class="me-2 btn btn-clean">
                                                <div class="badge bg-secondary">Opção {{ i+2 }}</div>
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <!-- Payment proof -->

                    <div *ngIf="['none', 'link'].includes(invoice.paymentMethod)">
                        <div class="card border-0">
                            <div class="card-body">

                                <strong>Comprovante de Pagamento</strong>
                                <div class="separator-10"></div>

                                <div *ngIf="['paid','cancelled'].includes(invoice.status) && (!invoice.paymentProof || !invoice.paymentProof.updatedAt)">
                                    Nenhum comprovante de pagamento foi enviado para esta fatura.
                                </div>

                                <div *ngIf="invoice.status === 'sent' && (!invoice.paymentProof || !invoice.paymentProof.updatedAt)">
                                    <div *ngIf="loadingUploadPaymentProof">
                                        <app-loading parent="button" color="text-primary"></app-loading>
                                        <small class="text-muted">{{uploadProgress}}%</small>
                                    </div>
                                    <app-uploader
                                        [uid]="invoice.uid"
                                        label="Selecionar Comprovante"
                                        folder="receipts"
                                        accept="application/pdf,image/jpg,image/jpeg,image/png"
                                        (onLoading)="onLoadingUploader($event)"
                                        (onUpload)="onUploadFile($event)"
                                        (onPercentageChange)="onUpdateUploadProgress($event)"
                                        [loading]="loadingUploadPaymentProof">
                                    </app-uploader>
                                </div>

                                <div *ngIf="invoice.paymentProof && invoice.paymentProof.updatedAt && invoice.paymentProof.updatedAt !== ''">
                                    <small class="text-muted">
                                        <a
                                            [href]="invoice.paymentProof.fileUrl"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            class="btn btn-sm btn-link btn-clean">
                                                <i class="fas fa-file-pdf me-2"></i> {{ invoice.paymentProof.fileName || '' }} <i class="fas fa-external-link-alt ms-2"></i>
                                        </a>

                                        <div class="separator-10"></div>
                                        Adicionado em {{ helpersService.convertFirebaseTimestampToString(invoice.paymentProof.updatedAt, "DD/MM/YYYY") }}
                                    </small>
                                </div>

                            </div><!-- /.card-body -->
                        </div><!-- /.card -->
                    </div>


                </div>
            </div><!-- /.row -->

        </div>
    </section>
</div>