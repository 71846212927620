import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(
    private http: HttpClient,
  ) { }

  getCountersPublic(validator: any): any {
    return this.http.get<any>(`${environment.api}/admin/counters/public/${validator}`);
  }

  reSendAccountValidationEmail(validator: any, uid: string): any {
    return this.http.get<any>(`${environment.api}/admin/counters/public/${validator}/revalidate/${uid}`);
  }

}
