<div *ngIf="parent === 'page'" class="text-center">
    <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>

<div *ngIf="parent === 'button'" class="spinner-border button-loading me-2" [ngClass]="color" role="status">
    <span class="visually-hidden">Loading...</span>
</div>

<div *ngIf="parent === 'line'" class="spinner-border button-loading loading-line" [ngClass]="color" role="status">
    <span class="visually-hidden">Loading...</span>
</div>