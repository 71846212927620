import { Component, OnInit } from '@angular/core';

const fbq: any = window['fbq'];

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})

export class WelcomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    if (window.location.hostname === 'app.invoicefy.io' && fbq) {
      fbq('track', 'CompleteRegistration');
    }
  }

}
