import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TruncatePipe } from '../pipes/truncate.pipe';
import { MessageService } from './message.service';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root'
})
export class HelpersService {

  constructor(
    private messageService: MessageService,
    private modalService: NgbModal,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private truncatePipe: TruncatePipe,
  ) { }

  convertFirebaseTimestampToString(firebaseTimestamp: { _seconds: number, _nanoseconds: number } | any, format: string = "YYYY-MM-DD HH:mm:ss"): string {
    if (!firebaseTimestamp || !firebaseTimestamp._seconds) {
      return "";
    }

    const fireBaseTime = new Date(
      firebaseTimestamp._seconds * 1000 + firebaseTimestamp._nanoseconds / 1000000,
    );
    return dayjs(fireBaseTime).format(format);
  }

  sanitizeUrl(url: string): any {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  currencyFormatter(locale: string = 'pt-BR', currency: string = 'BRL', value: number): string {
    return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(value);
  }

  readLess(content: string, label: string = 'read more', length: number = 100): string {
    return this.truncatePipe.transform(content, length, label);
  }

  readMore(content: string): string {
    return this.detectUrlInString(content);
  }

  getCurrencyName(currency: string): string {
    const currencies = {
      'BRL': 'Real (R$)',
      'USD': 'Dólar ($)',
    };
    return currencies[currency.toUpperCase()];
  }

  openWhatsappChat(phone: string): void {
    phone = phone.replace(/\D/g, '');
    window.open(`https://api.whatsapp.com/send?phone=${phone}`, '_blank');
  }

  shareToWhatsapp(message: string, number: string | null): void {
    message = encodeURIComponent(message);
    let url = `https://api.whatsapp.com/send?text=${message}`;
    if (number) {
      number = number.replace(/\D/g, '');
      url += `&phone=${number}`;
    }
    window.open(url, '_blank');
  }

  closeModals(): void {
    this.modalService.dismissAll();
  }

  detectUrlInString = (text: string) => {
    return text.replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank">$1</a>');
  }

  // Same as api/functions/src/helpers.ts
  translateStatus(status: string): string {
    const translations = {
      'draft': 'Rascunho',
      'sent': 'Enviada',
      'paid': 'Paga',
      'scheduled': 'Agendada',
      'cancelled': 'Cancelada',
      'paymentProof': 'Validação',
    };
    return translations[status];
  }

  // Same as api/functions/src/helpers.ts
  currencies: any[] = [
    {
      currency: 'USD',
      locale: 'en-US',
      symbol: '$',
      format: {
        prefix: '$ ',
        thousands: ',',
        decimal: '.'
      },
      dateFormat: 'MM/dd/YYYY',
    },
    {
      currency: 'BRL',
      locale: 'pt-BR',
      symbol: 'R$',
      format: {
        prefix: 'R$ ',
        thousands: '.',
        decimal: ','
      },
      dateFormat: 'dd/MM/YYYY',
    },
  ];

  // Same as api/functions/src/helpers.ts
  getCurrencyDetails(currency: string) {
    return this.currencies.find((item: any) => item.currency.toUpperCase() === currency.toUpperCase());
  };

  // Almost the same as api/functions/src/helpers.ts
  private _invoiceTrackingActions: any[] = [
    { action: "cancel", description: "cancelada em" },
    { action: "create", description: "criada em" },
    { action: "duplicate", description: "duplicada em" },
    { action: "paid", description: "paga em" },
    { action: "resend", description: "reenviada em" },
    { action: "send", description: "enviada em" },
    { action: "scheduled", description: "agendada para envio em" },
    { action: "update", description: "atualizada em" },
    { action: "paymentProof", description: "recebeu comprovante de pagamento em" },
    { action: "paymentProof_issuer", description: "recebeu comprovante de pagamento em" },
  ];

  getInvoiceTrackingDescriptionByAction(action: string): string {
    return this._invoiceTrackingActions.find((item: any) => item.action === action).description;
  }

  getDynamicPhoneMask(phone: string): string {
    if (!phone || phone === '') {
      return '';
    }

    // (00)00000-0000 || 55(00)00000-0000 -> mobile
    // 0(000)000-0000 -> USA
    const onlyNumbers = phone.replace(/\D/g,'');
    let mask = '00(00)0000-0000';
    if (onlyNumbers.length >= 10) {
      if (parseInt(onlyNumbers[0]) === 5) {
        mask = '00(00)00000-0000';
      } else if (parseInt(onlyNumbers[0]) === 1) {
        mask = '0(000)000-0000';
      } else {
        mask = '(00)00000-0000';
      }
    }
    return mask;
  }

  isValidEmail(email: string): boolean {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  isValidUrl(url: string): boolean {
    const re = /^(http|https):\/\/[a-zA-Z0-9-_.]+\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    return re.test(String(url).toLowerCase());
  }

  randomString(length: number = 10) {
    const dictionary = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
    let random = "";
    for (let i = 0; i < length; i++) {
      random += dictionary.charAt(Math.floor(Math.random() * dictionary.length));
    }
    return random;
  }

  copyToClipboard(item: string, showToast: boolean = false) {
    const create_copy = (e: any) => {
      e.clipboardData.setData('text/plain', item);
      e.preventDefault();
    }
    document.addEventListener('copy', create_copy);
    document.execCommand('copy');
    document.removeEventListener('copy', create_copy);
    if (showToast) {
      this.messageService.send('toast','Link copiado!');
    }
  }
}
