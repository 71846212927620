<app-header>
    <div class="badge primary mb-3">Visão 360 das suas faturas</div>
    <span>Relatórios</span>
    <p>
        Busque todas as faturas utilizando filtros personalizados.
    </p>
</app-header>

<section class="bg-super-light content-section">
    <div class="container">
        <form #formReport="ngForm" novalidate (submit)="filterReport(formReport.value)">
            <div class="row align-items-end">
                <div class="col">
                    <div class="form-group">
                        <label>De</label>
                        <input type="date" [disabled]="loadingReport" (change)="invoices = []" class="form-control" [(ngModel)]="filter.periodFrom" name="periodFrom" required>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Até</label>
                        <input type="date" [disabled]="loadingReport" (change)="invoices = []" class="form-control" [(ngModel)]="filter.periodTo" name="periodTo" required>
                    </div>
                </div>
                <div class="col col-md-4">

                    <app-customers
                        (onCustomers)="customers = $event"
                        (onLoading)="loadingCustomers = $event"
                        ></app-customers>

                    <div class="form-group">
                        <label>Cliente</label>
                        <select [disabled]="loadingReport || loadingCustomers" (change)="invoices = []" class="form-control" [(ngModel)]="filter.customer" name="customer" required>
                            <option value="all">Todos os clientes</option>
                            <option *ngFor="let customer of customers" [value]="customer.id">{{ customer.name }} ({{ customer.email }})</option>
                        </select>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Status</label>
                        <select [disabled]="loadingReport" (change)="invoices = []" class="form-control" [(ngModel)]="filter.status" name="status" required>
                            <option value="all">Todos os Status</option>
                            <option value="cancelled">Cancelada</option>
                            <option value="draft">Rascunho</option>
                            <option value="paid">Paga</option>
                            <option value="sent">Enviada</option>
                            <option value="paymentProof">Comprovante Recebido</option>
                            <option value="scheduled">Agendada</option>
                        </select>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label>Moeda</label>
                        <select [disabled]="loadingReport" (change)="invoices = []" class="form-control" [(ngModel)]="filter.currency" name="currency" required>
                            <option value="">Selecione</option>
                            <option *ngFor="let currency of helpersService.currencies" [value]="currency.currency">{{ currency.currency }} ({{ currency.symbol }})</option>
                        </select>
                    </div>
                </div>
                <div class="col">
                    <div class="d-grid">
                        <button type="submit" [disabled]="!formReport.valid || loadingReport" class="btn btn-primary">
                            <app-loading *ngIf="loadingReport" parent="button"></app-loading> 
                            <i *ngIf="!loadingReport" class="fas fa-check-circle me-2"></i> Filtrar
                        </button>
                    </div>
                </div>
            </div>
        </form>

        <div class="separator-10"></div>

        <small class="text-muted">
            &ast; Os campos "De" e "Para" consideram a data da última atualização da fatura.
        </small>

        <div class="separator-40"></div>

        <div class="row text-center">
            <div class="col-12 col-md-2">
                <div class="card border-0">
                    <div class="card-body">
                        <h1>{{ loadingReport ? "-" : invoices.length }}</h1>
                        Faturas
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="card border-0">
                    <div class="card-body">
                        <h1>{{ loadingReport ? "-" : sumInvoicesAmount() | currency : ((filter.currency === "all") ? "" : filter.currency) }}</h1>
                        Total
                    </div>
                </div>
            </div>
        </div>

        <div class="separator-50"></div>

        <app-loading *ngIf="loadingReport"></app-loading>

        <div *ngIf="!loadingReport && invoices.length > 0">
            <div class="mb-4">
                <button [disabled]="user && user.plan === 'free'" (click)="exportToCSV()" class="btn btn-outline-primary">
                    <i class="fas fa-file-excel me-2"></i> Exportar para Excel
                </button>
            </div>

            <app-invoice-list [invoices]="invoices" [canDuplicate]="false"></app-invoice-list>
        </div>

    </div>
</section>