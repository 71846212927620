<div class="container">
    <div class="row justify-content-center">
        <div class="col-12 col-md-6">

            <div class="page-header">
                <div class="badge primary mb-3">Acompanhe as novidades</div>
                <h3>Notas da versão</h3>
                <p>
                    Confira as novidades de cada versão da Invoicefy
                </p>
            </div>

            <div class="card border-0">
                <div class="card-body">

                    <strong>1.62 <span class="badge bg-primary ms-2">versão atual</span></strong>

                    <div class="separator-10"></div>

                    <ul>
                        <li>
                            Captura de respostas por email
                            <p class="text-muted">
                                Quando o cliente recebe um email (com a fatura, lembretes ou notificações), ao responder, a Invoicefy faz a captura da resposta e envia para o seu email.
                            </p>
                        </li>
                        <li>
                            Pré-visualização da fatura
                            <p class="text-muted">
                                Na tela de ajustes, onde você configura o seu perfil e detalhes da sua empresa, há uma pré-visualização de como ficará o PDF da fatura para os seus clientes.
                            </p>
                        </li>
                        <li>Melhorias diversas de segurança</li>
                    </ul>

                    <div class="separator-50"></div>

                    <strong>1.61</strong>

                    <div class="separator-10"></div>

                    <ul>
                        <li>
                            Exportar relatórios para planilha do Excel <div class="badge bg-primary ms-2">Pro</div>
                            <p class="text-muted">
                                Para quem é assinante do plano Pro, é possível criar um relatório e exportar os resultas para uma planilha do Excel.
                            </p>
                        </li>
                        <li>
                            Tempo de pagamento
                            <p class="text-muted">
                                Ao marcar uma fatura como "Paga", é exibida a informação de quantos dias esta fatura levou para ser paga. Este tempo é calculado a partir da data de emissão da fatura até a data de pagamento.
                            </p>
                        </li>
                        <li>Melhorias de performance e segurança</li>
                    </ul>

                    <div class="separator-50"></div>

                    <strong>1.60</strong>

                    <div class="separator-10"></div>

                    <ul>
                        <li>
                            Cancelar Agendamento de fatura <div class="badge bg-primary ms-2">Pro</div>
                            <p class="text-muted">
                                Agora é possível cancelar uma fatura que está agendada para envio.
                            </p>
                        </li>
                        <li>
                            Histórico detalhado de alterações da fatura <div class="badge bg-primary ms-2">Pro</div>
                            <p class="text-muted">
                                Cada alteração feita na fatura é registrada e pode ser visualizada no histórico de alterações.
                            </p>
                        </li>
                        <li>
                            Restaurar versão anterior da fatura <div class="badge bg-primary ms-2">Pro</div>
                            <p class="text-muted">
                                No histórico de alterações da fatura, é possível escolher uma das versões anteriores e restaurar a fatura para aquela versão.
                            </p>
                        </li>
                        <li>Melhorias de performance</li>
                    </ul>

                    <div class="separator-50"></div>

                    <strong>1.59</strong>

                    <div class="separator-10"></div>

                    <ul>
                        <li>
                            Agendamento de fatura <div class="badge bg-primary ms-2">Pro</div>
                            <p class="text-muted">
                                Agora você pode agendar o envio de uma fatura para um cliente. 
                                Ao criar uma fatura, basta selecionar a data na seção "Agendar Envio" e a fatura será enviada automaticamente para o cliente.
                            </p>
                        </li>
                        <li>Correção do formato do telefone no PDF da fatura</li>
                        <li>
                            Opção para adicionar anotações ao marcar uma fatura como paga
                            <p class="text-muted">
                                Ao marcar uma fatura como paga, você pode adicionar uma anotação para registrar o pagamento. 
                                É possível adicionar uma anotação que será exibida apenas para você ou para o cliente também.
                            </p>
                        </li>
                        <li>
                            Separação das anotações em abas para melhorar a visualização
                            <p class="text-muted">
                                As anotações agora são separadas em abas para melhorar a organização e a 
                                sua visualização do que são anotações públicas (seu cliente vê ao receber a fatura) e particulares (somente você vê).
                            </p>
                        </li>
                        <li>
                            Inclusão dos anexos ao reenviar a fatura
                            <p class="text-muted">
                                Ao reenviar uma fatura, os anexos são enviados novamente para o cliente.
                            </p>
                        </li>
                        <li>Melhorias diversas no layout</li>
                        <li>
                            Redirecionamento automático após fazer o login
                            <p class="text-muted">
                                Após fazer o login, você é redirecionado para a página que estava na sessão anterior, ou para o link da fatura que veio por e-mail.
                            </p>
                        </li>
                    </ul>

                    <div class="separator-50"></div>

                    <strong>1.58</strong>

                    <div class="separator-10"></div>

                    <ul>
                        <li>Visualização da fatura em abas quando o status for diferente de "Rascunho"</li>
                        <li>Opção para adicionar uma nota ao marcar manualmente uma invoice como paga.</li>
                        <li>Correções na tradução da plataforma para Português-BR</li>
                    </ul>

                    <div class="separator-50"></div>

                </div>
            </div>

        </div>
    </div>
</div>
