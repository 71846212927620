<div class="container mb-4">

    <div class="row d-flex justify-content-center">
        <div class="col-12 col-md-4">

            <div class="page-header">
                <div class="badge primary mb-3">Cadastrar</div>
                <h3>Criar Conta</h3>
                <p>
                    Crie uma conta em apenas um passo.<br>
                    Nenhum cartão de crédito necessário.
                </p>
            </div>

        </div>
    </div>
</div>

<section class="bg-super-light content-section">
    <div class="container">
        <div class="row d-flex justify-content-center">
            <div class="col-12 col-md-4">

                <div class="card border-0">
                    <div class="card-body">

                        <form #formSignup="ngForm" (submit)="signup(formSignup.value)">
                            <div class="form-group mb-2">
                                <label>Seu nome <span class="text-danger">*</span></label>
                                <input [disabled]="loading" ngModel name="name" type="text" class="form-control" required autocomplete="off">
                            </div>
                            <div class="form-group mb-2">
                                <label>Email <span class="text-danger">*</span></label>
                                <input [disabled]="loading" ngModel name="email" type="email" email class="form-control lowercase" required autocomplete="off">
                            </div>
                            <div class="form-group mb-2">
                                <label>Celular</label>
                                <input
                                    ngModel
                                    [disabled]="loading"
                                    name="phone"
                                    type="text"
                                    class="form-control"
                                    autocomplete="off"
                                    [specialCharacters]="['(',')','-']" mask="(00)00000-0000"
                                    placeholder="(00)00000-0000">
                            </div>
                            <div class="form-group mb-2">
                                <label>Senha <span class="text-danger">*</span></label>
                                <input [disabled]="loading" ngModel name="password" type="password" class="form-control" required autocomplete="off">
                            </div>
                            <div class="form-group mb-2">
                                <label>Repita a senha <span class="text-danger">*</span></label>
                                <input [disabled]="loading" [(ngModel)]="password" name="passwordConfirm" type="password" class="form-control" required autocomplete="off">
                            </div>

                            <password-strength-meter (strengthChange)="passwordStrength = $event" [password]="password"></password-strength-meter>

                            <div class="separator-5"></div>

                            <small class="text-muted">
                                Sua senha deve ter entre 8 e 20 caracteres, conter letras e números, e <strong>não deve</strong> conter espaços ou emojis.
                            </small>

                            <div class="separator-20"></div>

                            <label>
                                <input type="checkbox" [disabled]="loading" ngModel name="terms" required class="me-2" value="1" /> Ao clicar em "Criar Conta", você concorda com nossos <a href="https://invoicefy.io/terms" class="underline-none" target="_blank" rel="noopener noreferrer">Termos de Uso</a>.
                            </label>

                            <div class="separator-30"></div>

                            <small class="text-muted">
                                Você está se cadastrando em uma conta gratuita.<br>
                                Você pode atualizar para uma conta Pro a qualquer momento ou continuar com a versão gratuita para sempre.
                            </small>

                            <div class="separator-20"></div>

                            <button [disabled]="!formSignup.valid || loading || passwordStrength < 3" type="submit" class="btn btn-primary mt-4">
                                <app-loading *ngIf="loading" parent="button"></app-loading>
                                <i *ngIf="!loading" class="fas fa-check-circle me-2"></i> Criar Conta
                            </button>
                        </form>

                    </div>
                </div>

                <div class="text-center">
                    <div class="separator-40"></div>
                    <small class="text-muted">Precisa de ajuda?</small>
                    <div class="separator-5"></div>
                    <a href="https://forms.gle/PVDzfkvpFp98ingw9" target="_blank" rel="noopener noreferrer" class="btn btn-link">
                        <small>
                            Solicite o Suporte
                        </small>
                    </a>
                </div>

            </div>
        </div>
    </div>
</section>