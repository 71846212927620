<div class="modal-body">
    <i class="fas fa-exclamation-circle text-muted-40 me-2"></i> <strong>Confirmar Ação</strong>

    <div class="separator-5"></div>

    <hr>

    <div class="separator-10"></div>

    <ng-content select=".content"></ng-content>

    <div class="separator-20"></div>

    <ng-content select="button"></ng-content>

    <button [disabled]="loading" type="button" class="btn btn-link btn-sm ms-2" (click)="close()">Cancelar</button>
</div>