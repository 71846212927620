import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import Invoice from 'src/app/models/invoice.interface';
import { HelpersService } from 'src/app/services/helpers.service';
import { InvoicesService } from 'src/app/services/invoices.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent implements OnInit {

  @Input() canDuplicate: boolean = true;
  @Input() invoices: Invoice[] = [];
  invoicesImmutable: Invoice[] = [];

  @Output() onDuplicateInvoice: EventEmitter<any> = new EventEmitter();

  loadingGenerateUrl: boolean = false;
  loadingGenerateUrlForInvoiceId: string = "";

  loadingDuplicateInvoice: boolean = false;
  loadingDuplicateInvoiceForId: string = "";

  constructor(
    public helpersService: HelpersService,
    private messageService: MessageService,
    private invoicesService: InvoicesService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.invoicesImmutable = this.invoices;
  }

  openInvoice(invoice: Invoice): void {
    this.router.navigate(['/panel/invoices', invoice.id, (invoice.status === 'draft' ? 'editor' : 'review')]);
  }

  duplicateInvoice(invoice: Invoice): void {
    this.loadingDuplicateInvoice = true;
    this.loadingDuplicateInvoiceForId = invoice.id || "";
    this.invoicesService.duplicateInvoice(invoice).subscribe((response: any) => {
      this.messageService.send('toast', 'Fatura duplicada');

      this.messageService.send('InvoiceTrackingHistoryComponent', 'newAction', {
        invoiceId: invoice.id,
        invoiceRef: invoice.ref,
        action: 'duplicate',
        createdAt: (new Date()),
      });

      this.loadingDuplicateInvoice = false;
      this.loadingDuplicateInvoiceForId = "";

      this.router.navigate(['/panel/invoices', response.newInvoiceId, 'editor']);
    });
  }

}
