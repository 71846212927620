import {
  // LOCALE_ID,
  NgModule,
  // ErrorHandler,
} from '@angular/core';

// import Bugsnag from '@bugsnag/js';
// import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';

import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Bugsnag.start({ apiKey: environment.bugsnagApiKey });

// create a factory which will return the Bugsnag error handler
// export function errorHandlerFactory() {
//   return new BugsnagErrorHandler();
// }

// import { registerLocaleData } from '@angular/common';
// import ptBr from '@angular/common/locales/pt';
// registerLocaleData(ptBr);

import { AppComponent } from './app.component';
import { InvoicesComponent } from './views/invoices/invoices.component';
import { InvoicesService } from './services/invoices.service';
import { CustomersComponent } from './components/customers/customers.component';
import { CustomersService } from './services/customers.service';
import { ROUTES } from './app.routes';
import { InvoiceSingleComponent } from './views/invoice-single/invoice-single.component';
import { NgxCurrencyModule } from "ngx-currency";
import { NavbarComponent } from './components/navbar/navbar.component';
import { CustomersManagmentComponent } from './views/customers/customers.component';
import { LoadingComponent } from './components/loading/loading.component';
import { CustomersFormComponent } from './components/customers-form/customers-form.component';
import { NgxMaskModule } from 'ngx-mask'
import { HelpersService } from './services/helpers.service';
import { ToastComponent } from './components/toast/toast.component';
import { MessageService } from './services/message.service';
import { HeaderComponent } from './components/header/header.component';
import { UploaderComponent } from './components/uploader/uploader.component';
import { InvoicePublicComponent } from './views/invoice-public/invoice-public.component';
import { ConfirmContentComponent } from './components/confirm-content/confirm-content.component';
import { AuthLoginComponent } from './views/auth-login/auth-login.component';
import { AuthSignupComponent } from './views/auth-signup/auth-signup.component';
import { AuthService } from './services/auth.service';
import { UsersService } from './services/users.service';
import { LoggedInGuard } from './services/loggedin.guard';
import { AuthInterceptor } from './services/auth.interceptor';
import { ReportsComponent } from './views/reports/reports.component';
import { environment } from 'src/environments/environment';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { TermsComponent } from './views/terms/terms.component';
import { InvoiceTrackingHistoryComponent } from './components/invoice-tracking-history/invoice-tracking-history.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { AutosizeModule } from 'ngx-autosize';
import { CountersComponent } from './views/counters/counters.component';
import { AdminService } from './services/admin.service';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SettingsComponent } from './views/settings/settings.component';
import { ConfirmAccountComponent } from './views/confirm-account/confirm-account.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { InvoiceListComponent } from './components/invoice-list/invoice-list.component';
import { PaymentsService } from './services/payments.service';
import { WelcomeComponent } from './views/welcome/welcome.component';
import { FeaturesComponent } from './views/features/features.component';
import { UpgradeComponent } from './views/upgrade/upgrade.component';
import { WelcomeToProComponent } from './views/welcome-to-pro/welcome-to-pro.component';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { TypebotComponent } from './components/typebot/typebot.component';
import { InvoiceStatusComponent } from './components/invoice-status/invoice-status.component';
import { InvoicePublicLinkComponent } from './components/invoice-public-link/invoice-public-link.component';
import { CustomersHistoryComponent } from './components/customers-history/customers-history.component';
import { InvoiceEditorComponent } from './views/invoice-editor/invoice-editor.component';
import { VersionComponent } from './views/version/version.component';
import { InvoiceDetailsReadonlyComponent } from './components/invoice-details-readonly/invoice-details-readonly.component';
import { InvoiceDetailedChangesHistoryComponent } from './components/invoice-detailed-changes-history/invoice-detailed-changes-history.component';

// ng-bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

// Firebase
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';

@NgModule({
  declarations: [
    AppComponent,
    InvoicesComponent,
    CustomersComponent,
    InvoiceSingleComponent,
    InvoicePublicComponent,
    NavbarComponent,
    CustomersManagmentComponent,
    LoadingComponent,
    CustomersFormComponent,
    ToastComponent,
    HeaderComponent,
    UploaderComponent,
    ConfirmContentComponent,
    AuthLoginComponent,
    AuthSignupComponent,
    ReportsComponent,
    InvoiceTrackingHistoryComponent,
    TermsComponent,
    TruncatePipe,
    CountersComponent,
    SettingsComponent,
    ConfirmAccountComponent,
    ResetPasswordComponent,
    InvoiceListComponent,
    WelcomeComponent,
    WelcomeToProComponent,
    FeaturesComponent,
    UpgradeComponent,
    NotFoundComponent,
    InvoiceStatusComponent,
    InvoicePublicLinkComponent,
    CustomersHistoryComponent,
    InvoiceEditorComponent,
    VersionComponent,
    InvoiceDetailsReadonlyComponent,
    InvoiceDetailedChangesHistoryComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot(ROUTES, { scrollPositionRestoration: 'enabled' }),
    NgxCurrencyModule,
    NgxMaskModule.forRoot(),
    NgbModule,
    NgbAccordionModule,
    NgbTooltipModule,
    NgbDropdownModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireAnalyticsModule,
    AngularFireAuthModule,
    PasswordStrengthMeterModule.forRoot(),
    AutosizeModule,
    NgxIntlTelInputModule,
    BrowserAnimationsModule,
    TypebotComponent,
  ],
  providers: [
    // { provide: ErrorHandler, useFactory: errorHandlerFactory },
    InvoicesService,
    CustomersService,
    HelpersService,
    MessageService,
    AuthService,
    UsersService,
    AdminService,
    LoggedInGuard,
    PaymentsService,
    TruncatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    // { provide: LOCALE_ID, useValue: "pt-BR" },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
