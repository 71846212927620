<div class="container mb-4">

    <div class="row d-flex justify-content-center">
        <div class="col-12 col-md-6">

            <div class="page-header">
                <div class="badge primary mb-3">Parabéns!</div>
                <h3>Você se inscreveu para ser <span class="text-primary"><strong>PRO</strong></span></h3>
                <p>
                    Assim que um novo convite estiver disponível, vamos te notificar por email.
                </p>
            </div>

        </div>
    </div>
</div>

<section class="bg-super-light content-section">
    <div class="row d-flex justify-content-center">
        <div class="col-12 col-md-4">

            <img src="/assets/images/welcome-to-pro.svg" class="img-fluid" alt="" width="350px">

            <div class="separator-50"></div>

            <small class="text-muted">
                Se você acha que se inscreveu por engano, entre em contato com nossa equipe de suporte.
            </small>

        </div>
    </div>
</section>