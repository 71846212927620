<div class="container">
    <app-loading *ngIf="loading"></app-loading>

    <div *ngIf="!loading">

        <div class="row text-center">
            <div class="col-12 col-md-2 mb-3">
                <div class="card border-0">
                    <div class="card-body">
                        <h1>{{ counters.customersCount }}</h1>
                        Customers
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 mb-3">
                <div class="card border-0">
                    <div class="card-body">
                        <h1>{{ counters.invoicesCount }}</h1>
                        Invoices <span class="text-muted">({{ counters.invoicesSentCount }} sent)</span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-2 mb-3">
                <div class="card border-0">
                    <div class="card-body">
                        <h1>{{ counters.profilesCount }}</h1>
                        Profiles <span class="text-muted">({{ countTotalPlans().free + countTotalPlans().pro }} active<small>*</small>)</span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-2 mb-3">
                <div class="card border-0">
                    <div class="card-body">
                        <h1>{{ counters.queueCount }}</h1>
                        Queue <span class="text-muted">({{ counters.queueDoneCount }} sent)</span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-2 mb-3">
                <div class="card border-0">
                    <div class="card-body">
                        <h1>{{ counters.betaAwaitingList }}</h1>
                        Awaiting List
                    </div>
                </div>
            </div>
        </div>

        <div class="separator-20"></div>

        <small class="text-muted">
            <sup>*</sup> Email verified + Account active + Plan
        </small>

        <div class="separator-40"></div>

        <hr class="text-muted">

        <div class="separator-40"></div>

        <strong>Manual Trigger Link Generator</strong>
        <div class="separator-5"></div>
        <div class="row">
            <div class="col-3">
                <div class="form-group">
                    <label>Type of link</label>
                    <select [(ngModel)]="manualLinkGenerator.type" name="type" (change)="generateManualLink()" class="form-control">
                        <option value="">--</option>
                        <option value="runQueueManually">Run queue item</option>
                        <option value="regeneratePdf">Regenerate PDF</option>
                    </select>
                </div>
            </div>
            <div class="col-9">
                <div class="form-group">
                    <label>Item ID</label>
                    <input type="text" autocomplete="off" [(ngModel)]="manualLinkGenerator.id" name="id" (keyup)="generateManualLink()" class="form-control">
                    <div class="separator-5"></div>
                    <small class="text-muted">
                        <span *ngIf="manualLinkGenerator.type === 'runQueueManually'">Inform the <i>queueId</i></span>
                        <span *ngIf="manualLinkGenerator.type === 'regeneratePdf'">Inform the <i>invoiceId</i></span>
                    </small>
                </div>
            </div>
        </div>
        <div *ngIf="manualLinkGenerator.link && manualLinkGenerator.type && manualLinkGenerator.id">
            <small class="text-muted">
                Link: <a [href]="manualLinkGenerator.link" target="_blank">{{ manualLinkGenerator.link }} <i class="fas fa-external-link-alt ms-2"></i></a>
            </small>
        </div>

        <div class="separator-40"></div>

        <hr class="text-muted">

        <div class="separator-40"></div>

        <table class="table">
            <thead>
                <tr>
                    <th class="text-muted text-center"><small>#</small></th>
                    <th>UID</th>
                    <th>Display Name</th>
                    <th>Email</th>
                    <th class="text-center">
                        Email Verified<br>
                        <span class="text-muted">({{ countTotalProfilesEmailVerified() }} = {{ calcPercent(countTotalProfilesEmailVerified(), counters.profilesCount) }}%)</span></th>
                    <th class="text-center">
                        Account Active<br>
                        <span class="text-muted">({{ countTotalProfilesAccountActive() }} = {{ calcPercent(countTotalProfilesAccountActive(), counters.profilesCount) }}%)</span></th>
                    <th class="text-center">
                        Plan<br>
                        <span class="badge bg-secondary ms-2 me-2">{{ countTotalPlans().free }} = {{ calcPercent(countTotalPlans().free, counters.profilesCount) }}%</span>
                        <span class="badge bg-primary">{{ countTotalPlans().pro }} = {{ calcPercent(countTotalPlans().pro, counters.profilesCount) }}%</span>
                    </th>
                    <th class="text-center">Inv <span class="text-muted"> &middot; </span> Cust</th>
                    <th class="text-center">Resend</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let profile of counters.profiles; let i = index">
                    <td class="text-muted text-center"><small>{{ i+1 }}</small></td>
                    <td>{{ profile.uid | truncate: 10 }}</td>
                    <td>{{ profile.displayName }}</td>
                    <td>{{ profile.email }}</td>
                    <td class="text-center">
                        <i *ngIf="profile.emailVerified" class="fas fa-check text-primary"></i>
                        <i *ngIf="!profile.emailVerified" class="fas fa-times text-muted-20"></i>
                    </td>
                    <td class="text-center">
                        <i *ngIf="profile.isAccountActive" class="fas fa-check text-primary"></i>
                        <i *ngIf="!profile.isAccountActive" class="fas fa-times text-muted-20"></i>
                    </td>
                    <td class="text-center">
                        <span class="badge bg-primary" *ngIf="profile.emailVerified && profile.isAccountActive && profile.plan === 'pro'">Pro</span>
                        <span class="badge bg-secondary text-muted-40" *ngIf="profile.emailVerified && profile.isAccountActive && profile.plan === 'free'">Free</span>
                    </td>
                    <td class="text-center">
                        <span [class.text-muted]="profile.totalInvoices === 0" [class.text-primary]="profile.totalInvoices > 0">{{ profile.totalInvoices === 0 ? '-' : profile.totalInvoices }}</span>
                        <span class="text-muted"> &middot; </span>
                        <span [class.text-muted]="profile.totalCustomers === 0" [class.text-primary]="profile.totalCustomers > 0">{{ profile.totalCustomers === 0 ? '-' : profile.totalCustomers }}</span>
                         
                    </td>
                    <td class="text-center">
                        <button [disabled]="loadingResendEmailToId === profile.uid || (profile.emailVerified && profile.isAccountActive)" (click)="resendActivateAccountEmail(profile)" class="btn btn-link btn-sm" type="button">
                            <i *ngIf="loadingResendEmailToId !== profile.uid" class="fas fa-envelope"></i>
                            <app-loading *ngIf="loadingResendEmailToId === profile.uid" parent="button" color="color-primary"></app-loading>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
</div>
